import { CertificateItemDto } from '@inphiz-shared/shared-models/src/F_Certificates/certificate-item-dto'
import { useQuery } from '@tanstack/react-query'
import APIFunctions from '../Networking/APIFunctions'
import { APIConst } from '../Networking/APIServices'
import { QueryKey } from '../QueryKey'

const CertificateItemType = new CertificateItemDto()
export type CertificateItem = typeof CertificateItemType

export interface CertificatesList {
  items: CertificateItem[]
}

export function userCertificates(handle: string) {
  const results = useQuery({
    queryKey: [QueryKey.userCertificates, handle],
    queryFn: () =>
      APIFunctions.GET(
        `${APIConst.employeesPerformance.certificateItems}?Parameters=UserHandle&Parameters=${handle}`,
      ),
  })
  const response = results.isFetching ? null : results.data
  if (response && response?.isSuccess) {
    return {
      ...results,
      data: response?.items as CertificateItem[],
    }
  }
  else {
    return {
      ...results,
      data: null,
    }
  }
}
