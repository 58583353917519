import { APIConst } from '@inphiz/api'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import { QueryBuilder } from '@inphiz/core-utilities'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from '../../../App'

function onSuccess() {
  queryClient.invalidateQueries({ queryKey: [QueryKey.getPermits] })
}

interface Params {
  handle?: string
  contentType?: string
}

export function useDeletePermits() {
  return useMutation({
    mutationFn: async (data: Params) => {
      const params = {
        item: data,
      }
      const qb = new QueryBuilder(APIConst.permits.getPermits)
      const result = await APIFunctions.DELETE(qb.toString(), params)
      return result
    },
    onSuccess,
  })
}
