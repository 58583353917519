import type { KeyboardEvent } from 'react'
import type { OrganizationUnitItem } from '../../../packages/api-web'
import FlatList from 'flatlist-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox } from 'rsuite'
import { CloseIcon, SearchIcon } from '../../../icons'
import { CustomAlert, CustomButton, CustomText } from '../../atoms'

interface JobCompanyAlertProps {
  data?: OrganizationUnitItem[]
  selectedData?: OrganizationUnitItem[]
  onCloseAlert?: () => void
  onSelectedData?: (items: OrganizationUnitItem[]) => void
}

export function JobCompanyAlert({
  data,
  selectedData,
  onCloseAlert,
  onSelectedData,
}: JobCompanyAlertProps) {
  const { t } = useTranslation('common')
  const [searchTxt, setSearchTxt] = useState('')
  const [selectedItems, setSelectedItems] = useState<OrganizationUnitItem[]>(selectedData ?? [])
  const [organizationData, setOrganizationData] = useState<OrganizationUnitItem[]>(data ?? [])

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const searchData = data?.filter((i: OrganizationUnitItem) => {
        return i.title?.toLocaleLowerCase().includes(searchTxt.toLowerCase())
      })
      setOrganizationData(searchData ?? [])
    }
  }

  const renderItem = (item: OrganizationUnitItem, idx: string) => {
    return (
      <div className="items-center flex h-8 border-b border-b-stroke px-4">
        <Checkbox
          checked={selectedItems.some(val => item.handle === val.handle)}
          onChange={() => {
            if (selectedItems.some(val => item.handle === val.handle)) {
              setSelectedItems((oldValues) => {
                return oldValues.filter(val => item.handle !== val.handle)
              })
            }
            else {
              setSelectedItems([...selectedItems, item])
            }
          }}
          className="cm-checkbox"
        >
          {item?.title}
        </Checkbox>
      </div>
    )
  }

  return (
    <CustomAlert
      onClose={() => {
        onCloseAlert?.()
      }}
    >
      <CustomText variant="lgPoppinsSemiBold">{t('addCompanyJobTitle')}</CustomText>
      <div className="border-t border-t-stroke mt-3 mb-3" />
      <div className="max-h-96 overflow-y-auto scrollbar">
        <div className="flex items-center w-auto gap-4">
          <SearchIcon />
          <input
            type="text"
            value={searchTxt}
            placeholder={t('searchPlaceholder')}
            className="outline-0 flex-1 min-w-[100px]"
            onChange={e => setSearchTxt(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          {searchTxt && (
            <button
              onClick={() => {
                setSearchTxt('')
                setOrganizationData(data ?? [])
              }}
            >
              <CloseIcon className="h-5" />
            </button>
          )}
        </div>
        <div className="text-start mt-3 flex flex-col items-start gap-2">
          <CustomText variant="smPoppinsRegular">{`${t('selected')}:`}</CustomText>
          <div className="flex flex-1 w-full flex-wrap gap-2">
            {selectedItems?.length > 0
            && selectedItems?.map((i: OrganizationUnitItem) => {
              return (
                <div className="bg-primary py-1 px-3 flex items-center rounded-full">
                  <CustomText variant="xsmPoppinsRegular" textColor="text-textWhite">
                    {`${i.title}`}
                  </CustomText>
                </div>
              )
            })}
          </div>
        </div>
        <div className="border border-b-0 mt-4 border-stroke">
          <div className="bg-bgColor flex items-center px-4 h-8 border-b border-b-stroke">
            <Checkbox
              checked={organizationData?.length == selectedItems?.length}
              onChange={() => {
                if (organizationData?.length == selectedItems?.length) {
                  setSelectedItems([])
                }
                else {
                  setSelectedItems([...organizationData])
                }
              }}
              className="cm-checkbox"
            >
              {t('allCompanies').toUpperCase()}
            </Checkbox>
          </div>
          <FlatList
            list={organizationData ?? []}
            renderItem={renderItem}
            renderWhenEmpty={() => {
              return (
                <div className="h-10 items-center justify-center flex">
                  <CustomText variant="mdPoppinsRegular">{t('noDataFound')}</CustomText>
                </div>
              )
            }}
          />
        </div>
      </div>
      <div className="mt-3 flex gap-4 justify-end">
        <CustomButton
          disabled={!(selectedItems?.length > 0)}
          children={t('save')}
          backgroundColor="bg-secondary"
          textColor="text-textWhite"
          className={`!px-6 ${selectedItems?.length > 0 ? 'opacity-100' : 'opacity-20'}`}
          onClick={() => {
            onSelectedData?.(selectedItems)
            onCloseAlert?.()
          }}
        />
        <CustomButton
          children={t('cancel')}
          backgroundColor="bg-bthDisable"
          className="!px-6"
          textColor="text-textPrimary"
          onClick={() => {
            onCloseAlert?.()
          }}
        />
      </div>
    </CustomAlert>
  )
}
