import type { JobTitleItem } from '../../packages/api-web/RoleManagement'
import FlatList from 'flatlist-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import defaultTheme from 'tailwindcss/defaultTheme'
import { BorderButton, CustomText, DeleteAlert } from '../../component'
import MetaDataType from '../../component/templates/MetaDataType'
import { RightIcon } from '../../icons'
import { useJobAssign, useRolePositions } from '../../packages/api-web/RoleManagement'

function RoleMetaData() {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const { data: jobTitle } = useRolePositions({
    itemPerPage: 2147483647,
    skip: 0,
    searchTxt: '',
  })
  const { refetch } = useJobAssign()
  const [selectedItem, setSelectedItem] = useState<JobTitleItem>()
  const [isExpandIndex, setIsExpandIndex] = useState<number>(-1)
  const [isShowAlert, setIsShowAlert] = useState<boolean>(false)

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const onClickExpand = (item: JobTitleItem, idx: string) => {
    if (selectedItem?.handle == item?.handle) {
      setSelectedItem(undefined)
      setIsExpandIndex(-1)
    }
    else {
      setSelectedItem(item)
      setIsExpandIndex(Number.parseInt(idx))
    }
  }

  const renderItem = (item: JobTitleItem, idx: string) => {
    return (
      <div
        key={idx}
        className="cursor-pointer mt-1 flex flex-col"
        onClick={() => {
          if (screenWidth < Number.parseInt(defaultTheme.screens.lg.replace('px', ''), 10)) {
            navigate('/role_management/metadata/create', {
              state: {
                jobTitleList: jobTitle?.items ?? [],
                selectedItem: item,
              },
            })
          }
          else {
            onClickExpand(item, idx)
          }
        }}
      >
        <div
          className={`flex items-center justify-between px-3 py-2 ${selectedItem?.handle == item?.handle ? 'bg-table-odd-bg' : ''}`}
        >
          <CustomText variant="smPoppinsRegular">{item?.title ?? ''}</CustomText>
          {item?.jobRoles && item?.jobRoles?.length > 0 && (
            <div
              className="flex w-10 justify-end"
              onClick={(e) => {
                if (screenWidth < Number.parseInt(defaultTheme.screens.lg.replace('px', ''), 10)) {
                  e.stopPropagation()
                  onClickExpand(item, idx)
                }
              }}
            >
              <RightIcon
                className={`${isExpandIndex == Number.parseInt(idx) ? 'rotate-90' : ''} h-4 w-4`}
              />
            </div>
          )}
        </div>
        {isExpandIndex == Number.parseInt(idx)
        && item?.jobRoles
        && item?.jobRoles?.length > 0
        && item?.jobRoles?.map((i, index) => {
          return (
            <div
              key={index}
              className={`py-2 px-8 ${selectedItem?.handle == i?.handle ? 'bg-table-odd-bg' : ''}`}
              onClick={(e) => {
                e.stopPropagation()
                setSelectedItem(i)
                if (
                  screenWidth < Number.parseInt(defaultTheme.screens.lg.replace('px', ''), 10)
                ) {
                  navigate('/role_management/metadata/create', {
                    state: {
                      jobTitleList: jobTitle?.items ?? [],
                      selectedItem: i,
                    },
                  })
                }
              }}
            >
              <CustomText variant="smPoppinsRegular">{i?.title ?? ''}</CustomText>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className="p-5 h-full flex flex-col">
      {isShowAlert && (
        <DeleteAlert
          title={t('automaticUserJobRolesAssigment')}
          subTitle={t('assignJobRoleSubTitle')}
          singleButtonText={t('start')}
          onSingleButton={() => {
            refetch()
          }}
          onCloseAlert={() => {
            setIsShowAlert(false)
          }}
        />
      )}
      <CustomText variant="xlPoppinsSemiBold">{t('metaData')}</CustomText>
      <div className="flex mt-6 mb-4 items-center justify-between">
        <CustomText variant="lgPoppinsSemiBold">{t('overview')}</CustomText>
        <BorderButton
          children={t('assignSpecialization')}
          className="px-7 self-end"
          onClick={() => {
            setIsShowAlert(true)
          }}
        />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 flex-1 overflow-hidden">
        <div className="overflow-y-auto relative flex flex-col border border-stroke bg-white rounded-lg">
          <div className="px-3 flex justify-between h-10 border-b border-b-stroke items-center">
            <CustomText variant="smPoppinsSemiBold">{t('allTypes')}</CustomText>
            <CustomText
              className="!text-secondary"
              variant="smPoppinsSemiBold"
              onClick={() => {
                if (screenWidth < Number.parseInt(defaultTheme.screens.lg.replace('px', ''), 10)) {
                  navigate('/role_management/metadata/create', {
                    state: { jobTitleList: jobTitle?.items ?? [] },
                  })
                }
                else {
                  setSelectedItem(undefined)
                }
              }}
            >
              {`${t('createNew')} +`}
            </CustomText>
          </div>
          <div className="overflow-y-auto scrollbar flex-1 px-3">
            <ul>
              <FlatList list={jobTitle?.items ?? []} renderItem={renderItem} />
            </ul>
          </div>
        </div>
        <div className="ml-5 col-span-2 relative flex flex-col max-lg:hidden overflow-hidden">
          <MetaDataType jobTitleList={jobTitle?.items ?? []} selectedItem={selectedItem} />
        </div>
      </div>
    </div>
  )
}

export default RoleMetaData
