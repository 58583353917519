import { Chart, registerables } from 'chart.js'
import { useTranslation } from 'react-i18next'
import {
  ApprovalStatusCard,
  ComplianceCard,
  CustomDropDown,
  CustomText,
  LineChart,
  StatusValueItem,
} from '../../component'
import { RightIcon } from '../../icons'

Chart.register(...registerables)

function ControlCenterCompliance() {
  const { t } = useTranslation('common')
  const complianceData = [
    {
      title: 'Rule compliance Degree of fulfillment based on existing experience',
      subTitle: 'Descriptive text - explanation of what the diagram shows.',
      percentage: '90',
      taget: 95,
      color: 'stroke-chartGreen',
    },
    {
      title: 'Basic requirements for personal information',
      subTitle: 'Descriptive text - explanation of what the diagram shows.',
      percentage: '74',
      taget: 95,
      color: 'stroke-chartLightYellow',
    },
    {
      title: 'Regulatory compliance based on position type requirements',
      subTitle: 'Descriptive text - explanation of what the diagram shows.',
      percentage: '12',
      taget: 95,
      color: 'stroke-chartRed',
      isEmployee: true,
    },
  ]
  const approvalData = [
    {
      title: 'Policy',
      subTitle: 'Active',
      subTitleValue: '13995',
      leftTitle: 'Approved policy',
      leftTitleValue: '12138',
      rightTitle: 'Unapproved policy',
      rightTitleValue: '1857',
      bottomTitle: 'See user',
    },
    {
      title: 'Experience',
      subTitle: 'Active',
      subTitleValue: '7449',
      leftTitle: 'Approved certificates',
      leftTitleValue: '6441',
      rightTitle: 'Unapproved certificates',
      rightTitleValue: '1008',
      bottomTitle: 'See user',
    },
    {
      title: 'Health certificate',
      subTitle: 'Active',
      subTitleValue: '586',
      leftTitle: 'Approved health certificates',
      leftTitleValue: '471',
      rightTitle: 'Unapproved health certificates',
      rightTitleValue: '115',
      bottomTitle: 'See user',
    },
  ]
  const appUsageData = [
    {
      title: 'Active users',
      subTitle: 'CASE',
      subTitleValue: '1125',
      leftTitle: 'Onboarded',
      leftTitleValue: '95,02%',
      rightTitle: 'Not onboarded',
      rightTitleValue: '486',
      bottomTitle: 'See user',
    },
    {
      title: 'Onboarding',
      subTitle: 'Active',
      subTitleValue: '7449',
      leftTitle: 'Onboarding complete',
      leftTitleValue: '88,68%',
      rightTitle: 'Onboarding in progress',
      rightTitleValue: '55',
      bottomTitle: 'See user',
    },
    {
      title: 'Potential users',
      subTitleValue: '1555',
      leftTitle: 'Logged in',
      leftTitleValue: '72,28%',
      rightTitle: 'Not logged in',
      rightTitleValue: '431',
      bottomTitle: 'See user',
    },
  ]

  const reducedLabels = [
    '2023-01-01',
    '2023-01-02',
    '2023-01-03',
    '2023-01-04',
    '2023-01-05',
    '2023-01-06',
    '2023-01-07',
    '2023-01-08',
    '2023-01-09',
    '2023-01-10',
  ].map((label, index) => (index % 3 === 0 ? label : ''))

  return (
    <div className="p-5 h-full flex flex-col">
      <CustomText variant="xlPoppinsBold">{t('controlCenter')}</CustomText>
      {/* compliance section */}
      <div className="overflow-y-auto scrollbar flex flex-col mt-6">
        <div className="px-5 py-5 bg-white border-stroke border rounded-lg">
          <CustomText variant="lgPoppinsSemiBold">{t('regulatoryCompliance')}</CustomText>
          <div className="mt-5 grid grid-cols-1 lg:grid-cols-3 gap-3">
            {complianceData?.map((item) => {
              return (
                <ComplianceCard
                  title={item?.title}
                  subTitle={item?.subTitle}
                  percentage={item?.percentage}
                  targetLine={item?.taget}
                  color={item?.color}
                  isShowEmployee={item?.isEmployee}
                />
              )
            })}
          </div>
        </div>
        {/* Approval status section */}
        <div className="mt-5 px-5 py-5 bg-white border-stroke border rounded-lg">
          <CustomText variant="lgPoppinsSemiBold">{t('approvalStatus')}</CustomText>
          <div className="mt-5 grid grid-cols-1 lg:grid-cols-3 gap-3">
            {approvalData?.map((item) => {
              return (
                <ApprovalStatusCard
                  title={item?.title}
                  subTitle={item?.subTitle}
                  subTitleValue={item?.subTitleValue}
                  leftTitle={item?.leftTitle}
                  leftValue={item?.leftTitleValue}
                  rightTitle={item?.rightTitle}
                  rightValue={item?.rightTitleValue}
                  rightBottomText={item?.bottomTitle}
                  rightBottomIcon={<RightIcon />}
                />
              )
            })}
          </div>
        </div>
        {/* App usage section */}
        <div className="mt-5 px-5 py-5 bg-white border-stroke border rounded-lg">
          <CustomText variant="lgPoppinsSemiBold">{t('appUsage')}</CustomText>
          {/* Active users section */}
          <div className="mt-5 grid grid-cols-1 lg:grid-cols-3 gap-3">
            {appUsageData?.map((item) => {
              return (
                <ApprovalStatusCard
                  title={item?.title}
                  subTitle={item?.subTitle}
                  subTitleValue={item?.subTitleValue}
                  leftTitle={item?.leftTitle}
                  leftValue={item?.leftTitleValue}
                  rightTitle={item?.rightTitle}
                  rightValue={item?.rightTitleValue}
                  rightBottomText={item?.bottomTitle}
                  rightBottomIcon={<RightIcon />}
                />
              )
            })}
          </div>
          {/* Users over time section */}
          <div className="mt-5 grid grid-cols-1 lg:grid-cols-3 gap-3">
            <div className="col-span-2 rounded-lg border border-stroke">
              <div className="p-3 flex border-b border-stroke items-center justify-between">
                <CustomText variant="mdPoppinsSemiBold">{t('usersOverTime')}</CustomText>
                <CustomDropDown
                  value={{}}
                  className="!mb-3"
                  data={[]}
                  labelField="displayName"
                  valueField="handle"
                  searchable={false}
                  onSelect={(_, item) => {}}
                  onClean={() => {}}
                />
              </div>
              <div className="p-3">
                <LineChart
                  height="25%"
                  width="70%"
                  minYAxis={0}
                  maxYAxis={100}
                  labels={reducedLabels}
                  datasets={[
                    {
                      label: '',
                      data: [12, 45, 90, 30, 30, 40, 90, 12, 45, 90],
                      pointRadius: 0,
                      pointHoverRadius: 0,
                      tension: 0.1,
                      borderWidth: 1,
                    },
                  ]}
                />
              </div>
            </div>
            <div className="flex flex-col flex-1 rounded-lg border border-stroke">
              <div className="p-3 flex border-b border-stroke items-center justify-between">
                <CustomText variant="mdPoppinsSemiBold">{t('sessionsUsers')}</CustomText>
                <CustomDropDown
                  value={{}}
                  className="!mb-3"
                  data={[]}
                  labelField="displayName"
                  valueField="handle"
                  searchable={false}
                  onSelect={(_, item) => {}}
                  onClean={() => {}}
                />
              </div>
              <ApprovalStatusCard
                leftTitle={t('totalSessions')}
                leftValue="20665"
                leftBottomText="229.61 days"
                rightTitle={t('dailySessions')}
                rightValue="152"
                rightBottomText="– 73,14%"
                className="border-0"
              />
              <div className="flex flex-1 border-t border-stroke">
                <div className="flex w-1/2 border-r border-stroke items-center">
                  <LineChart
                    minYAxis={0}
                    maxYAxis={3}
                    labels={['1', '2', '3']}
                    datasets={[
                      {
                        label: '',
                        data: [1, 0, 1],
                        pointRadius: 0,
                        pointHoverRadius: 0,
                        tension: 0.1,
                        borderWidth: 1,
                      },
                    ]}
                  />
                </div>
                <div className="flex w-1/2 items-center">
                  <LineChart
                    minYAxis={0}
                    maxYAxis={3}
                    labels={['1', '2', '3']}
                    datasets={[
                      {
                        label: '',
                        data: [1, 0, 1],
                        pointRadius: 0,
                        pointHoverRadius: 0,
                        tension: 0.1,
                        borderWidth: 1,
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Active users chart section */}
          <div className="mt-5 rounded-lg border border-stroke">
            <div className="p-3 flex border-b border-stroke items-center justify-between">
              <CustomText variant="mdPoppinsSemiBold">Active users</CustomText>
              <CustomDropDown
                value={{}}
                className="!mb-3"
                data={[]}
                labelField="displayName"
                valueField="handle"
                searchable={false}
                onSelect={(_, item) => {}}
                onClean={() => {}}
              />
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-3">
              <div className="p-3 flex col-span-2 rounded-lg items-center">
                <LineChart
                  height="30%"
                  width="70%"
                  minYAxis={0}
                  maxYAxis={100}
                  labels={reducedLabels}
                  datasets={[
                    {
                      label: '',
                      data: [12, 45, 90, 30, 30, 40, 90, 12, 45, 90],
                      pointRadius: 0,
                      pointHoverRadius: 0,
                      tension: 0.1,
                      borderWidth: 1,
                    },
                  ]}
                />
              </div>
              <div className="lg:border-l border-stroke">
                {Array.from({ length: 3 })
                  .fill(0)
                  .map((_, index) => {
                    return (
                      <div className={`${index !== 0 ? ' border-t' : ''} border-stroke p-3`}>
                        <StatusValueItem title="Per month" value="484" bottomText="– 5.4%" />
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ControlCenterCompliance
