import type { CertificateItems, PermitsItem, UserItem } from '../../packages/api-web'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { queryClient } from '../../App'
import {
  AddCertificateModal,
  BorderButton,
  CustomButton,
  CustomDropDown,
  CustomInput,
  CustomText,
  DeleteAlert,
  Spinner,
  TagView,
  TimeLine,
} from '../../component'
import CustomDatePicker from '../../component/atoms/CustomDatePicker'
import { UserContext } from '../../contexts/UserContext'
import { Fields, globalDateFormat } from '../../helpers'
import {
  BlueDraftIcon,
  DraftCheck2Icon,
  DraftCheckIcon,
  DraftIssuedIcon,
  DraftShareIcon,
} from '../../icons'
import {
  useCheckPermitEligibility,
  useManagerFilterList,
  usePostEmployeeList,
  usePostPermits,
} from '../../packages/api-web'

function CreateNewDrivingPermit() {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { user } = useContext(UserContext)
  const [selectedHolder, setSelectedHolder] = useState<UserItem>()
  const [searchHolder, setSearchHolder] = useState<string>('')
  const [selectedIssuer, setselectedIssuer] = useState<UserItem>()
  const [searchIssuer, setSearchIssuer] = useState<string>('')
  const [startDate, setStartDate] = useState<Date | null>(new Date())
  const [endDate, setEndDate] = useState<Date | null>()
  const [isAddCertificate, setIsAddCertificate] = useState<boolean>(false)
  const [errorString, setErrorString] = useState<string[]>([])
  const [isCheckHolder, setIsCheckHolder] = useState<boolean>(false)
  const [isNotEligibility, setIsNotEligibility] = useState<boolean>(false)
  const [eligibilityMessage, setEligibilityMessage] = useState<string>('')
  const [trainer, setTrainer] = useState<string>('')
  const [tools, setTools] = useState<string>('')
  const [selectedItem, setSelectedItem] = useState<CertificateItems[]>()
  const {
    data: holderList,
    isFetching: isHolderList,
    refetch: refetchHolderList,
  } = useManagerFilterList({ filterTxt: searchHolder })
  const {
    mutate: employeeMutate,
    isPending: isIssuerList,
    data: issuerList,
  } = usePostEmployeeList()
  const { mutate: permitsMutate, isPending: isPostPermits } = usePostPermits()
  const {
    data: eligibilityData,
    isFetching: isEligibility,
    refetch: refetchEligibility,
  } = useCheckPermitEligibility({ userHandle: selectedHolder?.handle })
  const step = [
    {
      icon: <BlueDraftIcon />,
      title: t('draftCreated'),
      description: `${globalDateFormat(new Date().toDateString())} \n${user?.userData?.displayName}`, // '2024-10-01 \nPernilla Mårtensson',
      active: true,
    },
    {
      icon: <DraftShareIcon />,
      title: t('sentForSignature'),
      description: `[YYYY-MM-DD]\n[${t('admin')} / ${t('creator')}]`,
      active: false,
    },
    {
      icon: <DraftCheckIcon />,
      title: t('signatureResponsible'),
      description: `[YYYY-MM-DD]\n[${t('responsible')}]`,
      active: false,
    },
    {
      icon: <DraftCheck2Icon />,
      title: t('receiptEmployee'),
      description: `[YYYY-MM-DD]\n[${t('employee')}]`,
      active: false,
    },
    {
      icon: <DraftIssuedIcon />,
      title: t('drivingLicenseIssued'),
      description: '',
      active: false,
    },
  ]

  const holderData = useMemo(() => {
    const result = holderList?.items
    return result
  }, [holderList])

  const issuerData = useMemo(() => {
    const result = issuerList?.items
    return result
  }, [issuerList])

  useEffect(() => {
    // Debounce logic
    const debounce = setTimeout(() => {
      if (searchHolder.length >= 3) {
        refetchHolderList()
      }
      else if (searchHolder.length == 0 && !selectedHolder) {
        refetchHolderList()
      }
    }, 1000)

    return () => clearTimeout(debounce)
  }, [searchHolder, selectedHolder])

  useEffect(() => {
    // Debounce logic
    const debounce = setTimeout(() => {
      if (searchIssuer.length >= 3) {
        const data: any = {
          cursor: '0',
          take: 20,
          filter: searchIssuer,
        }
        employeeMutate(data)
      }
      else if (searchIssuer.length == 0 && !selectedIssuer) {
        const data: any = {
          cursor: '0',
          take: 20,
        }
        employeeMutate(data)
      }
    }, 1000)

    return () => clearTimeout(debounce)
  }, [searchIssuer, selectedIssuer])

  useEffect(() => {
    if (eligibilityData) {
      queryClient.removeQueries({
        queryKey: [QueryKey.getPermitEligibility, selectedHolder?.handle],
      })
      if (eligibilityData?.isPermitEligible) {
        const data: PermitsItem = {
          creator: { handle: user?.userData?.handle },
          holder: { handle: selectedHolder?.handle },
          issuer: { handle: selectedIssuer?.handle },
          startDate: startDate?.toDateString(),
          endDate: endDate?.toDateString(),
          certificates:
            selectedItem
            && selectedItem?.map((i) => {
              return { handle: i?.certificateHandle }
            }),
          fields: [
            { name: Fields?.trainer, value: trainer },
            { name: Fields?.tools, value: tools },
          ],
        }
        permitsMutate(data, {
          onSuccess: (response) => {
            navigate(`/driving/permit/${response?.item?.handle}`, {
              state: { holderHandle: response?.item?.holder?.handle },
              replace: true,
            })
          },
        })
      }
      else {
        setIsNotEligibility(true)
        setEligibilityMessage(eligibilityData?.message ?? '')
      }
    }
  }, [eligibilityData])

  const onSavePress = () => {
    if (selectedHolder && selectedIssuer && startDate) {
      setErrorString([])
      refetchEligibility()
    }
    else {
      const errors = []
      if (!selectedHolder)
        errors.push(t('errorDrivingLicense'))
      if (!selectedIssuer)
        errors.push(t('errorSignPerson'))
      if (!startDate)
        errors.push(t('errorValidDate'))
      setErrorString(errors)
    }
  }

  return (
    <div className="p-5 h-full flex flex-col">
      {(isPostPermits || isEligibility) && <Spinner />}
      {isNotEligibility && (
        <DeleteAlert
          title="Warning"
          subTitle={eligibilityMessage}
          singleButtonText="Ok"
          onCloseAlert={() => {
            setIsNotEligibility(false)
          }}
          onSingleButton={() => {
            setIsNotEligibility(false)
          }}
        />
      )}
      {isAddCertificate && (
        <AddCertificateModal
          selectedItems={selectedItem}
          handle={selectedHolder?.handle}
          onCloseAlert={() => {
            setIsAddCertificate(false)
          }}
          selectedCertificates={(i) => {
            setSelectedItem(i)
          }}
        />
      )}
      <CustomText variant="xlPoppinsBold">{t('drivingLicense')}</CustomText>
      <div className="flex flex-1 flex-col mt-6 px-5 bg-white rounded-lg border border-stroke h-[100%] overflow-hidden">
        <CustomText variant="lgPoppinsSemiBold" className="my-3">
          {t('addNewDriving')}
        </CustomText>
        <div className="flex-1 overflow-hidden overflow-y-auto scrollbar">
          <div className="flex flex-1 border border-stroke justify-between py-3 px-3 rounded-lg">
            <div className="flex-1 px-5 py-2">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                <div className="flex flex-1 flex-col">
                  <CustomDropDown
                    value={selectedHolder?.handle}
                    loading={isHolderList}
                    className="!mb-3"
                    title={t('fieldDrivingLicense')}
                    data={holderData ?? []}
                    labelField="displayName"
                    valueField="handle"
                    onSearch={(e) => {
                      setSearchHolder(e)
                    }}
                    onSelect={(_, item) => {
                      setSelectedHolder(item as UserItem)
                    }}
                    onClean={() => {
                      setSelectedHolder(undefined)
                      setSearchHolder('')
                    }}
                  />
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mb-5 mt-5">
                    <CustomInput
                      title={t('fieldSection')}
                      value={selectedHolder?.department}
                      placeholder={t('fieldSectionPlaceholder')}
                      disabled
                    />
                    <CustomInput
                      title={t('fieldBusiness')}
                      value={selectedHolder?.userOrganizationUnits
                        ?.map(i => i.title || '-')
                        .join(', ')}
                      placeholder={t('fieldBusinessPlaceholder')}
                      onChange={(e) => {}}
                      disabled
                    />
                  </div>
                  <div className="mb-5">
                    <CustomDropDown
                      value={selectedIssuer?.handle}
                      loading={isIssuerList}
                      className="!mb-3"
                      title={t('fieldSignDrivingPermit')}
                      data={issuerData ?? []}
                      labelField="displayName"
                      valueField="handle"
                      onSearch={(e) => {
                        setSearchIssuer(e)
                      }}
                      onSelect={(_, item) => {
                        setselectedIssuer(item as UserItem)
                      }}
                      onClean={() => {
                        setselectedIssuer(undefined)
                        setSearchIssuer('')
                      }}
                    />
                  </div>
                  <CustomInput
                    title={t('fieldTrainer')}
                    className="mb-5"
                    value={trainer}
                    placeholder={t('fieldTrainerPlaceholder')}
                    onChange={(e) => {
                      setTrainer(e.target.value)
                    }}
                  />
                  <CustomInput
                    title={t('fieldTools')}
                    className="mb-5"
                    value={tools}
                    placeholder={t('fieldToolsPlaceholder')}
                    onChange={(e) => {
                      setTools(e.target.value)
                    }}
                  />
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mb-5">
                    <CustomDatePicker
                      title={t('fieldValidFrom')}
                      minDate={new Date()}
                      value={startDate}
                      placeholder="DD-MM-YYYY"
                      onChange={(value) => {
                        if (value) {
                          setStartDate(value)
                        }
                      }}
                    />
                    <CustomDatePicker
                      disabled={!startDate}
                      title={t('fieldValidTo')}
                      minDate={startDate}
                      value={endDate}
                      placeholder="DD-MM-YYYY"
                      onChange={(value) => {
                        if (value) {
                          setEndDate(value)
                        }
                      }}
                    />
                  </div>
                  <CustomText variant="mdPoppinsSemiBold">
                    {t('fieldDrivingLicenseRefers')}
                  </CustomText>
                  <BorderButton
                    children={`+ ${t('addExperience')}`}
                    className="px-4 self-start mt-5"
                    onClick={() => {
                      if (!selectedHolder) {
                        setIsCheckHolder(true)
                      }
                      else {
                        setIsCheckHolder(false)
                        setIsAddCertificate(true)
                      }
                    }}
                  />
                  {selectedItem && (
                    <div className="flex flex-wrap gap-2 py-3">
                      {selectedItem?.map((i) => {
                        return <TagView className="border-secondary border" title={i?.title} />
                      })}
                    </div>
                  )}
                  {isCheckHolder && (
                    <CustomText
                      variant="smPoppinsRegular"
                      textColor="text-textDanger"
                      className="mt-2"
                    >
                      {t('errorDrivingLicenseRefers')}
                    </CustomText>
                  )}
                  {errorString.length > 0 && (
                    <ul className="mt-5 list-disc pl-4">
                      {errorString.map((i, index) => (
                        <li key={index} className="text-textDanger">
                          {i}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <div className="flex flex-1 flex-col items-center">
                  <TimeLine step={step} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative flex gap-4 justify-center sm:justify-end py-4 rounded-md">
          <BorderButton
            children={t('back')}
            className="!px-14"
            textColor="text-textPrimary"
            onClick={() => {
              navigate(-1)
            }}
          />
          <CustomButton
            children={t('save')}
            backgroundColor="bg-secondary"
            textColor="text-textWhite"
            onClick={onSavePress}
          />
        </div>
      </div>
    </div>
  )
}

export default CreateNewDrivingPermit
