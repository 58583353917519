import type { MinimizedUserDto } from '@inphiz-shared/shared-models/src/Dto/minimized-user-dto'
import type { KeyboardEvent } from 'react'
import { APIConst, QueryKey } from '@inphiz/api'
import FlatList from 'flatlist-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox } from 'rsuite'
import { queryClient } from '../../../App'
import { CloseIcon, SearchIcon, SortIcon } from '../../../icons'
import { useEquipmentUsers, usePostEquipmentAssign } from '../../../packages/api-web/Equipment'
import {
  BorderButton,
  CustomAlert,
  CustomButton,
  CustomImg,
  CustomText,
  Spinner,
} from '../../atoms'

interface GroupMembersProps {
  handle?: string
  onCloseAlert?: () => void
}

export function EquipmentAssignModal({ handle, onCloseAlert }: GroupMembersProps) {
  const { t } = useTranslation('common')
  const [searchTxt, setSearchTxt] = useState<string>('')
  const [sorting, setSorting] = useState<string>('')
  const [selectedItem, setSelectedItem] = useState<MinimizedUserDto[]>([])
  const [membersList, setMembersList] = useState<MinimizedUserDto[]>([])
  const {
    data,
    isLoading,
    refetch: fetch,
  } = useEquipmentUsers({ filterTxt: searchTxt, isEnabled: true })
  const { isPending, mutate } = usePostEquipmentAssign()

  useEffect(() => {
    setMembersList(data?.items ?? [])
  }, [data])

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter')
      fetch()
  }

  const renderItem = (item: MinimizedUserDto, idx: string) => {
    return (
      <div
        className={`flex flex-1 h-12 pl-4 items-center ${Number.parseInt(idx) != membersList?.length - 1 ? 'border-b' : ''} border-stroke ${Number.parseInt(idx) % 2 == 0 ? 'bg-table-odd-bg' : ''}`}
      >
        <div className="flex items-center flex-1 h-12 border-r border-stroke">
          <CustomImg
            name={item?.displayName}
            imgSrc={
              item?.profileImageHandle
                ? `${APIConst.baseURL}/${APIConst.mediaUpload}/${item?.profileImageHandle}`
                : ''
            }
            altText="user_image"
            className="h-9 w-9 rounded-full mr-4"
            imgClassName="rounded-full"
          />
          <CustomText wrapText={false} variant="mdPoppinsRegular">
            {item?.displayName ?? ''}
          </CustomText>
        </div>
        <Checkbox
          checked={selectedItem?.includes(item)}
          onChange={() => {
            if (selectedItem?.includes(item)) {
              const temp = [...selectedItem]
              const index = selectedItem.indexOf(item)
              temp.splice(index, 1)
              setSelectedItem(temp)
            }
            else {
              setSelectedItem([...selectedItem, item])
            }
          }}
        />
      </div>
    )
  }

  const recursivePost = (index: number) => {
    if (index < selectedItem?.length) {
      const data = {
        userHandle: selectedItem[index]?.handle,
        equipmentTypeHandle: handle,
      }
      mutate(data, {
        onSuccess: () => {
          recursivePost(index + 1)
        },
      })
    }
    else {
      queryClient.invalidateQueries({ queryKey: [QueryKey.byEquipmentType, handle] })
      onCloseAlert?.()
    }
  }

  if (isLoading)
    return <Spinner />

  return (
    <CustomAlert
      onClose={() => {
        onCloseAlert?.()
      }}
    >
      {isPending && <Spinner />}
      <CustomText variant="mdPoppinsSemiBold">{t('assignUsers')}</CustomText>
      <div className="border border-stroke rounded-md mt-4">
        <div className="flex items-center w-auto gap-4 h-10 px-4 border-b border-stroke">
          <SearchIcon />
          <input
            type="text"
            value={searchTxt}
            placeholder={t('searchPlaceholder')}
            className="outline-0 flex-1 min-w-[100px]"
            onChange={e => setSearchTxt(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          {searchTxt && (
            <button
              onClick={() => {
                setSearchTxt('')
                setTimeout(() => {
                  fetch()
                }, 1000)
              }}
            >
              <CloseIcon className="h-5" />
            </button>
          )}
        </div>
        <div
          className="flex items-center h-12 px-4 border-b border-stroke"
          onClick={() => {
            const temp = [...membersList]
            if (sorting == '' || sorting == 'asc') {
              setMembersList(
                temp.sort((a: any, b: any) => (a.displayName > b.displayName ? -1 : 1)),
              )
              setSorting('des')
            }
            else {
              setMembersList(
                temp.sort((a: any, b: any) => (a.displayName > b.displayName ? 1 : -1)),
              )
              setSorting('asc')
            }
          }}
        >
          <CustomText variant="mdPoppinsMedium">{t('name')}</CustomText>
          <SortIcon
            className={`h-5 w-5 ml-4 stroke-[#C9C9C9] ${sorting === 'asc' ? 'rotate-180' : ''}`}
          />
        </div>
        <div className="max-h-52 overflow-hidden overflow-y-auto scrollbar">
          <FlatList
            list={membersList}
            renderItem={renderItem}
            renderWhenEmpty={() => {
              return (
                <div className="h-10 flex flex-1 items-center justify-center">
                  <CustomText variant="mdPoppinsRegular">{t('noDataFound')}</CustomText>
                </div>
              )
            }}
          />
        </div>
        <div className="flex py-4 px-4 justify-end border-t border-stroke">
          <div className="flex gap-4 flex-wrap justify-end">
            <BorderButton
              children={t('cancel')}
              className="!px-2 flex-1"
              textColor="text-textPrimary"
              onClick={() => {
                onCloseAlert?.()
              }}
            />
            <CustomButton
              children={t('clear')}
              className="!px-4 flex-1"
              backgroundColor="bg-bthDisable"
              textColor="text-textPrimary"
              onClick={() => {
                setSelectedItem([])
              }}
            />
            <CustomButton
              children={t('add')}
              className="px-4 sm:px-8 bg-secondary"
              textColor="text-textWhite"
              onClick={() => {
                recursivePost(0)
              }}
            />
          </div>
        </div>
      </div>
    </CustomAlert>
  )
}
