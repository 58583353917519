import type { KeyboardEvent } from 'react'
import { EGroupSubscriberType } from '@inphiz-shared/shared-models/src/F_News/e-group-subscriber-type'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Checkbox } from 'rsuite'
import {
  BorderButton,
  CustomButton,
  CustomInput,
  CustomPagination,
  CustomTable,
  CustomText,
  DeleteAlert,
  GroupMembersModal,
  Spinner,
} from '../../component'
import { dateTimeFormat, getEnumKeysFromValue } from '../../helpers'
import { CloseIcon, RightIcon, SearchIcon } from '../../icons'
import {
  useDeleteGroup,
  useDeleteMember,
  useGroupDetails,
  useGroupMemberList,
  usePostGroup,
  usePutGroup,
} from '../../packages/api-web'

function GroupCreate() {
  const { t } = useTranslation('common')
  const { id } = useParams()
  const navigate = useNavigate()
  const [isMemberTab, setIsMemberTab] = useState<boolean>(false)
  const [isEnableMember, setIsEnableMember] = useState<boolean>(!!id)
  const [isEditEnable, setIsEditEnable] = useState<boolean>(false)
  const [isAddMember, setIsAddMember] = useState<boolean>(false)
  const [isDeleteAlert, setIsDeleteAlert] = useState<boolean>(false)
  const [isDeleteGroupAlert, setIsDeleteGroupAlert] = useState<boolean>(false)
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [searchTxt, setSearchTxt] = useState<string>('')
  const [errorString, setErrorString] = useState<string[]>([])
  const [itemPerPage, setItemPage] = useState(20)
  const [skipItem, setSkipItem] = useState(0)
  const [selectedMember, setSelectedMember] = useState<any>()
  const [checkBoxs, setCheckBoxs] = useState([
    { title: t('contacts'), isCheck: false, key: 'Contacts' },
    { title: t('news'), isCheck: true, key: EGroupSubscriberType.News },
    { title: t('documents'), isCheck: true, key: EGroupSubscriberType.Documents },
    { title: t('policies'), isCheck: true, key: EGroupSubscriberType.Policies },
    {
      title: t('snack'),
      isCheck: true,
      key: EGroupSubscriberType.Socials,
      childs: [
        {
          id: 1,
          title: t('OmanagersCanPostConfirmation'),
          isCheck: true,
          key: 'OnlyManagerCanPostWithConfirmation',
        },
        { id: 1, title: t('managersCanPost'), isCheck: false, key: 'OnlyManagerCanPost' },
        { id: 2, title: 'Sensitive content check enabled', isCheck: false, key: 'Enabled' },
        { id: 2, title: 'Sensitive content check disabled', isCheck: true, key: 'Disabled' },
      ],
    },
    { title: t('microPoll'), isCheck: true, key: EGroupSubscriberType.FeedbackCampaign },
  ])
  const {
    isLoading,
    data: groupDetail,
    refetch: fetchGroupDetail,
  } = useGroupDetails({
    handle: id,
  })
  const { data: membersData, refetch: fetchMemberList } = useGroupMemberList({
    groupHandle: id,
    itemPerPage,
    skip: skipItem,
    searchTxt,
  })
  const { mutate } = useDeleteMember()
  const { isPending, mutate: editGroupMutate } = usePutGroup()
  const { isPending: isAddGroupPending, mutate: addGroupMutate } = usePostGroup()
  const { isPending: isDeleteGroup, mutate: deleteGroupMutate } = useDeleteGroup()

  const tableHeaderList = useMemo(
    () => [
      { title: t('name'), id: 1, key: 'user.displayName' },
      { title: t('jobTitle'), id: 2, key: 'user.jobTitle' },
      { title: t('department'), id: 3, key: 'user.department' },
      { title: t('subscriptionType'), id: 4, key: 'isCustom' },
      { title: '', id: 5, key: '' },
    ],
    [t],
  )

  useEffect(() => {
    if (id) {
      fetchGroupDetail()
    }
  }, [id])

  useEffect(() => {
    if (id) {
      fetchMemberList()
    }
  }, [id, skipItem, itemPerPage])

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter')
      fetchMemberList()
  }

  const onSavePress = () => {
    if (title && description) {
      const filter: any = checkBoxs.filter(i => i?.isCheck && i.key !== 'Contacts').map(e => e.key)
      const socialFilter: any = checkBoxs
        .find(i => i?.isCheck && i.key == EGroupSubscriberType.Socials)
        ?.childs
        ?.filter(i => i?.isCheck && i?.id == 1)
        .map(e => e.key)
      const sensitiveFilter: any = checkBoxs
        .find(i => i?.isCheck && i.key == EGroupSubscriberType.Socials)
        ?.childs
        ?.filter(i => i?.isCheck && i?.id == 2)
        .map(e => e.key)
      const contentFilter: any = checkBoxs?.find(i => i?.isCheck && i.key == 'Contacts')
        ? 'Enabled'
        : 'Disabled'
      if (id) {
        const data = {
          ownerHandle: groupDetail?.item?.ownerHandle,
          ownerContentType: groupDetail?.item?.ownerContentType,
          authorHandle: groupDetail?.item?.authorHandle,
          title,
          description,
          subscriptionFlowType: groupDetail?.item?.subscriptionFlowType,
          handle: groupDetail?.item?.handle,
          allowedGroupSubscribers:
            1
            + filter.reduce(
              (accumulator: number, currentValue: number) => accumulator + currentValue,
              0,
            ),
          permissions: {
            ShowMembersInContacts: contentFilter,
            Social: socialFilter?.length > 0 ? socialFilter[0] : null,
            SensitiveContentCheck: sensitiveFilter?.length > 0 ? sensitiveFilter[0] : null,
          },
        }
        editGroupMutate(data, {
          onSuccess: () => {
            setIsEditEnable(false)
          },
        })
      }
      else {
        const data = {
          title,
          description,
          subscriptionFlowType: 1,
          allowedGroupSubscribers:
            1
            + filter.reduce(
              (accumulator: number, currentValue: number) => accumulator + currentValue,
              0,
            ),
          permissions: {
            ShowMembersInContacts: contentFilter,
            Social: socialFilter?.length > 0 ? socialFilter[0] : null,
            SensitiveContentCheck: sensitiveFilter?.length > 0 ? sensitiveFilter[0] : null,
          },
        }
        addGroupMutate(data, {
          onSuccess: (data: any) => {
            setIsEnableMember(true)
            navigate(`/group/${data?.item?.handle}`, { replace: true })
          },
        })
      }
    }
    else {
      const errors = []
      if (!title)
        errors.push(t('equipmentTitle'))
      if (!description)
        errors.push(t('errorDescription'))
      setErrorString(errors)
    }
  }

  const onEditPress = () => {
    setTitle(groupDetail?.item?.title ?? '')
    setDescription(groupDetail?.item?.description ?? '')
    const response: any = groupDetail?.item?.allowedGroupSubscribers && [
      ...(groupDetail?.item?.permissions?.ShowMembersInContacts === 'Enabled'
        ? [{ key: 'Contacts', val: 'Contacts' }]
        : []),
      ...getEnumKeysFromValue(
        groupDetail?.item?.allowedGroupSubscribers - EGroupSubscriberType.Users,
      ),
    ]
    const updatedCheckBoxs: any = checkBoxs.map(item => ({
      ...item,
      isCheck: response?.some((resp: any) => resp.val === item.key),
      childs:
        item.childs && response?.some((resp: any) => resp.val === EGroupSubscriberType.Socials)
          ? item.childs.map(child => ({
              ...child,
              isCheck:
                child.key === groupDetail?.item?.permissions?.Social
                || child.key === groupDetail?.item?.permissions?.SensitiveContentCheck,
            }))
          : item.childs,
    }))
    setCheckBoxs(updatedCheckBoxs)
    setIsEditEnable(true)
  }

  const handleCheckboxChange = (
    data: any,
    selectedKey: string,
    groupId: number,
    indexOf: number,
  ) => {
    const temp = data.map((checkbox: any) =>
      checkbox.id === groupId ? { ...checkbox, isCheck: checkbox.key === selectedKey } : checkbox,
    )
    const tempArray: any = [...checkBoxs]
    tempArray[indexOf].childs = temp ?? []
    setCheckBoxs(tempArray)
  }

  if (isLoading)
    return <Spinner />

  return (
    <div className="p-5 h-full flex flex-col">
      {(isPending || isAddGroupPending || isDeleteGroup) && <Spinner />}
      {isAddMember && (
        <GroupMembersModal
          handle={id}
          onCloseAlert={() => {
            setIsAddMember(false)
          }}
        />
      )}
      {isDeleteGroupAlert && (
        <DeleteAlert
          title={`${t('archive')} ${t('group')}`}
          subTitle={`${t('archiveTheGroup')} ${groupDetail?.item?.title}?`}
          leftButtonText={t('cancel')}
          rightButtonText={t('archive')}
          onCloseAlert={() => {
            setIsDeleteGroupAlert(false)
          }}
          onLeftButton={() => {
            setIsDeleteGroupAlert(false)
          }}
          onRightButton={() => {
            const data = {
              groupHandles: id,
            }
            deleteGroupMutate(data, {
              onSuccess: () => {
                navigate(-1)
              },
            })
          }}
        />
      )}
      {isDeleteAlert && (
        <DeleteAlert
          title={`${t('remove')} ${selectedMember?.user?.displayName} ${t('from')} ${groupDetail?.item?.title}`}
          subTitle={`${t('removeTheUser')} ${selectedMember?.user?.displayName} ${t('from')} ${groupDetail?.item?.title}?`}
          leftButtonText={t('cancel')}
          rightButtonText={t('delete')}
          onCloseAlert={() => {
            setIsDeleteAlert(false)
          }}
          onLeftButton={() => {
            setIsDeleteAlert(false)
          }}
          onRightButton={() => {
            const data = {
              groupHandles: id,
              subscriberHandle: selectedMember?.user?.handle,
            }
            mutate(data, {
              onSuccess: () => {
                setIsDeleteAlert(false)
              },
            })
          }}
        />
      )}
      <CustomText variant="xlPoppinsBold">
        {id ? `${t('edit')} ${t('group')} ${groupDetail?.item?.title}` : t('createNewGroup')}
      </CustomText>
      <div className="flex mt-6 -mb-[2px] z-50">
        <div
          className={`${isMemberTab ? 'bg-draft' : 'bg-white'} rounded-tl-lg py-6 w-44 text-center border border-b-0 border-stroke`}
          onClick={() => {
            setIsMemberTab(false)
          }}
        >
          <CustomText variant="mdPoppinsSemiBold">{t('aboutGroup')}</CustomText>
        </div>
        <div
          className={`${isMemberTab ? 'bg-white' : 'bg-draft'} rounded-tr-lg py-6 w-44 text-center border border-l-0 border-b-0 border-stroke`}
          onClick={() => {
            if (isEnableMember)
              setIsMemberTab(true)
          }}
        >
          <CustomText
            variant="mdPoppinsSemiBold"
            className={`${!isEnableMember ? 'opacity-40' : ''}`}
          >
            {t('members')}
          </CustomText>
        </div>
      </div>
      <div className="px-5 bg-white rounded-lg rounded-tl-none border border-stroke flex flex-col overflow-hidden">
        {isMemberTab ? (
          <div className="mt-6 mb-6 bg-white rounded-lg flex flex-col flex-1 overflow-hidden">
            <div className="flex flex-col sm:flex-row mb-4 gap-4">
              <CustomText className="mr-auto self-center" wrapText={false} variant="xlPoppinsBold">
                {`${t('total')}: ${membersData?.totalCount ?? 0}`}
              </CustomText>
              <div className="flex flex-1 items-center justify-between">
                <div className="flex items-center w-auto gap-4">
                  <SearchIcon />
                  <input
                    type="text"
                    value={searchTxt}
                    placeholder={t('searchPlaceholder')}
                    className="outline-0 flex-1 min-w-[100px]"
                    onChange={e => setSearchTxt(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                  {searchTxt && (
                    <button
                      onClick={() => {
                        setSearchTxt('')
                        setTimeout(() => {
                          fetchMemberList()
                        }, 1000)
                      }}
                    >
                      <CloseIcon className="h-5" />
                    </button>
                  )}
                </div>
                <CustomButton
                  children={t('addMembers')}
                  textVariant="smPoppinsMedium"
                  className="px-4 sm:px-8 bg-secondary"
                  onClick={() => {
                    setIsAddMember(true)
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col overflow-hidden rounded-lg border border-stroke">
              <div className="flex-1 overflow-x-auto overflow-y-auto scrollbar bg-white">
                <CustomTable
                  tableHeaderList={tableHeaderList}
                  tableDataList={membersData?.items}
                  displayKeys={[
                    'user.displayName',
                    'user.jobTitle',
                    'user.department',
                    'isCustom',
                    'rightIcon',
                  ]}
                  rightIcon={<CloseIcon />}
                  onRightIcon={(item) => {
                    setSelectedMember(item)
                    setIsDeleteAlert(true)
                  }}
                />
              </div>
              {membersData && membersData?.items && membersData?.items?.length > 0 && (
                <CustomPagination
                  totalItem={membersData?.totalCount}
                  itemPerPage={itemPerPage}
                  onChange={setItemPage}
                  onPagePress={index => setSkipItem((index - 1) * itemPerPage)}
                />
              )}
            </div>
          </div>
        ) : id && !isEditEnable
          ? (
              <div className="flex-1 overflow-hidden overflow-y-auto scrollbar md:w-2/4 self-center py-5 px-2">
                <CustomText variant="mdPoppinsSemiBold" className="mb-1">
                  {t('groupName')}
                </CustomText>
                <CustomText variant="mdPoppinsRegular" className="mb-3">
                  {groupDetail?.item?.title ?? ''}
                </CustomText>
                <CustomText variant="mdPoppinsSemiBold" className="mb-1">
                  {t('description')}
                </CustomText>
                <CustomText variant="mdPoppinsRegular" className="mb-3">
                  {groupDetail?.item?.description ?? ''}
                </CustomText>
                <CustomText variant="mdPoppinsSemiBold">{t('connectedFunctions')}</CustomText>
                <div className="flex flex-wrap mb-3">
                  {(() => {
                    const items: any = groupDetail?.item?.allowedGroupSubscribers && [
                      ...(groupDetail?.item?.permissions?.ShowMembersInContacts === 'Enabled'
                        ? [{ key: 'Contacts', val: 'Contacts' }]
                        : []),
                      ...getEnumKeysFromValue(
                        groupDetail?.item?.allowedGroupSubscribers - EGroupSubscriberType.Users,
                      ),
                    ]

                    return items.length > 0
                      ? (
                          items.map((i: any) => (
                            <div
                              className="py-1 px-3 mr-3 mt-3 border rounded-full border-secondary"
                              key={i?.key || i?.val}
                            >
                              <CustomText variant="mdPoppinsRegular">
                                {checkBoxs?.find(item => item?.key == i?.val)?.title ?? ''}
                              </CustomText>
                            </div>
                          ))
                        )
                      : (
                          <div className="bg-bubble py-2 px-3 mr-3 mt-3">
                            <CustomText variant="mdPoppinsRegular">{`N/A or ${t('skipped')}`}</CustomText>
                          </div>
                        )
                  })()}
                </div>
                <CustomText variant="mdPoppinsSemiBold" className="mb-1">
                  {t('typeOfGroup')}
                </CustomText>
                <CustomText variant="mdPoppinsRegular" className="mb-3">
                  {groupDetail?.item?.subscriptionFlowType == 1
                    ? t('customized')
                    : t('automaticallyGenerated')}
                </CustomText>
                <CustomText variant="mdPoppinsSemiBold" className="mb-1">
                  {t('author')}
                </CustomText>
                <CustomText variant="mdPoppinsRegular" className="mb-3">
                  {groupDetail?.item?.author?.displayName ?? 'System'}
                </CustomText>
                <CustomText variant="mdPoppinsSemiBold" className="mb-1">
                  {t('createdDate')}
                </CustomText>
                <CustomText variant="mdPoppinsRegular" className="mb-3">
                  {dateTimeFormat(groupDetail?.item?.createdTime ?? '')}
                </CustomText>
              </div>
            )
          : (
              <div className="flex-1 overflow-hidden overflow-y-auto scrollbar md:w-2/4 self-center py-5 px-2">
                <CustomInput
                  title={t('groupName')}
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                  placeholder={t('groupName')}
                  className={`mb-3 ${!title && errorString.length > 0 ? 'outline-textDanger' : ''}`}
                />
                <CustomInput
                  title={t('description')}
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  placeholder={t('description')}
                  className={`mb-3 ${!description && errorString.length > 0 ? 'outline-textDanger' : ''}`}
                />
                <CustomText variant="mdPoppinsSemiBold" className="mb-3">
                  {t('connectedFunctions')}
                </CustomText>
                <div className="flex flex-col">
                  {checkBoxs?.map((item, index) => {
                    return (
                      <div className="flex flex-col">
                        <div className="flex">
                          <Checkbox
                            checked={item?.isCheck}
                            onChange={() => {
                              const temp = [...checkBoxs]
                              temp[index].isCheck = !item?.isCheck
                              setCheckBoxs(temp)
                            }}
                            className="cm-checkbox flex-1 h-10"
                          >
                            {item?.title}
                          </Checkbox>
                          {item?.childs && <RightIcon className="h-4 w-4 rotate-90 ml-4" />}
                        </div>
                        {item?.isCheck
                        && item?.childs
                        && item?.childs?.map((child) => {
                          return (
                            <Checkbox
                              checked={child?.isCheck}
                              onChange={() =>
                                handleCheckboxChange(item?.childs, child.key, child.id, index)}
                              className={`ml-10 cm-checkbox h-10 ${index == 0 ? 'opacity-65' : ''}`}
                            >
                              {child?.title}
                            </Checkbox>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
                {errorString.length > 0 && (
                  <ul className="mt-5 list-disc pl-4">
                    {errorString.map((i, index) => (
                      <li key={index} className="text-textDanger">
                        {i}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
        {!isMemberTab && !isEditEnable && (
          <div className="flex flex-wrap gap-4 justify-end items-center py-4">
            <div className="flex gap-4 flex-wrap">
              <BorderButton
                children={t('cancel')}
                className="!px-2 sm:!px-5 flex-1"
                textColor="text-textPrimary"
                onClick={() => {
                  navigate(-1)
                }}
              />
              <CustomButton
                children={id ? t('edit') : t('save')}
                className="flex-auto"
                backgroundColor="bg-secondary"
                textColor="text-textWhite"
                onClick={() => {
                  if (id)
                    onEditPress()
                  else onSavePress()
                }}
              />
            </div>
          </div>
        )}
        {!isMemberTab && isEditEnable && (
          <div className="flex flex-wrap gap-4 justify-between items-center py-4">
            <CustomButton
              children={t('archive')}
              className="hidden sm:block"
              backgroundColor="bg-bthDisable"
              textColor="text-textPrimary"
              onClick={() => {
                setIsDeleteGroupAlert(true)
              }}
            />
            <div className="flex gap-4 flex-wrap">
              <CustomButton
                children={t('archive')}
                className="!px-4 sm:!px-5 block sm:hidden flex-1"
                backgroundColor="bg-bthDisable"
                textColor="text-textPrimary"
                onClick={() => {
                  setIsDeleteGroupAlert(true)
                }}
              />
              <BorderButton
                children={t('cancel')}
                className="!px-2 sm:!px-5 flex-1"
                textColor="text-textPrimary"
                onClick={() => {
                  setIsEditEnable(false)
                }}
              />
              <CustomButton
                children={t('save')}
                className="flex-auto"
                backgroundColor="bg-secondary"
                textColor="text-textWhite"
                onClick={onSavePress}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default GroupCreate
