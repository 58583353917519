import { APIConst } from '@inphiz/api'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import { QueryBuilder } from '@inphiz/core-utilities'
import { useQuery } from '@tanstack/react-query'

interface Response {
  message?: string
  isPermitEligible?: boolean
}

interface Params {
  userHandle?: string
}

export function useCheckPermitEligibility({ userHandle }: Params) {
  return useQuery<Response>({
    queryKey: [QueryKey.getPermitEligibility, userHandle],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.permits.eligibility).add('UserHandle', userHandle)
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
    enabled: false,
  })
}
