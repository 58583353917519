import { QueryKey } from '@inphiz/api/src/QueryKey'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { queryClient } from '../../App'
import {
  BorderButton,
  CustomMenu,
  CustomMultiSelectMenu,
  CustomPagination,
  CustomTable,
  CustomText,
  Spinner,
  TextWithIcon,
} from '../../component'
import { CloseIcon, RightIcon } from '../../icons'
import {
  useEmployeeHistory,
  useEmployeeItem,
  useManagerFilterList,
  useOrganizationUnits,
} from '../../packages/api-web'

export interface filterItem {
  id: number
  header: string
  data: any[]
  selectedData: any
  isSearch?: boolean
}

function EmployeeHistory() {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { state } = useLocation()
  const [itemPerPage, setItemPage] = useState(20)
  const [skipItem, setSkipItem] = useState(0)
  const [managerSearch, setManagerSearch] = useState('')
  const [selectedEmployee, setSelectedEmployee] = useState('')
  const [filterData, setFilterData] = useState<filterItem[]>([
    {
      id: 1,
      header: 'User',
      data: [],
      selectedData: state?.userHandle ? state?.userHandle : null,
      isSearch: true,
    },
    {
      id: 2,
      header: 'Action type',
      data: [
        { handle: 'user-updated', title: 'Updated' },
        { handle: 'user-recovered', title: 'Recovered' },
        { handle: 'user-archived', title: 'Archived' },
        { handle: 'user-created', title: 'Created' },
      ],
      selectedData: null,
    },
    {
      id: 3,
      header: 'Organization',
      data: [],
      selectedData: [],
    },
  ])
  const {
    isLoading,
    data: historyData,
    refetch,
  } = useEmployeeHistory({
    itemPerPage,
    skip: skipItem,
    filterData,
  })
  const {
    data: managerList,
    isFetching: isManagerList,
    refetch: refetchManagerList,
  } = useManagerFilterList({ filterTxt: managerSearch })
  const { data: organizationList } = useOrganizationUnits()
  const {
    isLoading: isEmployeeItemLoad,
    data: employeeItem,
    refetch: refetchEmployee,
  } = useEmployeeItem({
    handle: selectedEmployee,
    isShowDeleted: true,
  })

  const tableHeaderList = useMemo(
    () => [
      { title: t('tableUser'), id: 1 },
      { title: t('action'), id: 2 },
      { title: t('authorType'), id: 3 },
      { title: t('authorName'), id: 4 },
      { title: t('date'), id: 5 },
    ],
    [t],
  )

  const actionType = (key: string) => {
    switch (key) {
      case 'user-updated':
        return 'Updated'
      case 'user-recovered':
        return 'Recovered'
      case 'user-archived':
        return 'Archived'
      case 'user-created':
        return 'Created'
      default:
        return '-'
    }
  }

  const data = useMemo(() => {
    const updatedItems = historyData?.items?.map(item => ({
      ...item,
      actionType: actionType(`${item?.action}`),
    }))
    const result = {
      ...historyData,
      items: updatedItems,
    }
    return result
  }, [historyData])

  useMemo(() => {
    const newFilter: any = [...filterData]
    newFilter[0].data = managerList?.items?.map(i => ({ ...i, title: i.displayName }))
    newFilter[2].data = organizationList?.items
    setFilterData(newFilter)
  }, [managerList, organizationList])

  useEffect(() => {
    // Debounce logic
    const debounce = setTimeout(() => {
      if (managerSearch.length >= 3) {
        refetchManagerList()
      }
      else if (managerSearch.length == 0) {
        refetchManagerList()
      }
    }, 2000)

    return () => clearTimeout(debounce)
  }, [managerSearch])

  useEffect(() => {
    refetch()
  }, [skipItem, itemPerPage])

  useEffect(() => {
    if (selectedEmployee && !employeeItem) {
      refetchEmployee()
    }
    if (employeeItem && selectedEmployee) {
      navigate(`/employee/${employeeItem?.user?.handle}`, {
        state: { isShowDeleted: employeeItem?.user?.isDeleted },
      })
      queryClient.removeQueries({
        queryKey: [QueryKey.getEmployeeItem, employeeItem?.user?.handle],
      })
    }
  }, [employeeItem, selectedEmployee])

  if (isLoading)
    return <Spinner />

  return (
    <div className="p-5 h-full flex flex-col">
      <CustomText variant="xlPoppinsBold">{t('employeeHistory')}</CustomText>
      <div className="mt-6 px-5 py-3 bg-white rounded-lg flex items-center justify-between border border-stroke">
        <div className="flex items-center gap-3 flex-wrap">
          {filterData.map((item, index) => {
            if (index == 2) {
              return (
                <div className="flex items-center gap-3" key={index}>
                  <CustomMultiSelectMenu
                    key={item.id}
                    selectedValue={item?.selectedData ?? []}
                    menuList={item?.data ?? []}
                    onSelectedValue={(value) => {
                      const newFilter: any[] = [...filterData]
                      newFilter[index] = { ...item, selectedData: value }
                      setFilterData(newFilter)
                    }}
                    onFilterClear={() => {
                      const updateItem = { ...item, selectedData: [] }
                      const newFilter: any = [...filterData]
                      newFilter[index] = updateItem
                      setFilterData(newFilter)
                    }}
                    buttonComponent={(
                      <div>
                        <TextWithIcon
                          text={item?.header}
                          rightIcon={<RightIcon className="h-4 w-4 rotate-90 ml-4" />}
                          textVariant="smPoppinsRegular"
                        />
                        {item?.selectedData && item?.selectedData?.length > 0 && (
                          <div className="absolute -top-4 right-0 bg-primary h-4 w-4 rounded-full justify-center items-center flex">
                            <CustomText variant="xsmPoppinsRegular" textColor="text-textWhite">
                              {item?.selectedData.length.toString()}
                            </CustomText>
                          </div>
                        )}
                      </div>
                    )}
                  />
                  <div className="h-8 bg-black/10 w-[1px]" />
                </div>
              )
            }
            return (
              <div className="flex items-center gap-3" key={index}>
                <CustomMenu
                  isSearchLoader={isManagerList}
                  menuList={item?.data ?? []}
                  selectedValue={item?.selectedData}
                  onClick={(value) => {
                    const updateItem = { ...item, selectedData: value?.handle }
                    const newFilter: any = [...filterData]
                    newFilter[index] = updateItem
                    setFilterData(newFilter)
                  }}
                  buttonComponent={(
                    <div className="flex">
                      <TextWithIcon
                        text={t(item?.header)}
                        rightIcon={<RightIcon className="h-4 w-4 rotate-90 ml-4" />}
                        textVariant="smPoppinsRegular"
                      />
                      {item?.selectedData && (
                        <>
                          <div className="absolute -top-4 right-1 bg-primary h-4 w-4 rounded-full justify-center items-center flex">
                            <CustomText variant="xsmPoppinsRegular" textColor="text-textWhite">
                              1
                            </CustomText>
                          </div>
                          <button
                            onClick={() => {
                              const updateItem = { ...item, selectedData: null }
                              const newFilter: any = [...filterData]
                              newFilter[index] = updateItem
                              setFilterData(newFilter)
                            }}
                          >
                            <CloseIcon className="h-5" />
                          </button>
                        </>
                      )}
                    </div>
                  )}
                  {...(item?.isSearch && {
                    onSearch: (e) => {
                      setManagerSearch(e)
                    },
                  })}
                />
                <div className="h-8 bg-black/10 w-[1px]" />
              </div>
            )
          })}
        </div>
        <BorderButton
          children={t('filter')}
          className="px-7 self-end"
          onClick={() => {
            refetch()
          }}
        />
      </div>
      <div className="mt-6 py-6 px-5 bg-white rounded-lg border border-stroke flex flex-col flex-1 overflow-hidden">
        <CustomText className="mb-4 mr-auto self-center" wrapText={false} variant="xlPoppinsBold">
          {`${t('total')}: ${data?.totalCount ?? 0}`}
        </CustomText>
        <div className="flex flex-col overflow-hidden rounded-lg border border-stroke">
          <div className="flex-1 overflow-x-auto overflow-y-auto scrollbar bg-white">
            <CustomTable
              tableHeaderList={tableHeaderList}
              tableDataList={data?.items}
              displayKeys={['userName', 'actionType', 'authorType', 'authorName', 'createdTime']}
              onClick={(item) => {
                setSelectedEmployee(item.userHandle)
              }}
            />
          </div>
          {data && data?.items && data?.items?.length > 0 && (
            <CustomPagination
              totalItem={data?.totalCount}
              itemPerPage={itemPerPage}
              onChange={setItemPage}
              onPagePress={index => setSkipItem((index - 1) * itemPerPage)}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default EmployeeHistory
