import type { History } from '../../../database'
import FlatList from 'flatlist-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getStoreData, Stores } from '../../../database'
import { CustomText } from '../../atoms'
import { HistoryItem } from '../../organisms'

interface HistoryListProps {
  onPress?: (item: any) => void
}
export interface HistoryListItem {
  title: string
  data: {
    id: number
    question: string
    createdDate: string
    chats: string
  }
}

export function HistoryList({ onPress }: HistoryListProps) {
  const { t } = useTranslation('common')
  const [historyList, setHistoryList] = useState<HistoryListItem[]>([])

  useEffect(() => {
    window.addEventListener('updateHistory', () => {
      getHistory()
    })
    getHistory()
  }, [])

  const getHistory = async () => {
    const localHistoryData = await getStoreData<History>(Stores.History)
    const data = convertTheData(localHistoryData)
    setHistoryList(data)
  }

  const dateTitle = (date: Date) => {
    if (isToday(date)) {
      return t('today').toUpperCase()
    }
    else if (isYesterday(date)) {
      return t('yesterday').toUpperCase()
    }
    else if (isThreeDaysAgo(date)) {
      return `3 ${t('daysAgo').toUpperCase()}`
    }
    else if (isTwoWeeksAgo(date)) {
      return `2 ${t('weeksAgo').toUpperCase()}`
    }
    else if (isOneMonthAgo(date)) {
      return `1 ${t('monthAgo').toUpperCase()}`
    }
  }

  const convertTheData = (convertData: any) => {
    const data = convertData.map((item: any) => {
      const data = {
        title: dateTitle(new Date(Number.parseInt(item.createdDate))),
        data: item,
      }
      return data
    })
    return data
  }

  const isToday = (date: Date) => {
    const today = new Date()
    return date.toDateString() === today.toDateString()
  }

  const isYesterday = (date: Date) => {
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    return date.toDateString() === yesterday.toDateString()
  }

  const isThreeDaysAgo = (date: Date) => {
    const threeDaysAgo = new Date()
    threeDaysAgo.setDate(threeDaysAgo.getDate() - 2)
    return date.toDateString() === threeDaysAgo.toDateString()
  }

  const isTwoWeeksAgo = (date: Date) => {
    const today = new Date()
    const twoWeeksAgo = new Date()
    const adjustedToday = new Date()
    adjustedToday.setDate(today.getDate() - 2)
    twoWeeksAgo.setDate(today.getDate() - 17)
    return date >= twoWeeksAgo && date <= adjustedToday
  }

  const isOneMonthAgo = (date: Date) => {
    const today = new Date()
    const twoWeeksAgo = new Date(today)
    twoWeeksAgo.setDate(today.getDate() - 17)
    return date < twoWeeksAgo
  }

  const renderItem = (item: HistoryListItem, idx: string) => {
    return (
      <li
        onClick={() => {
          onPress?.(item)
        }}
        key={idx}
        className="flex w-full cursor-pointer"
      >
        <HistoryItem title={item.data.question} />
      </li>
    )
  }

  const groupSeparator = (group: any, idx: number, groupLabel: string) => {
    return (
      <div className="bg-bgColor h-8 items-center flex">
        <CustomText variant="smPoppinsRegular" className="px-5">
          {groupLabel}
        </CustomText>
      </div>
    )
  }

  return (
    <FlatList
      list={historyList}
      renderItem={renderItem}
      groupBy="title"
      groupSeparator={groupSeparator}
      reversed
      renderWhenEmpty={() => {
        return (
          <div className="mt-5 h-10 items-center justify-center flex">
            <CustomText variant="mdPoppinsRegular">{t('noDataFound')}</CustomText>
          </div>
        )
      }}
    />
  )
}
