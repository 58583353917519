import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ControlCenterEcodriving,
  ControlCenterHourCost,
  ControlCenterProdDeviation,
  ControlCenterVehicleCost,
  CustomMenu,
  CustomMultiSelectMenu,
  CustomText,
  TextWithIcon,
} from '../../component'
import { globalDateFormat } from '../../helpers'
import { CloseIcon, RightIcon } from '../../icons'

export interface filterItem {
  id: number
  header: string
  data: any[]
  selectedData: any
}

function ControlCenterOverview() {
  const { t } = useTranslation('common')
  const [filterData, setFilterData] = useState<filterItem[]>([
    {
      id: 1,
      header: 'All',
      data: [],
      selectedData: null,
    },
    {
      id: 2,
      header: 'All',
      data: [],
      selectedData: null,
    },
    {
      id: 3,
      header: 'All',
      data: [],
      selectedData: null,
    },
    {
      id: 4,
      header: '',
      data: [],
      selectedData: [],
    },
  ])

  const disableFutureDates = (date: Date): boolean => {
    const today = new Date()
    // Disable all dates greater than today
    return date.getTime() > today.getTime()
  }

  return (
    <div className="p-5 h-full flex flex-col">
      <CustomText variant="xlPoppinsBold">{t('dailyOverview')}</CustomText>
      <div className="overflow-y-auto scrollbar flex flex-col mt-6">
        <div className="px-5 py-3 bg-white border-stroke border rounded-lg flex items-center justify-between">
          <div className="flex items-center gap-3 flex-wrap">
            {filterData.map((item, index) => {
              if (item?.id == 4) {
                return (
                  <CustomMultiSelectMenu
                    shouldDisableDate={disableFutureDates}
                    selectedValue={item.selectedData}
                    menuList={item.data}
                    calender={true}
                    onSelectedValue={(value) => {
                      const updateItem = { ...item, selectedData: value }
                      const newFilter: filterItem[] = [...filterData]
                      newFilter[index] = updateItem
                      setFilterData([...newFilter])
                    }}
                    onFilterClear={() => {
                      const updateItem = { ...item, selectedData: [] }
                      const newFilter: filterItem[] = [...filterData]
                      newFilter[index] = updateItem
                      setFilterData([...newFilter])
                    }}
                    buttonComponent={(
                      <div className="flex items-center">
                        <TextWithIcon
                          text={`${globalDateFormat(new Date().toDateString())} - ${globalDateFormat(new Date().toDateString())}`}
                          rightIcon={<RightIcon className="h-4 w-4 rotate-90 ml-3" />}
                          textVariant="smPoppinsRegular"
                        />
                        {item.selectedData.length > 0 && (
                          <div className="absolute -top-4 right-0 bg-primary h-4 w-4 rounded-full flex justify-center items-center">
                            <CustomText variant="xsmPoppinsRegular" textColor="text-textWhite">
                              1
                            </CustomText>
                          </div>
                        )}
                      </div>
                    )}
                  />
                )
              }
              return (
                <div className="flex items-center gap-3" key={index}>
                  <CustomMenu
                    menuList={item?.data ?? []}
                    selectedValue={item?.selectedData ?? null}
                    onClick={(value) => {
                      const updateItem = { ...item, selectedData: value?.handle }
                      const newFilter: any = [...filterData]
                      newFilter[index] = updateItem
                      setFilterData(newFilter)
                    }}
                    buttonComponent={(
                      <div className="flex">
                        <TextWithIcon
                          text={t(item?.header)}
                          rightIcon={<RightIcon className="h-4 w-4 rotate-90 ml-4" />}
                          textVariant="smPoppinsRegular"
                        />
                        {item?.selectedData && (
                          <>
                            <div className="absolute -top-4 right-1 bg-primary h-4 w-4 rounded-full justify-center items-center flex">
                              <CustomText variant="xsmPoppinsRegular" textColor="text-textWhite">
                                1
                              </CustomText>
                            </div>
                            <button
                              onClick={() => {
                                const updateItem = { ...item, selectedData: null }
                                const newFilter: any = [...filterData]
                                newFilter[index] = updateItem
                                setFilterData(newFilter)
                              }}
                            >
                              <CloseIcon className="h-5" />
                            </button>
                          </>
                        )}
                      </div>
                    )}
                  />
                  <div className="h-8 bg-black/10 w-[1px]" />
                </div>
              )
            })}
          </div>
        </div>
        <ControlCenterProdDeviation />
        <ControlCenterEcodriving />
        <ControlCenterVehicleCost />
        <ControlCenterHourCost />
      </div>
    </div>
  )
}

export default ControlCenterOverview
