import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import { MinimizedBaseItemWithTitleDto } from '@inphiz-shared/shared-models/src/F_Selfleadership/minimized-base-item-with-title-dto'
import { APIConst } from '@inphiz/api'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import { QueryBuilder } from '@inphiz/core-utilities'
import { useQuery } from '@tanstack/react-query'

const DepartmentItemDto = new MinimizedBaseItemWithTitleDto()
export type DepartmentItem = typeof DepartmentItemDto

const DepartmentDto = new DataCollectionApiResponse<DepartmentItem>()
export type DepartmentData = typeof DepartmentDto

export function useDepartmentList() {
  return useQuery<DepartmentData>({
    queryKey: [QueryKey.departmentList],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.employeesPerformance.getDepartments).add(
        'take',
        2147483647,
      )
      const result = await APIFunctions.GET(qb.toString())
      return {
        ...result,
        items: result.items.map((i: { title: string, handle: string }) => {
          return { ...i, title: i.title ? i.title : 'Without department' }
        }),
      }
    },
  })
}
