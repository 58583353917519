import { CertificateItemDto } from '@inphiz-shared/shared-models/src/F_Certificates/certificate-item-dto'
import { useQuery } from '@tanstack/react-query'
import APIFunctions from '../../Networking/APIFunctions'
import { APIConst } from '../../Networking/APIServices'
import { QueryKey } from '../../QueryKey'

const CertificateDetailsPropsType = new CertificateItemDto()
type CertificateDetailsProps = typeof CertificateDetailsPropsType

export function getCertificateDetails(certificateHandle: string | null) {
  const fetchCertificateDetails = async () => {
    if (certificateHandle) {
      const apiResponse = await APIFunctions.GET(
        `${APIConst.employeesPerformance.certificateDetails}?handle=${certificateHandle}`,
      )
      if (apiResponse.isSuccess)
        return apiResponse
    }
    else {
      return { isSuccess: true, data: { item: null } }
    }
  }
  const results = useQuery({
    queryKey: [QueryKey.certificateDetails],
    queryFn: () => fetchCertificateDetails(),
    // enabled: false,
  })
  const response = results.isFetching ? null : results.data
  if (response && response?.isSuccess) {
    return { ...results, data: response?.item as CertificateDetailsProps }
  }
  else {
    return {
      isFetching: results.isFetching,
      isLoading: results.isLoading,
      refetch: results.refetch,
      data: null,
    }
  }
}
