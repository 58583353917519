import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  BorderButton,
  CustomMenu,
  CustomPagination,
  CustomTable,
  CustomText,
  Spinner,
  TextWithIcon,
} from '../../component'
import { CloseIcon, RightIcon, SearchIcon } from '../../icons'
import {
  useEquipmentCategory,
  useEquipmentList,
  useEquipmentUsers,
} from '../../packages/api-web/Equipment'

export interface filterItem {
  id?: number
  header?: string
  data?: any[]
  selectedData?: null
  isSearch?: boolean
}

function EquipmentList() {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const [itemPerPage, setItemPage] = useState(20)
  const [skipItem, setSkipItem] = useState(0)
  const [searchTxt, setSearchTxt] = useState('')
  const [searchEmployee, setSearchEmployee] = useState('')
  const [filterData, setFilterData] = useState<filterItem[]>([
    {
      id: 1,
      header: t('category'),
      data: [],
      selectedData: null,
      isSearch: false,
    },
    {
      id: 2,
      header: t('assignedUsers'),
      data: [],
      selectedData: null,
      isSearch: true,
    },
  ])
  const { isFetching, data, refetch } = useEquipmentList({
    itemPerPage,
    skip: skipItem,
    searchTxt,
    filterData,
  })
  const {
    data: employeeList,
    isFetching: isEmployeeLoad,
    refetch: refetchManagerList,
  } = useEquipmentUsers({ filterTxt: searchEmployee })
  const { data: categoryList, isFetching: isCategoryFetching } = useEquipmentCategory()

  const tableHeaderList = useMemo(
    () => [
      { title: t('tableTitle'), id: 1, key: 'title' },
      { title: t('mainCategory'), id: 2, key: 'equipmentCategory.parentEquipmentCategory.title' },
      { title: t('subCategory'), id: 3, key: 'equipmentCategory.title' },
      { title: t('costSEK'), id: 4, key: 'price' },
      { title: t('signatureMandatory'), id: 5, key: 'requiresSign' },
      { title: t('visible'), id: 6, key: 'isVisible' },
    ],
    [t],
  )

  useEffect(() => {
    if (categoryList || employeeList) {
      const newFilter: filterItem[] = [...filterData]
      newFilter[0].data = categoryList?.items ?? []
      newFilter[1].data = employeeList?.items ?? []
      setFilterData(newFilter)
    }
  }, [categoryList, employeeList])

  useEffect(() => {
    refetch()
  }, [skipItem, itemPerPage])

  useEffect(() => {
    // Debounce logic
    const debounce = setTimeout(() => {
      if (searchEmployee.length >= 3) {
        refetchManagerList()
      }
    }, 2000)

    return () => clearTimeout(debounce)
  }, [searchEmployee])

  const handleInputKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      refetch()
    }
  }

  if (isFetching || isCategoryFetching) {
    return <Spinner />
  }

  return (
    <div className="p-5 h-full flex flex-col">
      <div className="flex items-center justify-between">
        <CustomText variant="xlPoppinsBold">{t('equipment')}</CustomText>
        <BorderButton
          children={`+ ${t('createNew')}`}
          className="px-7 self-end"
          onClick={() => {
            navigate('/equipment/create')
          }}
        />
      </div>
      <div className="mt-6 px-5 py-3 bg-white rounded-lg flex items-center justify-between border border-stroke">
        <div className="flex items-center gap-3 flex-wrap">
          {filterData.map((item, index) => {
            return (
              <div className="flex items-center gap-3" key={index}>
                <CustomMenu
                  isSearchLoader={isEmployeeLoad}
                  menuList={item?.data ?? []}
                  selectedValue={item?.selectedData ?? null}
                  onClick={(value) => {
                    const updateItem = { ...item, selectedData: value?.handle }
                    const newFilter: any = [...filterData]
                    newFilter[index] = updateItem
                    setFilterData(newFilter)
                  }}
                  buttonComponent={(
                    <div className="flex">
                      <TextWithIcon
                        text={item?.header}
                        rightIcon={<RightIcon className="h-4 w-4 rotate-90 ml-4" />}
                        textVariant="smPoppinsRegular"
                      />
                      {item?.selectedData && (
                        <>
                          <div className="absolute -top-4 right-1 bg-primary h-4 w-4 rounded-full justify-center items-center flex">
                            <CustomText variant="xsmPoppinsRegular" textColor="text-textWhite">
                              1
                            </CustomText>
                          </div>
                          <button
                            onClick={() => {
                              const updateItem = { ...item, selectedData: null }
                              const newFilter: any = [...filterData]
                              newFilter[index] = updateItem
                              setFilterData(newFilter)
                            }}
                          >
                            <CloseIcon className="h-5" />
                          </button>
                        </>
                      )}
                    </div>
                  )}
                  {...(item?.isSearch && {
                    onSearch: (e) => {
                      if (e?.length > 3) {
                        setSearchEmployee(e)
                      }
                    },
                  })}
                />
                <div className="h-8 bg-black/10 w-[1px]" />
              </div>
            )
          })}
        </div>
        <BorderButton
          children={t('filter')}
          className="px-7 self-end"
          onClick={() => {
            refetch()
          }}
        />
      </div>
      <div className="mt-6 py-6 px-5 bg-white rounded-lg border border-stroke flex flex-col overflow-hidden">
        <div className="flex flex-col sm:flex-row mb-4 gap-4">
          <CustomText className="mr-auto self-center" wrapText={false} variant="xlPoppinsBold">
            {`${t('total')}: ${data?.totalCount ?? 0}`}
          </CustomText>
          <div className="flex flex-1 items-center justify-between">
            <div className="flex items-center w-auto gap-4">
              <SearchIcon />
              <input
                type="text"
                value={searchTxt}
                placeholder={t('searchPlaceholder')}
                className="outline-0 flex-1 min-w-[100px]"
                onChange={e => setSearchTxt(e.target.value)}
                onKeyDown={handleInputKeyDown}
              />
              {searchTxt && (
                <button
                  onClick={() => {
                    setSearchTxt('')
                    setTimeout(() => {
                      refetch()
                    }, 200)
                  }}
                >
                  <CloseIcon className="h-5" />
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col overflow-hidden rounded-lg border border-stroke">
          <div className="flex-1 overflow-x-auto overflow-y-auto scrollbar bg-white">
            <CustomTable
              tableHeaderList={tableHeaderList}
              tableDataList={data?.items}
              displayKeys={[
                'title',
                'equipmentCategory.parentEquipmentCategory.title',
                'equipmentCategory.title',
                'price',
                'requiresSign',
                'isVisible',
              ]}
              onClick={(item) => {
                navigate(`/equipment/${item.handle}`)
              }}
            />
          </div>
          {data && data?.items && data?.items?.length > 0 && (
            <CustomPagination
              totalItem={data?.totalCount}
              itemPerPage={itemPerPage}
              onChange={setItemPage}
              onPagePress={index => setSkipItem((index - 1) * itemPerPage)}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default EquipmentList
