import type { ReactNode } from 'react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Loader } from 'rsuite'
import { CloseIcon, SearchIcon } from '../../../icons'
import { CustomText } from '../CustomText'

interface CustomMenuProps {
  buttonComponent: ReactNode
  menuList?: any[]
  onClick: (item: any) => void
  className?: string
  selectedValue?: null
  onSearch?: (e: string) => void
  isSearchLoader?: boolean
}

export function CustomMenu({
  buttonComponent,
  menuList,
  className = '',
  selectedValue,
  onClick,
  onSearch,
  isSearchLoader,
}: CustomMenuProps) {
  const { t } = useTranslation('common')
  const [showMore, setShowMore] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [searchTxt, setSearchTxt] = useState('')

  const toggleMenu = () => {
    setShowMore(!showMore)
  }

  const handleClickOutside = (event: any) => {
    if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      setShowMore(false)
    }
  }

  useEffect(() => {
    if (showMore) {
      document.addEventListener('click', handleClickOutside)
    }
    else {
      document.removeEventListener('click', handleClickOutside)
    }
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [showMore])

  return (
    <div className="relative items-center flex">
      <button ref={buttonRef} onClick={toggleMenu}>
        {buttonComponent}
      </button>
      {showMore && (
        <div
          className={`flex flex-col items-start bg-white absolute top-[44px] z-10 rounded-md pl-2 min-w-fit shadow-md shadow-gray-900/10 ${className}`}
        >
          {onSearch && (
            <div className="flex my-2" onClick={e => e.stopPropagation()}>
              <SearchIcon />
              <input
                type="text"
                value={searchTxt}
                placeholder={t('searchPlaceholder')}
                className="outline-0 flex-1 w-[120px] ml-2"
                onChange={(e) => {
                  setSearchTxt(e.target.value)
                  onSearch(e.target.value)
                }}
              />
              {isSearchLoader && <Loader />}
              {searchTxt && (
                <button
                  onClick={() => {
                    setSearchTxt('')
                    onSearch('')
                  }}
                >
                  <CloseIcon className="h-5" />
                </button>
              )}
            </div>
          )}
          <div className="max-h-80 overflow-y-auto scrollbar">
            {menuList
            && menuList.map((i, index) => (
              <div
                className="cursor-pointer flex py-2 items-center mr-5"
                key={index}
                role="menuitem"
                onClick={() => {
                  setShowMore(false)
                  onClick(i)
                }}
              >
                <CustomText
                  wrapText={false}
                  variant={
                    selectedValue && i?.handle == selectedValue
                      ? 'smPoppinsBold'
                      : 'smPoppinsRegular'
                  }
                >
                  {i?.title}
                </CustomText>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
