import type { Axios, AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { Headers } from '@inphiz-shared/shared-models/src/NetworkConstants/headers'
import { queryClient } from '@inphiz/api/src'
import { appConfig } from '@inphiz/app-config/src/AppConfig'
import AppInfo from '@inphiz/app-core-shared/src/AppInfo'
import { AuthenticationSharedClient } from '@inphiz/auth/src/AuthFactory/AuthenticationSharedClient'
import { Logger } from '@inphiz/logger'
import axios from 'axios'
import i18n from 'i18next'
import uuid from 'react-native-uuid'
import { APIConst } from './APIServices'
import { logErrorPreRequest, logErrorResponse, logResponse } from './HttpLogging'

import { ApiSharedAuthProviderInstance } from './IApiSharedAuthProvider'

// main axios instance for making api calls from the application
const httpClient = axios.create({
  baseURL: APIConst.baseURL,
  headers: {
    // default headers can be set here
  },
})

applyLoggingAndErrorHandlingInterceptors(httpClient)

if (appConfig.properties.AlwaysUseAuthorizationApiKey) {
  // setup axios interceptor for using authorization api key only
  httpClient.interceptors.request.use(async (config) => {
    config.headers.Authorization = `ApiKey ${APIConst.ApiKey}`
    applyHeadersToTheRequest(config)
    return config
  })
}
else {
  // setup axios interceptor for adding authorization header
  httpClient.interceptors.request.use(async (config) => {
    // auth client can be null.
    // TBD we need to check the current state of the application and get the token from the authClient

    let authToken: string | null = null

    const authClient
      = AuthenticationSharedClient.instance ?? ApiSharedAuthProviderInstance.getInstance()
    if (!authClient) {
      Logger.Log.warn('AuthClient instance is null')
    }
    else {
      authToken = await authClient.getAuthToken()
      if (authToken === null) {
        Logger.Log.warn('Auth token is null. Expected to be a valid token')
      }
    }

    if (authToken !== null) {
      config.headers.Authorization = `Bearer ${authToken}`
    }
    else {
      config.headers.Authorization = `ApiKey ${APIConst.ApiKey}`
    }

    applyHeadersToTheRequest(config)

    return config
  })
}

let isSingleAlert = false
httpClient.interceptors.response.use(
  async (response: AxiosResponse) => {
    // axious won't send here anything except 2XX status code
    // so this code won't be executed
    if (response?.status === 500) {
      await SessionExpired()
      return response
    }

    if (response && response?.data) {
      if (response.data.isSuccess != undefined && response.data.isSuccess != true) {
        const message = response?.data?.message
        if (!isSingleAlert) {
          await ApiSharedAuthProviderInstance.getInstance().sessionExpiredAlert(
            i18n.t('warning'),
            message || i18n.t('somethingWentWrong'),
          )
          isSingleAlert = true
          resetAlert()
        }
      }
    }
    return response
  },
  async (error: AxiosError) => {
    if (error?.status === 409 || error?.status === 422) {
      return Promise.reject(error)
    }
    else {
      await SessionExpired()
      if (!isSingleAlert) {
        const message = ((error.response?.data ?? {}) as any).message
        await ApiSharedAuthProviderInstance.getInstance().sessionExpiredAlert(
          i18n.t('warning'),
          message || i18n.t('somethingWentWrong'),
        )
        isSingleAlert = true
        resetAlert()
      }
      return Promise.reject(error)
    }
  },
)

function applyLoggingAndErrorHandlingInterceptors(instance: Axios) {
  instance.interceptors.request.use(
    (config) => {
      // logRequest(config)
      // const { method, url, data, headers } = config
      // config.metadata = {
      //   startTime: new Date().getTime(),
      //   requestInfo: {
      //     method,
      //     url,
      //     data,
      //     headers,
      //   },
      // }
      return config
    },
    (error: AxiosError) => {
      // If request fails before it was sent (e.g., bad config)
      // you can still log/report it here if needed.
      logErrorPreRequest(error)
      return Promise.reject(error)
    },
  )

  instance.interceptors.response.use(logResponse, (error: AxiosError) => {
    // if (Platform.OS === 'ios' || Platform.OS === 'android') {
    //   logErrorResponse(error)
    // }
    // else {
    logErrorResponse(error)
    // }

    return Promise.reject(error)
  })
}

let defaultHeadersHasBeenSet = false

function applyHeadersToTheRequest(config: AxiosRequestConfig<any>) {
  if (!config.headers) {
    config.headers = {}
  }

  // perform addinge these headers only once, once the app is initialized
  if (!defaultHeadersHasBeenSet) {
    if (AppInfo.getInstance().getIfInitialized()) {
      // inject default, static headers

      const apinfo = AppInfo.getInstance()
      httpClient.defaults.headers.common[Headers.APP_VERSION] = apinfo.getAppVersion()
      httpClient.defaults.headers.common[Headers.APP_BUILD] = apinfo.getAppBuild()
      httpClient.defaults.headers.common[Headers.VERSION_INFO] = apinfo.getAppVersionInfo()
      httpClient.defaults.headers.common[Headers.OS_TYPE] = apinfo.getOsType()
      httpClient.defaults.headers.common[Headers.OS_VERSION] = apinfo.getOsVersion()
      httpClient.defaults.headers.common[Headers.APP_BUNDLEID] = apinfo.getBundleId()
      defaultHeadersHasBeenSet = true
    }
  }

  //   // Set language
  // var language = _currentCultureService.GetCurrentCultureInfo()?.TwoLetterISOLanguageName.ToLower();
  // request.Headers.Add(NetworkConstants.Headers.Language, language);
  // //

  // if (FakeUserHeadersHelper.UseFakeUserHandle)
  // {
  //     request.Headers.Add(FakeUserHeadersHelper.FakeUserHandleHeader, FakeUserHeadersHelper.FakeUserHandle);
  // }

  // config.headers[Headers.JSON_NO_DEFAULTS] = '' // to disable default values in the backend json serialization
  config.headers[Headers.CORRELATION_ID] = uuid.v4().toString() // Guid.NewGuid().ToString()
  config.headers[Headers.language] = i18n.language
}

async function SessionExpired(): Promise<void> {
  if (await ApiSharedAuthProviderInstance.getInstance().checkSessionExpired()) {
    queryClient.removeQueries()
    queryClient.clear()
    await ApiSharedAuthProviderInstance.getInstance().resetSession()
    isSingleAlert = true
    resetAlert()
  }

  // const session = await EncryptedStorage.getItem(EncryptedStorageKey.userSession)
  // if (session !== undefined && session !== null) {
  //   const user = JSON.parse(session)
  //   if (new Date().getTime() > new Date(user.accessTokenExpirationDate).getTime()) {
  //     await EncryptedStorage.removeItem(EncryptedStorageKey.userSession)
  //     queryClient.removeQueries()
  //     // reset('Root')
  //     isSingleAlert = true
  //     resetAlert()
  //   }
  // }
}

function resetAlert() {
  setTimeout(() => {
    isSingleAlert = false
  }, 5000)
}

export async function GET(url: string, config = {}) {
  const result = await httpClient
    .get(url, config)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error
    })
  return result
}

export async function POST(url: string, params: any) {
  const result = await httpClient
    .post(url, params || {})
    .then((response) => {
      return { ...response?.data, status: response?.status }
    })
    .catch((error) => {
      return error
    })
  return result
}

export async function FORM_POST_T<T>(url: string, params: any): Promise<T> {
  const headers = {
    'Content-Type': 'multipart/form-data',
  }
  const result = await httpClient
    .post<T>(url, params || {}, { headers })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error
    })
  return result
}

export async function FORM_POST(url: string, params: any) {
  const headers = {
    'Content-Type': 'multipart/form-data',
  }
  const result = await httpClient
    .post(url, params || {}, { headers })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error
    })
  return result
}

export async function DELETE(url: string, params: any) {
  const result = await httpClient
    .delete(url, { data: params })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error
    })
  return result
}

export async function PUT(url: string, params: any) {
  const result = await httpClient
    .put(url, params || {})
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return error
    })
  return result
}

export async function DownloadFile(url: string) {
  try {
    const response = await axios.get(url, {
      responseType: 'blob',
    })

    const blobUrl = window.URL.createObjectURL(new Blob([response.data]))

    const link = document.createElement('a')
    link.href = blobUrl
    link.setAttribute('download', 'permit-file.pdf')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    window.URL.revokeObjectURL(blobUrl)
  }
  catch (error) {
    console.error('Error downloading file:', error)
  }
}

export default { GET, POST, FORM_POST, FORM_POST_T, DELETE, PUT, DownloadFile }
