import type { ReactNode } from 'react'
import type { ValueType } from 'rsuite/esm/Checkbox'
// import { CertificateStatus } from '@inphiz-shared/shared-models/src/F_Certificates/statuses'
// import { CertificateStates } from '@inphiz-shared/shared-models/src/F_Certificates/states'
import * as F_CertificatesConstants from '@inphiz-shared/shared-models/src/F_Certificates/CertificateConstants'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, CheckboxGroup, DateRangePicker, Radio, RadioGroup } from 'rsuite'
import { CalenderIcon, CloseIcon, RightIcon, SearchIcon } from '../../../icons'
import { CustomText, TextWithIcon } from '../../atoms'

const statusArray = [
  {
    title: F_CertificatesConstants.Statuses.approved,
    handle: F_CertificatesConstants.Statuses.approved,
  },
  {
    title: F_CertificatesConstants.Statuses.pending,
    handle: F_CertificatesConstants.Statuses.pending,
  },
  {
    title: F_CertificatesConstants.Statuses.archived,
    handle: F_CertificatesConstants.Statuses.archived,
  },
]

const lifecycleArray = [
  { title: F_CertificatesConstants.States.active, handle: F_CertificatesConstants.States.active },
  { title: F_CertificatesConstants.States.expired, handle: F_CertificatesConstants.States.expired },
  { title: F_CertificatesConstants.States.missing, handle: F_CertificatesConstants.States.missing },
]

const operationArray = [
  { title: 'Or', handle: 'Or' },
  { title: 'And', handle: 'And' },
]
interface CertificateModalProps {
  buttonComponent: ReactNode
  selectedOperation?: any
  selectedStatus?: ValueType[]
  selectedStates?: ValueType[]
  selectedValue?: ValueType[]
  selectedDate?: ValueType[]
  className?: string
  menuList?: { [key: string]: any }[]
  onFilterClear?: () => void
  onFilterChange?: (searchTxt: string) => void
  onSelectedOperation?: (data: any) => void
  onSelectedStatus?: (data: ValueType[]) => void
  onSelectedStates?: (data: ValueType[]) => void
  onSelectedValue?: (data: ValueType[]) => void
  onSelectedDate?: (data: ValueType[]) => void
}

export function CertificateModal({
  buttonComponent,
  menuList,
  selectedOperation,
  selectedStatus,
  selectedStates,
  selectedValue,
  selectedDate,
  className = '',
  onFilterClear,
  onFilterChange,
  onSelectedOperation,
  onSelectedStatus,
  onSelectedStates,
  onSelectedValue,
  onSelectedDate,
}: CertificateModalProps) {
  const { t } = useTranslation('common')
  const [showMore, setShowMore] = useState(false)
  const [searchTxt, setSearchTxt] = useState('')
  const [menu, setMenu] = useState<{ [key: string]: any }[]>([])
  const [isShowStatus, setIsShowStatus] = useState(false)
  const [isShowStates, setIsShowStates] = useState(false)
  const [isShowOperation, setIsShowOperation] = useState(false)
  const [isShowCalender, setIsShowCalender] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const buttonCalenderRef = useRef<HTMLDivElement>(null)
  const displayDate
    = selectedDate && selectedDate?.length > 0
      ? `${moment(selectedDate?.[0]).format('MM/DD/YYYY')} - ${moment(selectedDate?.[1]).format(
        'MM/DD/YYYY',
      )}`
      : ''

  useEffect(() => {
    if (showMore) {
      document.addEventListener('click', handleClickOutside)
    }
    else {
      document.removeEventListener('click', handleClickOutside)
    }
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [showMore, isShowCalender])

  useEffect(() => {
    if (menuList && menuList.length > 0) {
      setMenu(menuList)
    }
  }, [menuList])

  useEffect(() => {
    // Debounce logic
    if (menuList && menuList?.length > 0 && !onFilterChange) {
      const debounce = setTimeout(() => {
        const filteredData = menuList?.filter(item =>
          item.title?.toLowerCase().includes(searchTxt.toLowerCase()),
        )
        setMenu(filteredData ?? [])
      }, 500)
      return () => clearTimeout(debounce)
    }
  }, [searchTxt])

  const toggleMenu = () => {
    setShowMore(!showMore)
  }

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as Node
    if (buttonRef.current && !buttonRef.current.contains(target) && !isShowCalender) {
      setShowMore(false)
    }
    if (buttonCalenderRef.current && !buttonCalenderRef.current.contains(target)) {
      setIsShowCalender(false)
    }
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    setSearchTxt(newValue)
    onFilterChange?.(newValue)
  }

  const handleSelect = (selectedValue: any[] | null) => {
    onSelectedDate?.(selectedValue ?? [])
    setIsShowCalender(false)
  }

  return (
    <div className="relative items-center flex">
      <button ref={buttonRef} onClick={toggleMenu}>
        {buttonComponent}
      </button>
      {((selectedValue && selectedValue.length > 0)
        || (selectedDate && selectedDate?.length > 0)
        || (selectedStatus && selectedStatus?.length > 0)
        || (selectedStates && selectedStates?.length > 0)) && (
        <button onClick={onFilterClear}>
          <CloseIcon className="h-5 w-5 ml-2.5" />
        </button>
      )}
      <div onClick={e => e.stopPropagation()}>
        <DateRangePicker
          open={isShowCalender}
          ranges={[]}
          showHeader={false}
          value={
            selectedDate && selectedDate.length
              ? [new Date(selectedDate[0]), new Date(selectedDate[1])]
              : null
          }
          format="yyyy-MM-dd"
          onOk={handleSelect}
          style={{ visibility: 'hidden', position: 'absolute', zIndex: -1, left: 0 }}
        />
      </div>
      {showMore && (
        <div
          className={`absolute top-[44px] z-10 rounded-md bg-white shadow-md shadow-gray-900/10 menu ${showMore ? 'visible' : ''} ${className}`}
          onClick={e => e.stopPropagation()}
        >
          <div className="flex flex-1 items-center py-3 px-4" onClick={e => e.stopPropagation()}>
            <SearchIcon className="h-5 w-5 mr-2" />
            <input
              type="text"
              value={searchTxt}
              placeholder={t('searchPlaceholder')}
              className="outline-0"
              onChange={handleSearchChange}
            />
          </div>
          <div>
            <div
              ref={buttonCalenderRef}
              className="flex h-10 bg-bgColor px-4 items-center gap-2"
              onClick={() => {
                setIsShowCalender(!isShowCalender)
              }}
            >
              <TextWithIcon
                text={`Select date: ${displayDate}`}
                className="text-textSecondary"
                leftIcon={<CalenderIcon className="h-4 w-4 mr-2 fill-textSecondary" />}
                textVariant="smPoppinsRegular"
              />
            </div>
            <div
              className="border-t border-b border-stroke"
              onClick={(e) => {
                setIsShowOperation(!isShowOperation)
              }}
            >
              <div className="flex h-10 bg-bgColor px-4 flex-col justify-center">
                <TextWithIcon
                  text={`Logical operation: ${selectedOperation?.title}`}
                  className="text-textSecondary"
                  rightIcon={<RightIcon className="h-4 w-4 rotate-90 ml-4 stroke-textSecondary" />}
                  textVariant="smPoppinsRegular"
                />
              </div>
              {isShowOperation && (
                <div onClick={e => e.stopPropagation()}>
                  <RadioGroup
                    name="checkbox-group"
                    defaultValue={selectedOperation?.handle}
                    className="pl-4 min-w-fit max-h-60 overflow-auto scrollbar"
                    onChange={(value) => {
                      const data = operationArray?.filter(i => i?.handle == value)
                      onSelectedOperation?.(data[0])
                    }}
                  >
                    {operationArray?.map(i => (
                      <Radio key={i.handle} value={i.handle}>
                        <CustomText wrapText={false} variant="smPoppinsRegular">
                          {i.title}
                        </CustomText>
                      </Radio>
                    ))}
                  </RadioGroup>
                </div>
              )}
            </div>
            <div
              className="border-b border-stroke"
              onClick={() => {
                setIsShowStatus(!isShowStatus)
              }}
            >
              <div className="flex h-10 bg-bgColor px-4 flex-col justify-center">
                <TextWithIcon
                  text={`Select status: ${selectedStatus}`}
                  className="text-textSecondary"
                  rightIcon={<RightIcon className="h-4 w-4 rotate-90 ml-4 stroke-textSecondary" />}
                  textVariant="smPoppinsRegular"
                />
              </div>
              {isShowStatus && (
                <div onClick={e => e.stopPropagation()}>
                  <CheckboxGroup
                    name="checkbox-group"
                    value={selectedStatus}
                    onChange={onSelectedStatus}
                    className="pl-4 min-w-fit max-h-60 overflow-auto scrollbar"
                  >
                    {statusArray?.map(i => (
                      <Checkbox key={i.handle} value={i.handle}>
                        <CustomText wrapText={false} variant="smPoppinsRegular">
                          {i.title}
                        </CustomText>
                      </Checkbox>
                    ))}
                  </CheckboxGroup>
                </div>
              )}
            </div>
            <div
              className="border-b border-stroke"
              onClick={() => {
                setIsShowStates(!isShowStates)
              }}
            >
              <div className="flex h-10 bg-bgColor px-4 flex-col justify-center">
                <TextWithIcon
                  text={`Lifecycle: ${selectedStates}`}
                  className="text-textSecondary"
                  rightIcon={<RightIcon className="h-4 w-4 rotate-90 ml-4 stroke-textSecondary" />}
                  textVariant="smPoppinsRegular"
                />
              </div>
              {isShowStates && (
                <div onClick={e => e.stopPropagation()}>
                  <RadioGroup
                    name="checkbox-group"
                    defaultValue={selectedStates?.[0]}
                    className="pl-4 min-w-fit max-h-60 overflow-auto scrollbar"
                    onChange={(value) => {
                      const data: any[] = lifecycleArray?.filter(i => i?.handle == value)
                      onSelectedStates?.([data?.[0]?.handle])
                    }}
                  >
                    {lifecycleArray?.map(i => (
                      <Radio key={i.handle} value={i.handle}>
                        <CustomText wrapText={false} variant="smPoppinsRegular">
                          {i.title}
                        </CustomText>
                      </Radio>
                    ))}
                  </RadioGroup>
                </div>
              )}
            </div>
            <div className="flex h-10 bg-bgColor items-center px-4 border-b border-stroke">
              <CustomText
                wrapText={false}
                variant="smPoppinsRegular"
                textColor="text-textSecondary"
              >
                Select certificates
              </CustomText>
            </div>
            <CheckboxGroup
              name="checkbox-group"
              value={selectedValue}
              onChange={onSelectedValue}
              className="pl-4 min-w-fit max-h-56 overflow-auto scrollbar"
            >
              <>
                {menu
                  .filter(i => selectedValue?.includes(i.handle))
                  .map(i => (
                    <Checkbox key={i.handle} value={i.handle}>
                      <CustomText wrapText={false} variant="smPoppinsRegular">
                        {i.title}
                      </CustomText>
                    </Checkbox>
                  ))}

                {menu
                  .filter(i => !selectedValue?.includes(i.handle))
                  .map(i => (
                    <Checkbox key={i.handle} value={i.handle}>
                      <CustomText wrapText={false} variant="smPoppinsRegular">
                        {i.title}
                      </CustomText>
                    </Checkbox>
                  ))}
              </>
            </CheckboxGroup>
          </div>
        </div>
      )}
    </div>
  )
}
