import type { MultiDropdownParams } from '../../atoms'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Radio, RadioGroup } from 'rsuite'
import defaultTheme from 'tailwindcss/defaultTheme'
import {
  type JobTitleItem,
  useDeleteJobRole,
  useDeleteJobTitle,
  usePostJobRole,
  usePostJobTitle,
  usePutJobRole,
  usePutJobTitle,
} from '../../../packages/api-web/RoleManagement'
import {
  BorderButton,
  CustomButton,
  CustomInput,
  CustomText,
  CustomTextArea,
  MultiSelectDropDown,
  Spinner,
} from '../../atoms'
import { DeleteAlert } from '../../molecules'

interface MetaDataTypeParams {
  jobTitleList?: JobTitleItem[]
  selectedItem?: JobTitleItem
}

function MetaDataType({ jobTitleList, selectedItem }: MetaDataTypeParams) {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [selectedJobTitle, setSelectedJobTitle] = useState<string[]>([])
  const [jobType, setJobType] = useState<number>()
  const [errorString, setErrorString] = useState<string[]>([])
  const [isShowAlert, setIsShowAlert] = useState<boolean>(false)
  const { mutate, isPending } = usePostJobTitle()
  const { mutate: updateMutate, isPending: updateIsPending } = usePutJobTitle({ isJobTitle: true })
  const { mutate: postJobRole, isPending: isPostJobRole } = usePostJobRole()
  const { mutate: updateJobRole, isPending: isUpdateJobRole } = usePutJobRole()
  const { mutate: deleteJobRole, isPending: isDeleteJobRole } = useDeleteJobRole()
  const { mutate: deleteJobTitle, isPending: isDeleteJobTitle } = useDeleteJobTitle()

  const jobFilterData = useMemo(() => {
    return (jobTitleList ?? []).map(item => ({
      label: item?.title,
      value: item?.handle,
    })) as MultiDropdownParams[]
  }, [jobTitleList])

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (selectedItem) {
      if (selectedItem?.contentType == 'job-role') {
        const filter = jobTitleList
          ?.filter(i => i.jobRoleHandles?.includes(selectedItem?.handle ?? ''))
          .map(i => i.handle)
        setSelectedJobTitle(filter as string[])
      }
      else {
        setSelectedJobTitle([])
      }
      setTitle(selectedItem?.title ?? '')
      setDescription(selectedItem?.description ?? '')
    }
    else {
      setJobType(1)
      setSelectedJobTitle([])
      setTitle('')
      setDescription('')
    }
  }, [selectedItem])

  const onSavePress = () => {
    if (validateForm()) {
      if (jobType == 2 || selectedItem?.contentType == 'job-role') {
        apiCallJobRole()
      }
      else {
        apiCallJobTitle()
      }
    }
  }

  const validateForm = () => {
    const errors = []
    if (!title) {
      errors.push(t('equipmentTitle'))
    }
    if (!description) {
      errors.push(t('errorDescription'))
    }
    if (jobType === 2 && selectedJobTitle?.length === 0) {
      errors.push(t('jobTitleRequired'))
    }
    setErrorString(errors)
    return Object.keys(errors).length === 0
  }

  const apiCallJobTitle = () => {
    if (selectedItem) {
      const data = {
        title,
        description,
        handle: selectedItem?.handle ?? '',
      }
      updateMutate(data, {
        onSuccess: () => {
          if (screenWidth < Number.parseInt(defaultTheme.screens.lg.replace('px', ''), 10)) {
            navigate(-1)
          }
        },
      })
    }
    else {
      const data = {
        title,
        description,
      }
      mutate(data, {
        onSuccess: () => {
          if (screenWidth < Number.parseInt(defaultTheme.screens.lg.replace('px', ''), 10)) {
            navigate(-1)
          }
          else {
            setTitle('')
            setDescription('')
          }
        },
      })
    }
  }

  const apiCallJobRole = () => {
    if (selectedItem) {
      const data = {
        title,
        description,
        handle: selectedItem?.handle ?? '',
        jobTitleHandles: selectedJobTitle,
      }
      updateJobRole(data, {
        onSuccess: () => {
          if (screenWidth < Number.parseInt(defaultTheme.screens.lg.replace('px', ''), 10)) {
            navigate(-1)
          }
        },
      })
    }
    else {
      const data = {
        title,
        description,
        jobTitleHandles: selectedJobTitle,
      }
      postJobRole(data, {
        onSuccess: () => {
          if (screenWidth < Number.parseInt(defaultTheme.screens.lg.replace('px', ''), 10)) {
            navigate(-1)
          }
          else {
            setTitle('')
            setDescription('')
            setSelectedJobTitle([])
          }
        },
      })
    }
  }

  const deleteCategory = () => {
    setIsShowAlert(true)
  }

  return (
    <div className="flex flex-col flex-1 border border-stroke rounded-md overflow-y-auto scrollbar bg-white">
      <div className="px-3 flex justify-between h-10 border-b border-b-stroke items-center">
        <CustomText variant="smPoppinsSemiBold">{t('typeInformation')}</CustomText>
      </div>
      <div className="px-5 py-5 flex-1">
        {!selectedItem && (
          <div className="mb-2">
            <CustomText variant="mdPoppinsSemiBold">Type</CustomText>
            <RadioGroup
              inline
              name="radioList"
              value={jobType}
              onChange={(value) => {
                setJobType(value as number)
              }}
            >
              <Radio value={1} className="cm-checkbox">
                {t('jobTitle')}
              </Radio>
              <Radio value={2} className="cm-checkbox">
                {t('jobRole')}
              </Radio>
            </RadioGroup>
          </div>
        )}
        <CustomInput
          title={t('title')}
          value={title}
          onChange={(e) => {
            setTitle(e.target.value)
          }}
          placeholder={t('exHotWork')}
          className={`mb-3 ${!title && errorString.length > 0 ? 'outline-textDanger' : ''}`}
        />
        {(selectedItem?.contentType == 'job-role' || jobType == 2) && (
          <MultiSelectDropDown
            title={t('jobTitle')}
            value={selectedJobTitle}
            data={jobFilterData ?? []}
            onChange={(value) => {
              setSelectedJobTitle(value as string[])
            }}
            className="!mt-3 mb-3"
          />
        )}
        <CustomTextArea
          title={`${t('description')} ${t('maxCharacters')}`}
          placeholder={`${t('writeDescription')}`}
          aria-multiline
          value={description}
          onChange={(e) => {
            setDescription(e.target.value)
          }}
          className={`${!description && errorString.length > 0 ? 'outline-textDanger' : ''}`}
        />
        {errorString.length > 0 && (
          <ul className="mt-5 list-disc pl-4">
            {errorString.map((i, index) => (
              <li key={index} className="text-textDanger">
                {i}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="flex flex-wrap gap-4 justify-end py-4 px-3">
        {selectedItem && (
          <div className="flex-1">
            <CustomButton
              children={t('remove')}
              className="hidden sm:block"
              backgroundColor="bg-bthDisable"
              textColor="text-textPrimary"
              onClick={deleteCategory}
            />
          </div>
        )}
        <div className="flex gap-4 flex-wrap">
          {selectedItem && (
            <CustomButton
              children={t('remove')}
              className="!px-4 sm:!px-5 block sm:hidden flex-1"
              backgroundColor="bg-bthDisable"
              textColor="text-textPrimary"
              onClick={deleteCategory}
            />
          )}
          <BorderButton
            children={t('cancel')}
            className="!px-2 sm:!px-5 flex-1"
            textColor="text-textPrimary"
            onClick={() => {
              if (screenWidth < Number.parseInt(defaultTheme.screens.lg.replace('px', ''), 10)) {
                navigate(-1)
              }
              else {
                setTitle('')
                setDescription('')
                setSelectedJobTitle([])
              }
            }}
          />
          <CustomButton
            children={t('save')}
            className="flex-auto"
            backgroundColor="bg-secondary"
            textColor="text-textWhite"
            onClick={onSavePress}
          />
        </div>
      </div>
      {(isPending
        || updateIsPending
        || isPostJobRole
        || isUpdateJobRole
        || isDeleteJobRole
        || isDeleteJobTitle) && <Spinner />}
      {isShowAlert && (
        <DeleteAlert
          title={t('delete')}
          subTitle={
            selectedItem?.metadata?.CanBeDeleted == 'True'
            || selectedItem?.contentType == 'job-role'
              ? t('deleteNewDes')
              : t('deleteItemWarning')
          }
          {...((selectedItem?.metadata?.CanBeDeleted == 'True'
            || selectedItem?.contentType == 'job-role') && { leftButtonText: t('cancel') })}
          {...((selectedItem?.metadata?.CanBeDeleted == 'True'
            || selectedItem?.contentType == 'job-role') && {
            rightButtonText: t('delete'),
          })}
          {...(selectedItem?.metadata?.CanBeDeleted == 'False' && {
            singleButtonText: t('cancel'),
          })}
          onCloseAlert={() => {
            setIsShowAlert(false)
          }}
          onLeftButton={() => {
            setIsShowAlert(false)
          }}
          onSingleButton={() => {
            setIsShowAlert(false)
          }}
          onRightButton={() => {
            const data = {
              type: 'default',
              handle: selectedItem?.handle,
              contentType: selectedItem?.contentType,
            }
            if (jobType == 2 || selectedItem?.contentType == 'job-role') {
              deleteJobRole(data, {
                onSuccess: () => {
                  if (
                    screenWidth < Number.parseInt(defaultTheme.screens.lg.replace('px', ''), 10)
                  ) {
                    navigate(-1)
                  }
                  else {
                    setIsShowAlert(false)
                    setTitle('')
                    setDescription('')
                    setSelectedJobTitle([])
                  }
                },
              })
            }
            else {
              deleteJobTitle(data, {
                onSuccess: () => {
                  if (
                    screenWidth < Number.parseInt(defaultTheme.screens.lg.replace('px', ''), 10)
                  ) {
                    navigate(-1)
                  }
                  else {
                    setIsShowAlert(false)
                    setTitle('')
                    setDescription('')
                    setSelectedJobTitle([])
                  }
                },
              })
            }
          }}
        />
      )}
    </div>
  )
}

export default MetaDataType
