import type { EquipmentCategoryItem } from '../../packages/api-web/Equipment'
import FlatList from 'flatlist-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import defaultTheme from 'tailwindcss/defaultTheme'
import { CustomText } from '../../component'
import CreateEquipmentCategory from '../../component/templates/CreateEquipmentCategory'
import { RightIcon } from '../../icons'
import { useEquipmentCategory } from '../../packages/api-web/Equipment'

function EquipmentCategory() {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const { data: categoryList, isFetching } = useEquipmentCategory()
  const [selectedItem, setSelectedItem] = useState<EquipmentCategoryItem>()
  const [isExpandIndex, setIsExpandIndex] = useState<number>(-1)

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const onClickExpand = (item: EquipmentCategoryItem, idx: string) => {
    if (selectedItem?.handle == item?.handle) {
      setSelectedItem(undefined)
      setIsExpandIndex(-1)
    }
    else {
      setSelectedItem(item)
      setIsExpandIndex(Number.parseInt(idx))
    }
  }

  const renderItem = (item: EquipmentCategoryItem, idx: string) => {
    return (
      <div
        key={idx}
        className="cursor-pointer mt-1 flex flex-col"
        onClick={() => {
          if (screenWidth < Number.parseInt(defaultTheme.screens.lg.replace('px', ''), 10)) {
            navigate('/equipment/category/create', {
              state: { categoryList: categoryList?.items ?? [], selectedItem: item },
            })
          }
          else {
            onClickExpand(item, idx)
          }
        }}
      >
        <div
          className={`flex items-center justify-between px-3 py-2 ${selectedItem?.handle == item?.handle ? 'bg-table-odd-bg' : ''}`}
        >
          <CustomText variant="smPoppinsRegular">{item?.title ?? ''}</CustomText>
          <div
            className="flex w-10 justify-end"
            onClick={(e) => {
              if (screenWidth < Number.parseInt(defaultTheme.screens.lg.replace('px', ''), 10)) {
                e.stopPropagation()
                onClickExpand(item, idx)
              }
            }}
          >
            <RightIcon
              className={`${isExpandIndex == Number.parseInt(idx) ? 'rotate-90' : ''} h-4 w-4`}
            />
          </div>
        </div>
        {isExpandIndex == Number.parseInt(idx)
        && item?.childEquipmentCategories
        && item?.childEquipmentCategories?.length > 0
        && item?.childEquipmentCategories?.map((i, index) => {
          return (
            <div
              key={index}
              className={`py-2 px-8 ${selectedItem?.handle == i?.handle ? 'bg-table-odd-bg' : ''}`}
              onClick={(e) => {
                e.stopPropagation()
                setSelectedItem(i)
                if (
                  screenWidth < Number.parseInt(defaultTheme.screens.lg.replace('px', ''), 10)
                ) {
                  navigate('/equipment/category/create', {
                    state: { categoryList: categoryList?.items ?? [], selectedItem: i },
                  })
                }
              }}
            >
              <CustomText variant="smPoppinsRegular">{i?.title ?? ''}</CustomText>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className="p-5 h-full flex flex-col">
      <CustomText variant="xlPoppinsSemiBold">{t('categories')}</CustomText>
      <div className="flex mt-6 mb-4 items-center justify-between">
        <CustomText variant="lgPoppinsSemiBold">{t('overview')}</CustomText>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 flex-1 overflow-hidden">
        <div className="overflow-y-auto relative flex flex-col border border-stroke bg-white rounded-lg">
          <div className="px-3 flex justify-between h-10 border-b border-b-stroke items-center">
            <CustomText variant="smPoppinsSemiBold">
              {`${t('all')} ${t('categories').toLowerCase()}`}
            </CustomText>
            <CustomText
              className="!text-secondary"
              variant="smPoppinsSemiBold"
              onClick={() => {
                if (screenWidth < Number.parseInt(defaultTheme.screens.lg.replace('px', ''), 10)) {
                  navigate('/equipment/category/create', {
                    state: { categoryList: categoryList?.items ?? [] },
                  })
                }
                else {
                  setSelectedItem(undefined)
                }
              }}
            >
              {`${t('createNew')} +`}
            </CustomText>
          </div>
          <div className="overflow-y-auto scrollbar flex-1">
            <FlatList list={categoryList?.items ?? []} renderItem={renderItem} />
          </div>
        </div>
        <div className="ml-5 col-span-2 relative flex flex-col max-lg:hidden overflow-hidde">
          <CreateEquipmentCategory
            categoryList={categoryList?.items ?? []}
            selectedItem={selectedItem}
          />
        </div>
      </div>
    </div>
  )
}

export default EquipmentCategory
