import type { EquipmentAssignmentItem } from '../../packages/api-web/Equipment'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  BorderButton,
  CustomButton,
  CustomText,
  DeleteAlert,
  EquipmentAssignModal,
  Spinner,
} from '../../component'
import { dateTimeFormat, globalDateFormat } from '../../helpers'
import { CloseIcon, DeleteIcon, ReminderIcon, SearchIcon } from '../../icons'
import {
  useByequipmentType,
  useDeleteEquipmentAssignment,
  useEquipmentDetails,
  usePostEquipmentNotification,
} from '../../packages/api-web/Equipment'

function EquipmentDetails() {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { id } = useParams()
  const [searchTxt, setSearchTxt] = useState('')
  const [isDelete, setIsDelete] = useState<boolean>(false)
  const [isAddUser, setIsAddUser] = useState<boolean>(false)
  const [selectedAssignment, setSelectedAssignment] = useState<EquipmentAssignmentItem>()
  const [assignmentEquipmentList, setAssignmentEquipmentList]
    = useState<EquipmentAssignmentItem[]>()
  const { data: equipment, isFetching: isEquipmentFetching } = useEquipmentDetails({
    handle: id ?? '',
  })
  const { data: assignmentEquipment, isFetching: isAssignmentEquipment } = useByequipmentType({
    typeHandle: id ?? '',
  })
  const { isPending: isNotification, mutate: mutateNotification } = usePostEquipmentNotification()
  const { isPending: isDeleteAssignment, mutate: mutateDeleteAssignment }
    = useDeleteEquipmentAssignment()

  const equipmentDetails = useMemo(() => {
    const result = equipment?.item
    return result
  }, [equipment])

  useMemo(() => {
    const result = assignmentEquipment?.items
    setAssignmentEquipmentList(result)
  }, [assignmentEquipment])

  const handleInputKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (searchTxt?.length > 0) {
        const filter = assignmentEquipmentList?.filter(i =>
          i?.user?.displayName?.toLowerCase()?.includes(searchTxt),
        )
        setAssignmentEquipmentList(filter)
      }
      else {
        setAssignmentEquipmentList(assignmentEquipment?.items)
      }
    }
  }

  if (isEquipmentFetching || isAssignmentEquipment) {
    return <Spinner />
  }

  return (
    <div className="p-5 h-full flex flex-col">
      {(isNotification || isDeleteAssignment) && <Spinner />}
      {isDelete && (
        <DeleteAlert
          title={t('deleteAssignment')}
          subTitle={`${t('deleteEquipmentConnection')}.`}
          leftButtonText={t('cancel')}
          rightButtonText={t('delete')}
          onCloseAlert={() => {
            setIsDelete(false)
          }}
          onLeftButton={() => {
            setIsDelete(false)
          }}
          onRightButton={() => {
            const data = {
              handle: selectedAssignment?.handle,
              typeHandle: id,
              contentType: 'equipment-assignment',
            }
            mutateDeleteAssignment(data, {
              onSuccess: () => {
                setIsDelete(false)
              },
            })
          }}
        />
      )}
      {isAddUser && (
        <EquipmentAssignModal
          handle={id}
          onCloseAlert={() => {
            setIsAddUser(false)
          }}
        />
      )}
      <div className="flex items-center justify-between">
        <CustomText variant="xlPoppinsBold" className="flex-1">
          {`${t('equipment')} ${t('details').toLowerCase()}`}
        </CustomText>
        <BorderButton
          children={t('edit')}
          className="px-3 self-end mr-5"
          onClick={() => {
            navigate(`/equipment/edit/${id}`)
          }}
        />
        <CustomButton
          children={t('assignUsers')}
          textVariant="smPoppinsMedium"
          className="px-4 sm:px-8 bg-secondary"
          onClick={() => {
            setIsAddUser(true)
          }}
        />
      </div>
      <div className="mt-5 grid grid-cols-1 lg:grid-cols-3 gap-4 flex-1 items-start overflow-hidden overflow-y-auto scrollbar">
        <div className="p-5 col-span-1 lg:col-span-2 bg-white rounded-lg gap-5 flex flex-col">
          <div>
            <CustomText variant="smPoppinsSemiBold">{t('title')}</CustomText>
            <CustomText variant="mdPoppinsRegular">{equipmentDetails?.title ?? ''}</CustomText>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3">
            <div className="gap-5 flex flex-col">
              <div>
                <CustomText variant="smPoppinsSemiBold">{t('mainCategory')}</CustomText>
                <CustomText variant="mdPoppinsRegular">
                  {equipmentDetails?.equipmentCategory?.parentEquipmentCategory?.title ?? ''}
                </CustomText>
              </div>
              <div>
                <CustomText variant="smPoppinsSemiBold">{t('endOfUseDate')}</CustomText>
                <CustomText variant="mdPoppinsRegular">
                  {equipmentDetails?.endOfUseDate
                    ? dateTimeFormat(equipmentDetails?.endOfUseDate ?? '')
                    : '-'}
                </CustomText>
              </div>
              <div>
                <CustomText variant="smPoppinsSemiBold">{t('requiresSignature')}</CustomText>
                <CustomText variant="mdPoppinsRegular">
                  {equipmentDetails?.requiresSign ? 'Yes' : 'No'}
                </CustomText>
              </div>
            </div>
            <div className="gap-5 flex flex-col">
              <div>
                <CustomText variant="smPoppinsSemiBold">{t('subCategory')}</CustomText>
                <CustomText variant="mdPoppinsRegular">
                  {equipmentDetails?.equipmentCategory?.title ?? ''}
                </CustomText>
              </div>
              <div>
                <CustomText variant="smPoppinsSemiBold">{t('costEstimationTitle')}</CustomText>
                <CustomText variant="mdPoppinsRegular">{`${equipmentDetails?.price} SEK`}</CustomText>
              </div>
              <div>
                <CustomText variant="smPoppinsSemiBold">{t('isVisible')}</CustomText>
                <CustomText variant="mdPoppinsRegular">
                  {equipmentDetails?.isVisible ? 'Yes' : 'No'}
                </CustomText>
              </div>
            </div>
          </div>
        </div>
        <div className="p-5 bg-white rounded-lg">
          <CustomText variant="lgPoppinsSemiBold">{`${t('assignedToWho')}?(${assignmentEquipmentList?.length})`}</CustomText>
          <div className="p-1 flex flex-1 mt-2 items-center justify-between border border-stroke rounded-lg">
            <div className="flex items-center w-auto gap-4 flex-1">
              <SearchIcon />
              <input
                type="text"
                value={searchTxt}
                placeholder={t('searchPlaceholder')}
                className="outline-0 flex-1 min-w-[100px]"
                onChange={e => setSearchTxt(e.target.value)}
                onKeyDown={handleInputKeyDown}
              />
              {searchTxt && (
                <button
                  onClick={() => {
                    setSearchTxt('')
                    setAssignmentEquipmentList(assignmentEquipment?.items)
                  }}
                >
                  <CloseIcon className="h-5" />
                </button>
              )}
            </div>
          </div>
          {assignmentEquipmentList && assignmentEquipmentList?.length > 0 ? (
            <div className="mt-5 flex-1 overflow-x-auto overflow-y-auto scrollbar bg-white border border-stroke">
              <table className="w-full text-left">
                <tbody>
                  {assignmentEquipmentList?.map((item) => {
                    return (
                      <tr className="odd:bg-table-odd-bg">
                        <td className="px-5 py-4 border-r border-r-stroke">
                          <CustomText wrapText={false} variant="smPoppinsRegular">
                            {item?.user?.displayName ?? ''}
                          </CustomText>
                          <CustomText wrapText={false} variant="xsmPoppinsRegular">
                            {item?.user?.subtitle?.text ?? ''}
                          </CustomText>
                        </td>
                        <td className="px-5 py-4 flex items-center">
                          <CustomText
                            wrapText={false}
                            variant="smPoppinsRegular"
                            className="bg-bgColor px-3 py-2 rounded-full"
                          >
                            {`${t('assigned').toUpperCase()} ${globalDateFormat(item?.createdTime ?? '')}`}
                          </CustomText>
                          <button
                            className="ml-2"
                            title="Send a reminder notification"
                            onClick={() => {
                              mutateNotification({ handle: item?.handle })
                            }}
                          >
                            <ReminderIcon />
                          </button>
                          <button
                            className="ml-2"
                            title="Delete equipment assignment"
                            onClick={() => {
                              setIsDelete(true)
                              setSelectedAssignment(item)
                            }}
                          >
                            <DeleteIcon className="fill-textDanger" />
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <CustomText variant="smPoppinsRegular" className="mt-5 text-center">
              {t('noDataFound')}
            </CustomText>
          )}
        </div>
      </div>
    </div>
  )
}

export default EquipmentDetails
