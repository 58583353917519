import type { Group } from '../../packages/api-web'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CreateSocialPost, CustomTab, CustomText, SnackList, Spinner } from '../../component'
import { useFollowersQuery } from '../../packages/api-web'

function SocialScreen() {
  const { t } = useTranslation('common')
  const [tabListData, setTabListData] = useState<Group[]>([])
  const [selectedItem, setSelectedItem] = useState<Group>()
  const [isVisible, setIsVisible] = useState(false)
  const { isLoading, data: tabData } = useFollowersQuery('groupwallpost')

  useEffect(() => {
    if (tabData && tabData?.length)
      setTabListData(tabData)
  }, [tabData])

  if (isLoading)
    return <Spinner />

  return (
    <div className="p-5 h-full flex flex-col">
      <CustomText variant="xlPoppinsBold">{`${t('snack')}`}</CustomText>
      <div className="flex flex-1 overflow-hidden">
        <div className="flex relative flex-col overflow-hidden">
          <div className="mt-5 mb-2">
            <CustomTab
              tabList={tabListData}
              selectedItem={selectedItem}
              onClick={(i) => {
                if (selectedItem?.handle !== i.handle) {
                  setSelectedItem(i)
                }
              }}
            />
          </div>
          <SnackList
            selectedItem={selectedItem}
            onEmptyBtnPress={() => {
              setIsVisible(true)
              // navigate('/create_post', { state: { gType: selectedItem } })
            }}
          />
        </div>
      </div>
      <CreateSocialPost
        isVisible={isVisible}
        onClose={() => setIsVisible(false)}
        gType={selectedItem}
      />
    </div>
  )
}

export default SocialScreen
