import { APIConst } from '@inphiz/api'

import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import { useMutation } from '@tanstack/react-query'

export function useLoginMutation() {
  return useMutation({
    mutationKey: [QueryKey.loginData],
    mutationFn: async (isLogin: boolean) => {
      if (isLogin)
        await APIFunctions.GET(APIConst.usersDirectory.loginDate)
      const result = await APIFunctions.GET(`${APIConst.usersDirectory.userProfile}?handle=@me`)
      return result
    },
  })
}
