import type { MultiDropdownParams } from '../../component'
import type { SchedulesParam } from '../../packages/api-web'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  BorderButton,
  CustomButton,
  CustomInput,
  CustomText,
  DeleteAlert,
  MultiSelectDropDown,
  Spinner,
} from '../../component'
import CustomDatePicker from '../../component/atoms/CustomDatePicker'
import ScheduleItem from '../../component/organisms/ScheduleItem'
import {
  useCampaignCreate,
  useCampaignDelete,
  useCampaignDetails,
  useCampaignStatus,
  useFeedbackGroupList,
} from '../../packages/api-web'

function DetailsCampaign() {
  const { t } = useTranslation('common')
  const { id } = useParams()
  const navigate = useNavigate()
  const { data: groupData } = useFeedbackGroupList()
  const { data, isFetching } = useCampaignDetails(id ?? '')
  const { mutate, isPending } = useCampaignCreate()
  const { mutate: deleteMutate, isPending: isDeletePending } = useCampaignDelete()
  const { mutate: statusChangeMutate, isPending: statusPending } = useCampaignStatus()
  const [scheduleList, setScheduleList] = useState<SchedulesParam[] | []>([])
  const [campaignName, setCampaignName] = useState<string>('')
  const [selectedGroups, setSelectedGroups] = useState<string[]>([])
  const [campaignDescription, setCampaignDescription] = useState<string>('')
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [errorString, setErrorString] = useState<string[]>([])
  const [isShowAlert, setIsShowAlert] = useState(false)

  useEffect(() => {
    if (data && data.isSuccess) {
      const startAt = data?.item?.startAt
      const endAt = data?.item?.endAt
      setCampaignName(data?.item?.title)
      setCampaignDescription(data?.item?.description)
      setSelectedGroups(data?.item?.groupHandles)
      setStartDate(startAt ? new Date(startAt) : null)
      setEndDate(endAt ? new Date(endAt) : null)
      setScheduleList(data?.item?.schedules)
    }
  }, [data])

  const groupFilterData = useMemo(() => {
    return (groupData?.items ?? []).map(item => ({
      label: item?.title,
      value: item?.handle,
    })) as MultiDropdownParams[]
  }, [groupData])

  if (isFetching)
    return <Spinner />

  return (
    <div className="p-5 h-full flex flex-col">
      <div className="flex justify-between items-center">
        <CustomText variant="xlPoppinsBold">
          {`${t('campaigns')} / ${data?.item?.title}`}
        </CustomText>
        <BorderButton
          children={t('viewStatistics')}
          className="!px-2 sm:!px-5"
          textColor="text-textPrimary"
          onClick={() => navigate(`/feedback/campaign/statistics/${id}`)}
        />
      </div>
      <div className="mt-5 overflow-y-auto scrollbar">
        <div className="mb-5 grid grid-cols-1 lg:grid-cols-5 items-center gap-5 bg-white p-5 rounded-md">
          <CustomInput
            title={t('campaignName')}
            className={`mt-[5px] ${!campaignName && errorString.length > 0 ? 'outline-textDanger' : ''}`}
            value={campaignName}
            onChange={(e) => {
              setCampaignName(e.target.value)
            }}
          />
          <MultiSelectDropDown
            title={t('campaignGroup')}
            value={selectedGroups}
            data={groupFilterData ?? []}
            onChange={(value) => {
              setSelectedGroups(value as string[])
            }}
          />
          <CustomInput
            title={t('campaignDescription')}
            className={`mt-[5px] ${!campaignDescription && errorString.length > 0 ? 'outline-textDanger' : ''}`}
            value={campaignDescription}
            onChange={(e) => {
              setCampaignDescription(e.target.value)
            }}
          />
          <CustomDatePicker
            title={t('startDate')}
            minDate={new Date()}
            value={startDate}
            onChange={setStartDate}
          />
          <CustomDatePicker
            title={t('endDate')}
            minDate={startDate}
            value={endDate}
            onChange={setEndDate}
          />
        </div>
        <div className="flex flex-col">
          {scheduleList.map((item, index) => (
            <ScheduleItem
              key={index}
              item={item}
              index={index}
              errorString={errorString}
              handleEdit={(itemIndex, field, value) => {
                const data = [...scheduleList]
                data[itemIndex] = {
                  ...data[itemIndex],
                  [field]: value,
                }
                setScheduleList(data)
              }}
            />
          ))}
        </div>
        <CustomButton
          children={t('addNewSchedule')}
          className="self-start bg-secondary"
          onClick={() => {
            const array = [...scheduleList]
            array.push({
              title: '',
              description: '',
              startAt: new Date(),
              endAt: null,
              cron: '0 0 15 1/1 * ? *',
              jobTitleHandles: [],
              surveys: [],
            })
            setScheduleList(array)
          }}
        />
        {errorString.length > 0 && (
          <ul className="mt-5 list-disc pl-4">
            {errorString.map((i, index) => (
              <li key={index} className="text-textDanger">
                {i}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="relative flex flex-wrap gap-4 justify-between bg-white p-4 rounded-md mt-5">
        <BorderButton
          children="Delete"
          className="!px-5 hidden sm:block"
          textColor="text-textPrimary"
          onClick={() => setIsShowAlert(true)}
        />
        <div className="flex gap-4 flex-wrap">
          <BorderButton
            children="Delete"
            className="!px-5 block sm:hidden flex-1"
            textColor="text-textPrimary"
            onClick={() => setIsShowAlert(true)}
          />
          <CustomButton
            children={data?.item?.status == 0 ? t('stop') : t('start')}
            backgroundColor="bg-primary"
            className="!px-5 sm:!px-14 flex-1"
            textColor="text-textWhite"
            onClick={() => {
              const statusChange = {
                campaignHandle: data?.item.handle ?? '',
                status: data?.item?.status == 0 ? 1 : 0,
              }
              statusChangeMutate(statusChange)
            }}
          />
          <CustomButton
            children={t('cancel')}
            backgroundColor="bg-bthDisable"
            className="!px-5 md:!px-14 flex-1"
            textColor="text-textPrimary"
            onClick={() => {
              navigate(-1)
            }}
          />
          <CustomButton
            children={t('save')}
            backgroundColor="bg-secondary"
            textColor="text-textWhite"
            className="flex-auto"
            onClick={() => {
              if (campaignName && campaignDescription && scheduleList.every(i => i.title)) {
                const newObj = {
                  ...data?.item,
                  title: campaignName,
                  description: campaignDescription,
                  startAt: startDate,
                  endAt: endDate,
                  groupHandles: selectedGroups,
                  schedules: scheduleList,
                }
                mutate(newObj, {
                  onSuccess: (data) => {
                    navigate(-1)
                  },
                })
              }
              else {
                const errorArray = []
                if (!campaignName) {
                  errorArray.push(t('errorCampaign'))
                }
                if (!campaignDescription) {
                  errorArray.push(t('errorDescription'))
                }
                scheduleList.forEach((i, index) => {
                  if (!i.title) {
                    errorArray.push(`${t('schedule')} ${index}: ${t('errorSchedule')}`)
                  }
                })
                setErrorString(errorArray)
              }
            }}
          />
        </div>
      </div>
      {(isPending || isDeletePending || statusPending) && <Spinner />}
      {isShowAlert && (
        <DeleteAlert
          title={`${t('campaigns')}: ${data?.item?.title}`}
          subTitle={t('deleteCampaign')}
          leftButtonText={t('cancel')}
          rightButtonText={t('delete')}
          onCloseAlert={() => {
            setIsShowAlert(false)
          }}
          onRightButton={() => {
            setIsShowAlert(false)
            deleteMutate(data?.item?.handle ?? '', {
              onSuccess: (data) => {
                if (data.isSuccess) {
                  navigate(-1)
                }
              },
            })
          }}
        />
      )}
    </div>
  )
}

export default DetailsCampaign
