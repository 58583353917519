import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseIcon } from '../../../icons'
import { useArchivingReasons } from '../../../packages/api-web/Employee'
import { CustomAlert, CustomButton, CustomDropDown, CustomText, CustomTextArea } from '../../atoms'

interface Reason {
  title?: string
  handle?: string
}

interface GroupMembersProps {
  onCloseAlert?: () => void
  onRemove?: (item: any) => void
}

export function DeleteReasonsModal({ onCloseAlert, onRemove }: GroupMembersProps) {
  const { t } = useTranslation('common')
  const [selectReason, setSelectReason] = useState<Reason>()
  const [comment, setComment] = useState<string>('')
  const { data: reasonsList } = useArchivingReasons()

  const data = useMemo(() => {
    const result = reasonsList?.items?.map((i) => {
      return {
        title: i,
        handle: i,
      }
    })
    if (result && result?.length > 0) {
      setSelectReason(result[0] as Reason)
    }
    return result
  }, [reasonsList])

  return (
    <CustomAlert
      onClose={() => {
        onCloseAlert?.()
      }}
    >
      <div className="flex items-center justify-center">
        <CustomText variant="xlPoppinsSemiBold" className="flex-1">
          {`${t('remove')} ${t('certificate')}`}
        </CustomText>
        <button onClick={() => onCloseAlert?.()}>
          <CloseIcon />
        </button>
      </div>
      <div className="mt-5 flex flex-col text-left gap-4 py-5 border-y border-stroke">
        <CustomDropDown
          value={selectReason?.handle}
          title={`${t('select')} ${t('reason')}`}
          data={data ?? []}
          labelField="title"
          valueField="handle"
          className="!mb-2"
          onSelect={(_, item) => {
            setSelectReason(item as Reason)
          }}
        />
        <CustomTextArea
          rows={5}
          title={t('comment')}
          value={comment}
          onChange={(e) => {
            setComment(e.target.value)
          }}
        />
      </div>
      <div className="flex justify-end mt-5 gap-3">
        <CustomButton
          children={t('cancel')}
          backgroundColor="bg-bthDisable"
          textColor="text-textPrimary"
          onClick={() => {
            onCloseAlert?.()
          }}
          className="!px-5"
        />
        <CustomButton
          children={t('remove')}
          backgroundColor="bg-secondary"
          textColor="text-textWhite"
          className="!px-5"
          onClick={() => {
            onRemove?.({ reason: selectReason?.handle, comment })
            onCloseAlert?.()
          }}
        />
      </div>
    </CustomAlert>
  )
}
