import type { News } from '../../../packages/api-web'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { dateFormat } from '../../../helpers'
import { NewsCommentIcon, NewsLikeIcon } from '../../../icons'
import { CustomText, TextWithIcon } from '../../atoms'
import { UserLikeListModal } from '../../molecules'

export interface NewItemProps {
  item: News
}

export function NewItem({ item }: NewItemProps) {
  const { t } = useTranslation('common')
  const [isVisible, setIsVisible] = useState(false)

  return (
    <div className="bg-white border border-stroke">
      {item && item.coverMedia?.length > 0 && (
        <img className="bg-center new-img" src={item?.coverMedia[0]?.url} />
      )}
      <div className="px-6 pt-3 flex-col">
        <div className="flex items-center justify-between">
          <CustomText className="!text-[12px]" variant="smPoppinsRegular">
            {dateFormat(item?.publishTime)}
          </CustomText>
          <CustomText className="!text-[12px]" variant="smPoppinsRegular">
            {item?.groups[0]?.title.toUpperCase()}
          </CustomText>
        </div>
        <div className="pt-2">
          <CustomText className="line-clamp-2" variant="mdPoppinsSemiBold">
            {item?.title}
          </CustomText>
        </div>
        <div className="py-2">
          <CustomText className="line-clamp-3" variant="smPoppinsRegular">
            {item?.summary}
          </CustomText>
        </div>
      </div>
      {/* {item?.metadata.IsLikesEnabled === 'True' && item?.metadata.IsCommentsEnabled === 'True' && (
        <div className=" flex items-center justify-between border-t border-t-stroke">
          <div className="flex flex-1 py-3 justify-center items-center border-r border-r-stroke">
            <TextWithIcon
              leftIcon={(
                <UnLike
                  className={`mr-2 ${item?.metadata?.IsLikedByUser === `True` ? 'fill-secondary' : 'fill-white'}`}
                />
              )}
              text={t('likeU')}
              textVariant="smPoppinsRegular"
            />
          </div>
          <div className="flex flex-1 justify-center items-center">
            <TextWithIcon
              leftIcon={<Comment className="mr-2" />}
              text={t('comment')}
              textVariant="smPoppinsRegular"
            />
          </div>
        </div>
      )} */}
      {item?.metadata.IsLikesEnabled === 'True' && item?.metadata.IsCommentsEnabled === 'True' && (
        <div className="flex items-center justify-between px-6 pt-2 pb-4">
          <button
            onClick={(e) => {
              e.stopPropagation()
              if (item.likeCount > 0)
                setIsVisible(true)
            }}
          >
            <TextWithIcon
              leftIcon={<NewsLikeIcon className="h-5 w-5 mr-2" />}
              text={`${item.likeCount} ${t('likeU')}`}
              textVariant="smPoppinsRegular"
              className="!text-[12px]"
            />
          </button>
          <TextWithIcon
            leftIcon={<NewsCommentIcon className="h-5 w-5 mr-2" />}
            text={`${item?.commentCount} ${t('comment')}`}
            textVariant="smPoppinsRegular"
            className="!text-[12px]"
          />
        </div>
      )}
      <UserLikeListModal
        handle={item?.handle}
        likeCount={item?.likeCount}
        isVisible={isVisible}
        onClose={() => {
          setIsVisible(false)
        }}
      />
    </div>
  )
}
