import { APIConst } from '@inphiz/api'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseIcon } from '../../../icons'
import { useGetLikesByHandle } from '../../../packages/api-web'
import { CustomImg, CustomText, Divider, Spinner } from '../../atoms'

interface CustomModalProps {
  isVisible: boolean
  onClose: () => void
  handle: string
  likeCount?: number
}

export function UserLikeListModal({ isVisible, handle, likeCount, onClose }: CustomModalProps) {
  const { t } = useTranslation('common')
  const [showModal, setShowModal] = useState(isVisible)
  const { data, isFetching } = useGetLikesByHandle(handle)

  useEffect(() => {
    if (isVisible) {
      setShowModal(true)
    }
    else {
      setTimeout(() => setShowModal(false), 300)
    }
  }, [isVisible])

  if (!showModal)
    return null

  return (
    <div
      className={`fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center bg-stroke-60 transition-opacity ${isVisible ? 'opacity-100' : 'opacity-0'} duration-300`}
      style={{ zIndex: 999 }}
    >
      <div
        className={`bg-white border-stroke border z-50 rounded-md transform transition-transform ${isVisible ? 'scale-100' : 'scale-90 duration-300'} duration-300 w-[340px]`}
      >
        <div className="flex items-center p-4 justify-between">
          <CustomText className="!text-[12px]" variant="smPoppinsSemiBold">
            {`${likeCount ?? 0} ${t('haveLikeThis')}`}
          </CustomText>
          <button
            onClick={(e) => {
              e.stopPropagation()
              onClose()
            }}
          >
            <CloseIcon />
          </button>
        </div>
        <Divider />
        <div className="h-[320px] overflow-y-auto scrollbar pt-3">
          {isFetching && <Spinner />}
          <ul>
            {data?.items?.map(i => (
              <div key={i.handle} className="flex items-center pl-4 mb-4">
                <CustomImg
                  name={i?.author?.displayName}
                  imgSrc={
                    i?.author?.profileImageHandle
                      ? `${APIConst.baseURL}/${APIConst.mediaUpload}/${i?.author?.profileImageHandle}`
                      : ''
                  }
                  altText="user_image"
                  className="h-[50px] w-[50px] rounded-full"
                  imgClassName="rounded-full"
                />
                <CustomText variant="smPoppinsRegular" className="ml-2">
                  {i?.author?.displayName ?? ''}
                </CustomText>
              </div>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
