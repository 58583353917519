import type { NotificationSettingParam } from './useGetSettingList'
import { UpdateNotificationSettingsApiRequest } from '@inphiz-shared/shared-models/src/F_UserNotificationSettings/update-notification-settings-api-request'
import { queryClient } from '@inphiz/api/src'
import { useMutation } from '@tanstack/react-query'
import APIFunctions from '../Networking/APIFunctions'
import { APIConst } from '../Networking/APIServices'
import { QueryKey } from '../QueryKey'

const NotificationSettingProps = new UpdateNotificationSettingsApiRequest()
export type NotificationSettingRequest = typeof NotificationSettingProps

interface responseParams {
  data: NotificationSettingRequest
}

function onSetSettingSuccess(result: responseParams) {
  queryClient.setQueryData<NotificationSettingParam>(
    [QueryKey.notificationSetting],
    (requestsHistory) => {
      if (requestsHistory === undefined)
        return

      return {
        ...requestsHistory,
        groups: requestsHistory.groups?.map(item => ({
          ...item,
          items: [...(result.data.items ?? [])],
        })),
      }
    },
  )
}

export function usePostSettingItem() {
  return useMutation({
    mutationKey: [QueryKey.setNotificationSetting],
    mutationFn: async (data: NotificationSettingRequest) => {
      const params = {
        ...data,
        items: [...(data.items?.filter(i => i.isEditable) ?? [])],
      }
      await APIFunctions.POST(APIConst.getNotificationSettings, params)
      return { data }
    },
    onSuccess: onSetSettingSuccess,
  })
}
