import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import { QueryBuilder } from '@inphiz/core-utilities'
import { useQuery } from '@tanstack/react-query'
import APIFunctions from '../Networking/APIFunctions'
import { APIConst } from '../Networking/APIServices'
import { QueryKey } from '../QueryKey'

export interface PermitsItem {
  startDate?: string
  endDate?: string
  status?: number
  holder?: {
    handle?: string
    displayName?: string
    profileImageHandle?: string
    jobTitle?: string
    department?: string
  }
  issuer?: {
    handle?: string
    displayName?: string
  }
  creator?: {
    handle?: string
    displayName?: string
  }
  fields?: Array<{
    name?: string
    value?: string
  }>
  approvalSteps?: Array<{
    stepType?: number
    stepAction?: number
    details?: string
    timestamp?: string
    initiatedBy?: {
      displayName?: string
    }
  }>
  certificates?: Array<{
    title?: string
    handle?: string
    imageMediaItemDto?: {
      url?: string
    }
  }>
  handle?: string
}

const PermitsDataDto = new DataCollectionApiResponse<PermitsItem>()
export type PermitsData = typeof PermitsDataDto

interface Params {
  itemPerPage?: number
  skip?: number
  userHandle?: string
  isNeedAction?: boolean
}

export function usePermitsList({
  itemPerPage = 0,
  skip = 0,
  userHandle,
  isNeedAction = false,
}: Params) {
  return useQuery<PermitsData>({
    queryKey: [QueryKey.getPermits, isNeedAction],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.permits.getPermits)
        .add('cursor', skip)
        .add('take', itemPerPage)
        .add('ParametersWithValues[IncludeHolder]', 'True')
        .add('ParametersWithValues[IncludeApprovalSteps]', 'True')
        .add('ParametersWithValues[IncludeIssuer]', 'True')
      if (!isNeedAction) {
        qb.add('ParametersWithValues[HolderUserHandle]', userHandle)
      }
      if (isNeedAction) {
        qb.add('ParametersWithValues[NeedsActionByUserHandle]', userHandle)
      }
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
  })
}
