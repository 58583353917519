import { msalInstance } from '@inphiz/api-web/src/Networking/ApiWebAuthProvider'
import { appConfig, AuthType } from '@inphiz/app-config/src/AppConfig'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import F_WelcomeScreens_CompanyLogoIcon from '../../assets/F_WelcomeScreens/CompanyLogoIcon'
import { CustomButton, CustomText, Spinner } from '../../component'
import { UserContext } from '../../contexts/UserContext'
import { login_bg } from '../../icons'
import { useLoginMutation } from '../../packages/api-web'

const b2c = appConfig.properties.auth_azureb2c

function Login() {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const userContext = useContext(UserContext)
  const { mutateAsync } = useLoginMutation()
  const [sessionLoading, setSessionLoading] = useState(true)

  useEffect(() => {
    const retrieveUserSession = async () => {
      const response = await mutateAsync(true)
      if (response.isSuccess) {
        navigateScreen()
        return
      }

      setSessionLoading(false)
    }
    retrieveUserSession()
  }, [])

  const onPressLogin = async () => {
    if (appConfig.properties.auth_type === AuthType.Msal) {
      alert(`MSAL AuthType is not supported in web app`)
      return
    }

    msalInstance
      .loginPopup({
        scopes: b2c.Scopes,
      })
      .then(async (result) => {
        if (result.accessToken !== undefined) {
          const response = await mutateAsync(true)
          userContext.setUser({
            userData: response.user,
          })
          navigateScreen()
        }
      })
      .catch((error) => {
        console.log('error ::: ', error)
      })
  }

  const navigateScreen = () => {
    navigate('/dashboard', { replace: true })
  }

  if (sessionLoading) {
    return (
      <div
        style={{ backgroundImage: `url(${login_bg})` }}
        className="min-h-screen items-center justify-center bg-no-repeat bg-cover flex-col flex bg-center"
      >
        <div className="flex flex-col text-center items-center">
          <Spinner />
        </div>
      </div>
    )
  }

  return (
    <div
      style={{ backgroundImage: `url(${login_bg})` }}
      className="min-h-screen items-center justify-center bg-no-repeat bg-cover flex-col flex bg-center"
    >
      <div className="flex p-10 flex-col text-center rounded-xl items-center bg-white">
        <F_WelcomeScreens_CompanyLogoIcon height={40} width={120} color="#295387" />

        <CustomText variant="xxlPoppinsBold" style={{ marginTop: '5%' }}>
          {t('welcome')}
        </CustomText>
        <CustomText variant="xlPoppinsRegular" style={{ marginTop: '5%' }}>
          {`- ${t('awesomeDay')}`}
        </CustomText>
        <CustomButton
          backgroundColor="bg-secondary"
          style={{ marginTop: '10%' }}
          onClick={onPressLogin}
        >
          {t('signIn')}
        </CustomButton>
      </div>
      <CustomText
        variant="mdPoppinsRegular"
        textColor="text-textWhite"
        style={{ position: 'absolute', bottom: 20 }}
      >
        © 2024 Inphiz Technologies AB
      </CustomText>
    </div>
  )
}

export default Login
