import { APIConst } from '@inphiz/api'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import { useQuery } from '@tanstack/react-query'

interface EmployeesStateListParam {
  isSuccess: boolean
  items: {
    title: string
    handle: string
  }[]
}

export function useEmployeesStateList() {
  return useQuery<EmployeesStateListParam>({
    queryKey: [QueryKey.employeesStates],
    queryFn: async () => {
      const result = await APIFunctions.GET(`${APIConst.getEmployeeState}?take=2147483647`)
      return {
        ...result,
        items: result.items.map((i: { title: string, handle: string }) => {
          return { ...i, title: i.title ? i.title : 'Without work area' }
        }),
      }
    },
    staleTime: Number.POSITIVE_INFINITY,
  })
}
