import type { KeyboardEvent } from 'react'
import type { PermitsItem } from '../../packages/api-web'
import type { filterItem } from '../EquipmentList'
import { DownloadFile, QueryKey } from '@inphiz/api'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { queryClient } from '../../App'
import {
  BorderButton,
  CustomButton,
  CustomMenu,
  CustomPagination,
  CustomTable,
  CustomText,
  Spinner,
  TextWithIcon,
} from '../../component'
import { globalDateFormat, PermitStatus } from '../../helpers'
import { CloseIcon, RightIcon, SearchIcon } from '../../icons'
import {
  useDepartmentList,
  useGetPermitFile,
  useIssuerList,
  usePermitsList,
  useRolePositions,
} from '../../packages/api-web'

function DrivingLicense() {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const [filterData, setFilterData] = useState<filterItem[]>([
    {
      id: 1,
      header: t('positionType'),
      data: [],
      selectedData: null,
      isSearch: true,
    },
    {
      id: 2,
      header: t('department'),
      data: [],
      selectedData: null,
      isSearch: true,
    },
    {
      id: 3,
      header: t('issuer'),
      data: [],
      selectedData: null,
      isSearch: true,
    },
    {
      id: 4,
      header: t('status'),
      data: [
        { handle: 'Draft', title: 'Draft' },
        { handle: 'WaitingSignature', title: 'WaitingSignature' },
        { handle: 'Active', title: 'Active' },
        { handle: 'Denied', title: 'Denied' },
        { handle: 'Expired', title: 'Expired' },
      ],
      selectedData: null,
    },
  ])
  const [itemPerPage, setItemPage] = useState(20)
  const [skipItem, setSkipItem] = useState(0)
  const [searchManager, setSearchManager] = useState('')
  const [searchJob, setSearchJob] = useState('')
  const [searchTxt, setSearchTxt] = useState<string>('')
  const { data: departmentList } = useDepartmentList()
  const [selectedDownload, setSelectedDownload] = useState<PermitsItem>()
  const {
    isFetching: isDownload,
    data: fileData,
    refetch: refetchDownload,
  } = useGetPermitFile({ permitHandle: selectedDownload?.handle })
  const {
    data: issuerList,
    isFetching: isIssuerList,
    refetch: refetchIssuerList,
  } = useIssuerList({
    searchTxt: searchManager,
    itemPerPage: 0,
    skip: 0,
  })
  const {
    data: jobTitle,
    refetch: refetchJob,
    isFetching: isJobTitle,
  } = useRolePositions({
    itemPerPage: 0,
    skip: 0,
    searchTxt: searchJob,
  })
  const { isLoading, data, refetch } = usePermitsList({
    itemPerPage,
    skip: skipItem,
    searchTxt,
    filterData,
  })

  const tableHeaderList = [
    { title: t('name'), id: 1 },
    { title: t('positionType'), id: 2 },
    { title: t('department'), id: 3 },
    { title: t('issuer'), id: 4 },
    { title: t('periodOfValidity'), id: 5 },
    { title: t('status'), id: 6 },
    { title: '', id: 7 },
  ]

  useMemo(() => {
    const newFilter: any = [...filterData]
    newFilter[0].data = jobTitle?.items
    newFilter[1].data = departmentList?.items
    newFilter[2].data = issuerList?.items?.map(i => ({ ...i, title: i.displayName }))
    setFilterData(newFilter)
  }, [jobTitle, departmentList, issuerList])

  useEffect(() => {
    // Debounce logic
    const debounce = setTimeout(() => {
      if (searchJob.length >= 3) {
        refetchJob()
      }
      else if (searchJob.length == 0) {
        refetchJob()
      }
    }, 2000)

    return () => clearTimeout(debounce)
  }, [searchJob])

  useEffect(() => {
    // Debounce logic
    const debounce = setTimeout(() => {
      if (searchManager.length >= 3) {
        refetchIssuerList()
      }
      else if (searchManager.length == 0) {
        refetchIssuerList()
      }
    }, 1000)
    return () => clearTimeout(debounce)
  }, [searchManager])

  useEffect(() => {
    if (selectedDownload) {
      refetchDownload()
    }
  }, [selectedDownload])

  useEffect(() => {
    if (fileData?.isSuccess && fileData?.permitFileUrl) {
      DownloadFile(fileData?.permitFileUrl)
      queryClient.removeQueries({ queryKey: [QueryKey.getPermitFile, selectedDownload?.handle] })
      setSelectedDownload(undefined)
    }
  }, [fileData])

  const permitsData = useMemo(() => {
    const result = data?.items?.map((i) => {
      return {
        ...i,
        download: i?.status == PermitStatus.active,
        displayName: i?.holder?.displayName,
        profileImageHandle: i?.holder?.profileImageHandle,
        validity: `${globalDateFormat(i?.startDate ?? '')} - ${globalDateFormat(i?.endDate ?? '')}`,
        certificateStatus: Number.parseInt(`${i?.status}`),
      }
    })
    return result
  }, [data])

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      refetch()
    }
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div className="p-5 h-full flex flex-col">
      {isDownload && <Spinner />}
      <CustomText variant="xlPoppinsBold">{t('permits')}</CustomText>
      <div className="mt-6 px-5 py-3 bg-white rounded-lg flex items-center justify-between border border-stroke">
        <div className="flex items-center gap-3 flex-wrap">
          {filterData.map((item, index) => {
            return (
              <div className="flex items-center gap-3" key={index}>
                <CustomMenu
                  isSearchLoader={isIssuerList || isJobTitle}
                  menuList={item?.data ?? []}
                  selectedValue={item?.selectedData ?? null}
                  onClick={(value) => {
                    const updateItem = { ...item, selectedData: value?.handle }
                    const newFilter: any = [...filterData]
                    newFilter[index] = updateItem
                    setFilterData(newFilter)
                  }}
                  buttonComponent={(
                    <div className="flex">
                      <TextWithIcon
                        text={item?.header}
                        rightIcon={<RightIcon className="h-4 w-4 rotate-90 ml-4" />}
                        textVariant="smPoppinsRegular"
                      />
                      {item?.selectedData && (
                        <>
                          <div className="absolute -top-4 right-1 bg-primary h-4 w-4 rounded-full justify-center items-center flex">
                            <CustomText variant="xsmPoppinsRegular" textColor="text-textWhite">
                              1
                            </CustomText>
                          </div>
                          <button
                            onClick={() => {
                              const updateItem = { ...item, selectedData: null }
                              const newFilter: any = [...filterData]
                              newFilter[index] = updateItem
                              setFilterData(newFilter)
                            }}
                          >
                            <CloseIcon className="h-5" />
                          </button>
                        </>
                      )}
                    </div>
                  )}
                  {...(item?.isSearch && {
                    onSearch: (e) => {
                      if (item?.id == 1) {
                        setSearchJob(e)
                      }
                      else {
                        setSearchManager(e)
                      }
                    },
                  })}
                />
                <div className="h-8 bg-black/10 w-[1px]" />
              </div>
            )
          })}
        </div>
        <BorderButton
          children={t('filter')}
          className="px-7 self-end"
          onClick={() => {
            refetch()
          }}
        />
      </div>
      <div className="mt-6 py-6 px-5 bg-white rounded-lg border border-stroke flex flex-col overflow-hidden">
        <div className="flex flex-col sm:flex-row mb-4 gap-4">
          <CustomText className="mr-auto self-center" wrapText={false} variant="xlPoppinsBold">
            {`${t('total')}: ${data?.totalCount}`}
          </CustomText>
          <div className="flex flex-1 items-center justify-between">
            <div className="flex items-center w-auto gap-4">
              <SearchIcon />
              <input
                type="text"
                value={searchTxt}
                placeholder={t('searchPlaceholder')}
                className="outline-0 flex-1 min-w-[100px]"
                onChange={e => setSearchTxt(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              {searchTxt && (
                <button
                  onClick={() => {
                    setSearchTxt('')
                    setTimeout(() => {
                      refetch()
                    }, 1000)
                  }}
                >
                  <CloseIcon className="h-5" />
                </button>
              )}
            </div>
            <CustomButton
              children={t('addNewDriving')}
              textVariant="smPoppinsMedium"
              className="px-4 sm:px-8 bg-secondary"
              onClick={() => {
                navigate('/driving/permit')
              }}
            />
          </div>
        </div>
        <div className="flex flex-col overflow-hidden rounded-lg border border-stroke">
          <div className="flex-1 overflow-x-auto overflow-y-auto scrollbar bg-white">
            <CustomTable
              tableHeaderList={tableHeaderList}
              tableDataList={permitsData}
              displayKeys={[
                'displayName',
                'holder.jobTitle',
                'holder.department',
                'issuer.displayName',
                'validity',
                'certificateStatus',
                'download',
              ]}
              onClick={(item) => {
                navigate(`/driving/permit/${item.handle}`, {
                  state: { holderHandle: item?.holder?.handle },
                })
              }}
              onDownloadClick={(item) => {
                setSelectedDownload(item)
              }}
            />
          </div>
          {data && data?.items && data?.items?.length > 0 && (
            <CustomPagination
              totalItem={data?.totalCount}
              itemPerPage={itemPerPage}
              onChange={setItemPage}
              onPagePress={index => setSkipItem((index - 1) * itemPerPage)}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default DrivingLicense
