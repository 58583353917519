import { SaveEmergencyContactsApiRequest } from '@inphiz-shared/shared-models/src/SensitiveData/save-emergency-contacts-api-request'
import { queryClient } from '@inphiz/api/src'
import { useMutation } from '@tanstack/react-query'
import APIFunctions from '../Networking/APIFunctions'
import { APIConst } from '../Networking/APIServices'
import { QueryKey } from '../QueryKey'

const AddEmgContactsType = new SaveEmergencyContactsApiRequest()
export type AddEmgContactsParam = typeof AddEmgContactsType

function addEmgContact(variables: AddEmgContactsParam) {
  queryClient.setQueryData(
    [QueryKey.emergencyContacts],
    (emergencyContacts: AddEmgContactsParam) => {
      if (emergencyContacts === undefined)
        return

      return {
        ...emergencyContacts,
        emergencyContacts: {
          ...emergencyContacts.emergencyContacts,
          contacts: variables.emergencyContacts?.contacts,
        },
      }
    },
  )
}

export function addEmgContacts() {
  return useMutation({
    mutationFn: (param: AddEmgContactsParam) =>
      APIFunctions.PUT(APIConst.emergencyContacts.emergencyContacts, param),
    onSuccess: (data, variables: AddEmgContactsParam, _context) => {
      if (data.isSuccess)
        addEmgContact(variables)
    },
  })
}
