import type {
  DepartmentItem,
  JobTitleItem,
  OrganizationUnitItem,
  UserItem,
} from '../../packages/api-web'
import { UserMetadataFields } from '@inphiz-shared/shared-models/src/F_Users/user-metadata-fields'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  BorderButton,
  CustomButton,
  CustomDropDown,
  CustomInput,
  CustomText,
  Spinner,
} from '../../component'
import {
  useDepartmentList,
  useManagerFilterList,
  useOrganizationUnits,
  usePostEmployee,
  useRolePositions,
} from '../../packages/api-web'

function EmployeeCreate() {
  const navigate = useNavigate()
  const { t } = useTranslation('common')
  const [selectedOrganization, setSelectedOrganization] = useState<OrganizationUnitItem>()
  const [selectedDepartment, setSelectedDepartment] = useState<DepartmentItem>()
  const [searchManager, setSearchManager] = useState<string>('')
  const [searchJob, setSearchJob] = useState<string>('')
  const [selectedJob, setSelectedJob] = useState<JobTitleItem>()
  const [selectedManager, setSelectedManager] = useState<UserItem>()
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [userName, setUserName] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [mobile, setMobile] = useState<string>('')
  const [state, setState] = useState<string>('')
  const [city, setCity] = useState<string>('')
  const { isLoading: isLoadOrganization, data: organizationList } = useOrganizationUnits()
  const { isLoading: isDepartment, data: departmentList } = useDepartmentList()
  const {
    data: managerList,
    isFetching: isManagerList,
    refetch: refetchManagerList,
  } = useManagerFilterList({ filterTxt: searchManager })
  const { data: jobData, refetch: refetchJob } = useRolePositions({
    itemPerPage: 20,
    skip: 0,
    searchTxt: searchJob,
    organizationUnitHandle: selectedOrganization?.handle,
  })
  const { isPending, mutate: createEmployeeMutate } = usePostEmployee()

  const organizationData = useMemo(() => {
    const result = organizationList?.items
    return result
  }, [organizationList])

  const departmentData = useMemo(() => {
    const result = departmentList?.items
    return result
  }, [departmentList])

  const managerData = useMemo(() => {
    const result = managerList?.items
    return result
  }, [managerList])

  useEffect(() => {
    // Debounce logic
    const debounce = setTimeout(() => {
      if (searchManager.length >= 3) {
        refetchManagerList()
      }
      else if (searchManager.length == 0 && !selectedManager) {
        refetchManagerList()
      }
    }, 1000)

    return () => clearTimeout(debounce)
  }, [searchManager, selectedManager])

  useEffect(() => {
    // Debounce logic
    const debounce = setTimeout(() => {
      if (searchJob.length >= 3) {
        refetchJob()
      }
      else if (searchJob.length == 0) {
        refetchJob()
      }
    }, 1000)

    return () => clearTimeout(debounce)
  }, [searchJob])

  useEffect(() => {
    refetchJob()
  }, [selectedOrganization])

  if (isLoadOrganization || isDepartment) {
    return <Spinner />
  }

  return (
    <div className="p-5 h-full flex flex-col">
      {isPending && <Spinner />}
      <CustomText variant="xlPoppinsBold">{`${t('employee')} ${t('create')}`}</CustomText>
      <div className="flex flex-1 flex-col mt-6 px-5 bg-white rounded-lg border border-stroke h-[100%] overflow-hidden">
        <div className="flex-1 overflow-hidden overflow-y-auto scrollbar">
          <div className="border border-stroke rounded-lg mt-3">
            <div className="py-3 px-5 border-b border-b-stroke">
              <CustomText variant="mdPoppinsSemiBold">{t('create')}</CustomText>
            </div>
            <div className="flex-1 p-5 overflow-y-auto scrollbar">
              <div className="grid grid-cols-2 gap-3 mb-3">
                <CustomInput
                  title={t('firstName')}
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value)
                  }}
                />
                <CustomInput
                  title={t('lastName')}
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value)
                  }}
                />
              </div>
              <CustomInput
                title={t('userName')}
                value={userName}
                onChange={(e) => {
                  setUserName(e.target.value)
                }}
                className="mb-3"
              />
              <div className="grid grid-cols-2 gap-3 mb-3">
                <CustomInput
                  title={t('businessMobile')}
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value)
                  }}
                />
                <CustomInput
                  title={t('privateMobile')}
                  value={mobile}
                  onChange={(e) => {
                    setMobile(e.target.value)
                  }}
                />
              </div>
              <div className="grid grid-cols-2 gap-3 mb-3">
                <CustomDropDown
                  value={selectedOrganization?.handle}
                  className="!mb-3"
                  title={t('organization')}
                  data={organizationData ?? []}
                  labelField="title"
                  valueField="handle"
                  onSelect={(_, values) => {
                    setSelectedOrganization(values as OrganizationUnitItem)
                  }}
                />
                <CustomDropDown
                  value={selectedDepartment?.handle}
                  className="!mb-3"
                  title={t('department')}
                  data={departmentData ?? []}
                  labelField="title"
                  valueField="handle"
                  onSelect={(_, values) => {
                    setSelectedDepartment(values as DepartmentItem)
                  }}
                />
              </div>
              <div className="grid grid-cols-2 gap-3 mb-3">
                <CustomInput
                  title="State"
                  value={state}
                  onChange={(e) => {
                    setState(e.target.value)
                  }}
                />
                <CustomInput
                  title="City"
                  value={city}
                  onChange={(e) => {
                    setCity(e.target.value)
                  }}
                />
              </div>
              <div className="grid grid-cols-2 gap-3 mb-3">
                <CustomDropDown
                  disabled={!selectedOrganization}
                  value={selectedJob?.handle}
                  className="!mb-3"
                  title={t('jobTitle')}
                  data={jobData?.items ?? []}
                  labelField="title"
                  valueField="handle"
                  onSearch={(e) => {
                    setSearchJob(e)
                  }}
                  onSelect={(_, item) => {
                    setSelectedJob(item as JobTitleItem)
                  }}
                />
                <CustomDropDown
                  value={selectedManager?.handle}
                  loading={isManagerList}
                  className="!mb-3"
                  title={t('reportsTo')}
                  data={managerData ?? []}
                  labelField="displayName"
                  valueField="handle"
                  onSearch={(e) => {
                    setSearchManager(e)
                  }}
                  onSelect={(_, item) => {
                    setSelectedManager(item as UserItem)
                  }}
                  onClean={() => {
                    setSelectedManager(undefined)
                    setSearchManager('')
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap gap-4 justify-end items-center py-4">
          <div className="flex gap-4 flex-wrap">
            <BorderButton
              children={t('cancel')}
              className="!px-2 sm:!px-5 flex-1"
              textColor="text-textPrimary"
              onClick={() => navigate(-1)}
            />
            <CustomButton
              children={t('save')}
              className="flex-auto"
              backgroundColor="bg-secondary"
              textColor="text-textWhite"
              onClick={() => {
                const data = {
                  firstName,
                  lastName,
                  displayName: `${firstName} ${lastName}`,
                  email: userName,
                  phone,
                  mobile,
                  jobTitle: selectedJob?.title,
                  department: selectedDepartment?.handle,
                  country: state,
                  city,
                  managerHandle: selectedManager?.handle,
                  contentType: 'user',
                  userMetadata: [
                    {
                      key: UserMetadataFields.phone,
                      name: 'Telefon',
                      value: phone,
                      type: 'Phone',
                      order: 0,
                      isCustom: false,
                      isVisible: true,
                      isDeleted: false,
                      isEditable: false,
                      isDisabled: false,
                    },
                    {
                      key: UserMetadataFields.mobile,
                      name: 'Mobil',
                      value: mobile,
                      type: 'Phone',
                      order: 0,
                      isCustom: false,
                      isVisible: true,
                      isDeleted: false,
                      isEditable: false,
                      isDisabled: false,
                    },
                    {
                      key: UserMetadataFields.department,
                      name: 'Avdelning',
                      value: selectedDepartment?.handle,
                      type: 'Text',
                      order: 998,
                      isCustom: false,
                      isVisible: true,
                      isDeleted: false,
                      isEditable: false,
                      group: 'Contacts',
                      groupTitle: 'KONTAKTUPPGIFTER',
                      isDisabled: false,
                    },
                    {
                      key: UserMetadataFields.state,
                      name: 'Region',
                      value: state,
                      type: 'Custom',
                      order: 0,
                      isCustom: false,
                      isVisible: true,
                      isDeleted: false,
                      isEditable: false,
                      isDisabled: false,
                    },
                    {
                      key: UserMetadataFields.email,
                      name: 'Användarnamn',
                      value: userName,
                      type: 'Text',
                      order: 1000,
                      isCustom: false,
                      isVisible: true,
                      isDeleted: false,
                      isEditable: false,
                      group: 'Contacts',
                      groupTitle: 'KONTAKTUPPGIFTER',
                      isDisabled: false,
                    },
                    {
                      key: UserMetadataFields.city,
                      name: 'Ort',
                      value: city,
                      type: 'Custom',
                      order: 0,
                      isCustom: false,
                      isVisible: true,
                      isDeleted: false,
                      isEditable: false,
                      isDisabled: false,
                    },
                    {
                      key: UserMetadataFields.officeLocation,
                      name: 'Bolag',
                      value: selectedOrganization?.title,
                      type: 'Text',
                      order: 999,
                      isCustom: false,
                      isVisible: true,
                      isDeleted: false,
                      isEditable: false,
                      group: 'Contacts',
                      groupTitle: 'KONTAKTUPPGIFTER',
                      isDisabled: false,
                    },
                    {
                      key: UserMetadataFields.managerId,
                      name: '',
                      value: selectedManager?.handle,
                      type: 'Custom',
                      order: 995,
                      isCustom: false,
                      isVisible: false,
                      isDeleted: false,
                      isEditable: false,
                      isDisabled: false,
                    },
                    {
                      key: UserMetadataFields.managerEmail,
                      name: '',
                      value: selectedManager?.email,
                      type: 'Custom',
                      order: 994,
                      isCustom: false,
                      isVisible: false,
                      isDeleted: false,
                      isEditable: false,
                      isDisabled: false,
                    },
                    {
                      key: UserMetadataFields.manager,
                      name: 'Chef',
                      value: selectedManager?.displayName,
                      type: 'Custom',
                      order: 996,
                      isCustom: false,
                      isVisible: true,
                      isDeleted: false,
                      isEditable: false,
                      group: 'Contacts',
                      groupTitle: 'KONTAKTUPPGIFTER',
                      isDisabled: false,
                    },
                  ],
                }
                createEmployeeMutate(data, {
                  onSuccess: (data) => {
                    if (data?.isSuccess) {
                      navigate(-1)
                    }
                  },
                })
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmployeeCreate
