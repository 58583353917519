import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { BorderButton, CustomPagination, CustomTable, CustomText, Spinner } from '../../component'
import { useDashboardNewsList } from '../../packages/api-web/DashboardNews'

function DashboardNewsList() {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const [itemPerPage, setItemPage] = useState(20)
  const [skipItem, setSkipItem] = useState(0)
  const { isLoading, data, refetch } = useDashboardNewsList({
    itemPerPage,
    skip: skipItem,
  })

  const tableHeaderList = useMemo(
    () => [
      { title: t('tableTitle'), id: 1, key: 'title' },
      { title: t('category'), id: 2, key: 'groups[0].title' },
      { title: t('likesEnabled'), id: 3, key: 'metadata.IsLikesEnabled' },
      { title: t('commentsEnabled'), id: 4, key: 'metadata.IsCommentsEnabled' },
      { title: t('author'), id: 5, key: 'authorName' },
      { title: t('status'), id: 6, key: 'status' },
      { title: t('createdDate'), id: 7, key: 'createdTime' },
      { title: t('publishDate'), id: 8, key: 'publishTime' },
    ],
    [t],
  )

  useEffect(() => {
    refetch()
  }, [skipItem, itemPerPage])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div className="p-5 h-full flex flex-col">
      <div className="flex items-center justify-between">
        <CustomText variant="xlPoppinsBold">{t('newsList')}</CustomText>
        <BorderButton
          children={`+ ${t('createNew')}`}
          className="px-7 self-end"
          onClick={() => {
            navigate('/dashboard/create/news_item')
          }}
        />
      </div>
      <div className="mt-6 py-6 px-5 bg-white rounded-lg border border-stroke flex flex-col overflow-hidden">
        <div className="flex flex-col sm:flex-row mb-4 gap-4">
          <CustomText className="mr-auto self-center" wrapText={false} variant="xlPoppinsBold">
            {`${t('total')}: ${data?.totalCount}`}
          </CustomText>
        </div>
        <div className="flex flex-col overflow-hidden rounded-lg border border-stroke">
          <div className="flex-1 overflow-x-auto overflow-y-auto scrollbar bg-white">
            <CustomTable
              tableHeaderList={tableHeaderList}
              tableDataList={data?.items ?? []}
              displayKeys={[
                'title',
                'groups',
                'metadata.IsLikesEnabled',
                'metadata.IsCommentsEnabled',
                'authorName',
                'status',
                'createdTime',
                'publishTime',
              ]}
              keyMapping={{ groups: ['title'] }}
              onClick={item => navigate(`/news/${item.handle}`)}
            />
          </div>
          <CustomPagination
            totalItem={data?.totalCount}
            itemPerPage={itemPerPage}
            onChange={(value) => {
              setItemPage(value)
            }}
            onPagePress={(index) => {
              const skipItem = (index - 1) * itemPerPage
              setSkipItem(skipItem)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default DashboardNewsList
