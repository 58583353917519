import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { initDB } from '../../../database'
import { CustomHeader, CustomNotificationSideMenu, CustomSlideMenu } from '../../atoms'

export function MainTemplate() {
  const [sidebarToggle, setSidebarToggle] = useState(true)
  const [rightSidebarToggle, setRightSidebarToggle] = useState(false)

  useEffect(() => {
    initDataBase()
  }, [])

  const initDataBase = async () => {
    await initDB()
  }

  return (
    <div className="w-full h-screen bg-bgColor">
      <CustomHeader
        setSidebarToggle={setSidebarToggle}
        sidebarToggle={sidebarToggle}
        setRightSidebarToggle={setRightSidebarToggle}
      />
      <CustomSlideMenu onClick={() => setSidebarToggle(true)} sidebarToggle={sidebarToggle} />
      <div
        className={`fixed right-0 left-0 bottom-0 flex-1 ${sidebarToggle ? 'xl:ml-64' : 'xl:ml-0'} ${sidebarToggle && 'translate-x-0 bg-bgColor'} ease-in-out duration-500 top-[62.5px]`}
      >
        <Outlet />
      </div>
      <CustomNotificationSideMenu
        onClick={() => setRightSidebarToggle(false)}
        rightSidebarToggle={rightSidebarToggle}
      />
    </div>
  )
}
