import type { Comments, News, PostCommentParams } from '../../packages/api-web'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import {
  AddComment,
  CommentItem,
  CustomText,
  DeleteAlert,
  Divider,
  Spinner,
  TextWithIcon,
  UserLikeListModal,
} from '../../component'
import { UserContext } from '../../contexts/UserContext'
import { ContentType, dateFormat } from '../../helpers'
import { Comment, UnLike } from '../../icons'
import {
  useDeleteLikeMutation,
  useGetCommentByHandle,
  useNewsDetailsQuery,
  usePostCommentMutation,
  usePostLikeMutation,
} from '../../packages/api-web'
import { useDeleteComment } from '../../packages/api-web/Social/useDeleteComment'

interface NewDetailsProps {
  newsHandle?: string
  typeHandle?: string
}

interface Images {
  uri: string
  type: string | null
  name: string | null
  createdTime?: string
}

function NewDetails({ newsHandle, typeHandle }: NewDetailsProps) {
  const { t } = useTranslation('common')
  const { state } = useLocation()
  const { user } = useContext(UserContext)
  const { isFetching, data, refetch } = useNewsDetailsQuery(
    state?.newsHandle ? state?.newsHandle : newsHandle,
  )
  const { data: commentList } = useGetCommentByHandle(
    state?.newsHandle ? state?.newsHandle : newsHandle,
  )
  const { mutate: deleteLikePost } = useDeleteLikeMutation(
    state?.typeHandle ? state?.typeHandle : typeHandle,
  )
  const { mutate: likePost } = usePostLikeMutation(
    state?.typeHandle ? state?.typeHandle : typeHandle,
  )
  const { mutate: deleteCommentMutate } = useDeleteComment(
    state?.typeHandle ? state?.typeHandle : typeHandle,
  )
  const {
    mutate: postComment,
    isPending,
    isSuccess,
  } = usePostCommentMutation(state?.typeHandle ? state?.typeHandle : typeHandle)
  const [newDetails, setNewDetails] = useState<News | null>(null)
  const [tempReplyComment, setTempReplyComment] = useState<Comments>()
  const [tempCommentTxt, setTempCommentTxt] = useState('')
  const [replyComment, setReplyComment] = useState<Comments>()
  const [tempImages, setTempImages] = useState<Images[]>([])
  const [isShowAlert, setIsShowAlert] = useState<boolean>(false)
  const messagesEndRef = useRef<null | HTMLDivElement>(null)
  const [deleteComment, setDeleteComment] = useState<Comments>()
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (newsHandle || state?.newsHandle) {
      refetch()
    }
  }, [newsHandle, state?.newsHandle])

  useEffect(() => {
    if (data) {
      setNewDetails(data)
    }
  }, [data])

  useEffect(() => {
    if (isSuccess) {
      setTempCommentTxt('')
      setTempReplyComment(undefined)
      setTempImages([])
    }
    if (isPending) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }, [isPending, isSuccess])

  const onClickLike = () => {
    if (newDetails?.metadata?.IsLikedByUser == 'True') {
      const data = {
        ownerHandle: newsHandle ?? '',
        contentType: ContentType.like,
        ownerContentType: ContentType.news,
        authorHandle: '@me',
        userHandle: user.userData.handle,
      }
      deleteLikePost(data)
      return
    }
    const data = {
      ownerHandle: newsHandle ?? '',
      ownerContentType: ContentType.news,
      authorHandle: '@me',
      contentType: ContentType.like,
    }
    likePost(data)
  }

  const onSend = (message: string) => {
    const data: PostCommentParams = {
      content: message,
      ownerHandle: state?.newsHandle ? state?.newsHandle : newsHandle,
      ownerContentType: ContentType.news,
      authorHandle: '@me',
      parentCommentHandle: replyComment?.handle || undefined,
    }
    postComment(data)
    setTempCommentTxt(message)
    setReplyComment(undefined)
  }

  if (isFetching && !newDetails)
    return <Spinner />

  return (
    <div className="h-full flex">
      <div className="relative flex flex-1 flex-col bg-white">
        <div className="flex flex-1 flex-col overflow-y-auto scrollbar">
          <div className="border border-stroke">
            {newDetails && newDetails?.coverMedia?.length > 0 && (
              <img className="bg-center new-img" src={newDetails?.coverMedia[0]?.url} />
            )}
            <div className="flex-col">
              <div className="px-12 py-6">
                <div className="flex items-center justify-between">
                  <CustomText variant="smPoppinsRegular">
                    {dateFormat(newDetails?.publishTime ?? '')}
                  </CustomText>
                  <CustomText variant="smPoppinsRegular">
                    {newDetails?.groups[0]?.title ?? ''}
                  </CustomText>
                </div>
                <div className="pt-2">
                  <CustomText className="line-clamp-2" variant="xxlPoppinsSemiBold">
                    {newDetails?.title ?? ''}
                  </CustomText>
                </div>
                <div className="pt-2">
                  {newDetails && newDetails.contentFormat == 'Html' ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: newDetails?.content ?? '' }}
                      style={{ fontFamily: 'Poppins-Regular' }}
                    />
                  ) : (
                    <CustomText variant="smPoppinsRegular">{newDetails?.content ?? ''}</CustomText>
                  )}
                </div>
              </div>
              {newDetails?.metadata.IsLikesEnabled === 'True'
              && newDetails?.metadata.IsCommentsEnabled === 'True' && (
                <>
                  <div className="px-12 flex justify-between mb-3">
                    <CustomText
                      onClick={() => {
                        if (newDetails?.likeCount)
                          setIsVisible(true)
                      }}
                      variant="smPoppinsRegular"
                    >
                      {`${newDetails?.likeCount ?? 0} ${t('like')}`}
                    </CustomText>
                    <CustomText variant="smPoppinsRegular">{`${newDetails?.commentCount ?? 0} ${t('comments')}`}</CustomText>
                  </div>
                  <Divider />
                  <div className="flex h-10">
                    <div className="flex flex-1 justify-center items-center border-r border-r-stroke">
                      <button onClick={onClickLike}>
                        <TextWithIcon
                          leftIcon={(
                            <UnLike
                              className={`mr-2 ${newDetails?.metadata?.IsLikedByUser === `True` ? 'fill-secondary' : 'fill-white'}`}
                            />
                          )}
                          text={t('likeU')}
                          textVariant="smPoppinsRegular"
                        />
                      </button>
                    </div>
                    <div className="flex flex-1 justify-center items-center">
                      <TextWithIcon
                        leftIcon={<Comment className="mr-2" />}
                        text={t('comment')}
                        textVariant="smPoppinsRegular"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          {newDetails?.metadata.IsCommentsEnabled === 'True' && (
            <div className="border-x border-x-stroke" ref={messagesEndRef}>
              {newDetails
              && commentList
              && commentList?.items
                .slice()
                .reverse()
                ?.map((comment, index) => {
                  return (
                    <CommentItem
                      key={index}
                      item={comment}
                      isPending={isPending}
                      pendingItem={{
                        handle: tempReplyComment ? tempReplyComment.handle : '',
                        isChildData: !!tempReplyComment,
                        content: tempCommentTxt,
                        createdTime: new Date(),
                        author: {
                          displayName: user?.userData?.displayName,
                          profileImage: user?.userData?.profileImage,
                          handle: user?.userData?.handle,
                        },
                        metadata: {
                          IsLikedByUser: 'False',
                        },
                        likeCount: 0,
                        childs: [],
                      }}
                      onLike={(item: Comments) => {
                        if (item?.metadata?.IsLikedByUser == 'True') {
                          const data = {
                            ownerHandle: item?.handle ?? '',
                            contentType: ContentType.like,
                            ownerContentType: ContentType.comment,
                            authorHandle: '@me',
                            userHandle: user.userData.handle,
                            itemHandle: newsHandle,
                            parentCommentHandle: item?.parentCommentHandle
                              ? item?.parentCommentHandle
                              : undefined,
                          }
                          deleteLikePost(data)
                        }
                        else {
                          const data = {
                            ownerHandle: item?.handle ?? '',
                            contentType: ContentType.like,
                            ownerContentType: ContentType.comment,
                            authorHandle: '@me',
                            itemHandle: newsHandle,
                            parentCommentHandle: item?.parentCommentHandle
                              ? item?.parentCommentHandle
                              : undefined,
                          }
                          likePost(data)
                        }
                      }}
                      onChildComment={(item: Comments) => {
                        setReplyComment(item)
                        setTempReplyComment(item)
                      }}
                      onMore={(item: Comments) => {
                        setDeleteComment(item)
                        setIsShowAlert(true)
                      }}
                    />
                  )
                })}
              {isPending && !tempReplyComment && (
                <div style={{ opacity: 0.5 }}>
                  <CommentItem
                    item={{
                      content: tempCommentTxt,
                      createdTime: new Date(),
                      author: {
                        displayName: user?.userData?.displayName,
                        profileImage: user?.userData?.profileImage,
                        handle: user?.userData?.handle,
                      },
                      metadata: {
                        IsLikedByUser: 'False',
                      },
                      likeCount: 0,
                      childs: [],
                      coverMedia: tempImages.length > 0 ? [{ url: tempImages[0]?.uri }] : [],
                    }}
                  />
                </div>
              )}
              {isShowAlert && (
                <DeleteAlert
                  title={t('deleteComment')}
                  subTitle={t('deleteCommentDetails')}
                  leftButtonText={t('cancel')}
                  rightButtonText="Detete"
                  onCloseAlert={() => {
                    setIsShowAlert(false)
                    setDeleteComment(undefined)
                  }}
                  onLeftButton={() => {
                    setDeleteComment(undefined)
                  }}
                  onRightButton={() => {
                    if (deleteComment) {
                      const data = {
                        ownerHandle: state?.newsHandle ? state?.newsHandle : newsHandle,
                        contentType: ContentType.comment,
                        ownerContentType: ContentType.news,
                        authorHandle: '@me',
                        handle: deleteComment?.handle,
                        parentCommentHandle: deleteComment?.parentCommentHandle
                          ? deleteComment?.parentCommentHandle
                          : undefined,
                      }
                      deleteCommentMutate(data)
                    }
                    setTimeout(() => {
                      setIsShowAlert(false)
                    }, 250)
                  }}
                />
              )}
            </div>
          )}
          <div className="flex-1 border-x border-x-stroke" />
        </div>
        {newDetails?.metadata.IsCommentsEnabled === 'True' && (
          <div className="py-2 border border-stroke items-center justify-center flex px-5 flex-col">
            {replyComment && (
              <div className="mb-2 pb-2 w-full flex items-center border-b border-b-stroke">
                <CustomText variant="smPoppinsRegular">Replying to </CustomText>
                <CustomText variant="smPoppinsBold" className="flex-1">
                  {`${replyComment?.author?.displayName}`}
                </CustomText>
                <CustomText
                  variant="smPoppinsBold"
                  onClick={() => {
                    setReplyComment(undefined)
                  }}
                >
                  ✕
                </CustomText>
              </div>
            )}
            <AddComment
              onTapPublish={(message) => {
                onSend(message)
              }}
            />
          </div>
        )}
      </div>
      <UserLikeListModal
        handle={newDetails?.handle ?? ''}
        likeCount={newDetails?.likeCount ?? 0}
        isVisible={isVisible}
        onClose={() => {
          setIsVisible(false)
        }}
      />
    </div>
  )
}

export default NewDetails
