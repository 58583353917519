import { WallItemModelDto } from '@inphiz-shared/shared-models/src/Dto/wall-item-model-dto'
import { SocialActionDto } from '@inphiz-shared/shared-models/src/SocialActions/social-action-dto'
import { APIConst } from '@inphiz/api'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import { useQuery } from '@tanstack/react-query'

const SocialDetailsType = new WallItemModelDto()
export type SocialDetails = typeof SocialDetailsType

const SocialActionType = new SocialActionDto()
export type SocialAction = typeof SocialActionType

export function useSocialDetails(socialHandle: string) {
  return useQuery({
    queryKey: [QueryKey.socialDetails, socialHandle],
    queryFn: async () => {
      const result = await APIFunctions.GET(
        `${APIConst.socials.getSocialDetails}?Handle=${socialHandle}`,
      )
      if (result?.isSuccess) {
        return result?.item as SocialDetails
      }
      else {
        return undefined
      }
    },
    enabled: false,
  })
}
