import type { DatePickerProps } from 'rsuite'
import { DatePicker } from 'rsuite'
import { CustomText } from '../CustomText'
import 'rsuite/dist/rsuite.min.css'

interface CustomDatePickerProps extends DatePickerProps {
  title?: string
  minDate?: Date | null
  className?: string
}

function CustomDatePicker({ title, minDate, className, ...props }: CustomDatePickerProps) {
  const effectiveMinDate = minDate ? new Date(minDate.setHours(0, 0, 0, 0)) : undefined

  const disableBeforeMinDate = (date?: Date) => {
    if (!date || !effectiveMinDate)
      return false
    return date < effectiveMinDate
  }

  return (
    <div className="flex flex-col cm-date-picker">
      {title && <CustomText variant="mdPoppinsSemiBold">{title}</CustomText>}
      <DatePicker
        block
        {...(minDate ? { disabledDate: disableBeforeMinDate } : {})}
        className={`mt-[5px] ${className}`}
        placeholder="Select Date"
        format="dd-MM-yyyy"
        placement="auto"
        cleanable={false}
        {...props}
      />
    </div>
  )
}

export default CustomDatePicker
