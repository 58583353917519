import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { usePostArchive } from '../../../packages/api-web/Employee'
import {
  BorderButton,
  CustomAlert,
  CustomButton,
  CustomText,
  CustomTextArea,
  Spinner,
} from '../../atoms'

interface Params {
  userHandle?: string
  onCloseAlert?: () => void
}

export function ArchiveUserModal({ userHandle, onCloseAlert }: Params) {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const [description, setDescription] = useState('')
  const { isPending, mutate } = usePostArchive()

  return (
    <CustomAlert
      onClose={() => {
        onCloseAlert?.()
      }}
    >
      {isPending && <Spinner />}
      <CustomText variant="mdPoppinsSemiBold">{t('archiveUser')}</CustomText>
      <div className="text-left py-5">
        <CustomTextArea
          title={t('describeReason')}
          placeholder={t('reason')}
          aria-multiline
          value={description}
          onChange={(e) => {
            setDescription(e.target.value)
          }}
          maxLength={255}
        />
        <CustomText variant="xsmPoppinsRegular" className=" mt-3 text-right">
          {`${description?.length}/255`}
        </CustomText>
      </div>
      <div className="flex py-4 justify-end border-t border-stroke mt-5">
        <div className="flex gap-4 flex-wrap justify-end">
          <BorderButton
            children={t('cancel')}
            className="!px-2 flex-1"
            textColor="text-textPrimary"
            onClick={() => {
              onCloseAlert?.()
            }}
          />
          <CustomButton
            children={t('archive')}
            className="!px-6 flex-1"
            backgroundColor="bg-secondary"
            textColor="text-textWhite"
            onClick={() => {
              const data = {
                handle: userHandle,
                reason: description,
                isRecoveryMode: false,
              }
              mutate(data, {
                onSuccess: () => {
                  onCloseAlert?.()
                  navigate(-1)
                },
              })
            }}
          />
        </div>
      </div>
    </CustomAlert>
  )
}
