import type { FC, SetStateAction } from 'react'
import { UserDto } from '@inphiz-shared/shared-models/src/Dto/user-dto'
import { createContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLoginMutation } from '../packages/api-web'

const UserDataDto = new UserDto()
type UserDataType = typeof UserDataDto

export interface UserData {
  readonly userData: UserDataType
}

// accessToken isn't a part of user session data, it can be changed withot changing the user session, e.g. when the token expires and can be renewed
export const INITIAL_USER_DATA = {
  userData: {
    handle: '',
    displayName: '',
  },
} as UserData

// export class AppAuthState {
//   Mode: [Public | SignedIn | Demo] = ['Public']
// }

interface UserContextType {
  user: UserData
  setUser: React.Dispatch<SetStateAction<UserData>>
  firstLoad: boolean
  setFirstLoad: React.Dispatch<SetStateAction<boolean>>
}

export const UserContext = createContext<UserContextType>({
  user: INITIAL_USER_DATA,
  setUser: () => {},
  firstLoad: true,
  setFirstLoad: () => {},
})

interface UserProviderPropsWeb {
  readonly children: React.ReactElement
}

export const UserProvider: FC<UserProviderPropsWeb> = ({ children }) => {
  const [user, setUser] = useState<UserData>(INITIAL_USER_DATA)
  const [firstLoad, setFirstLoad] = useState(true)
  const { mutateAsync } = useLoginMutation()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const retrieveUserSession = async () => {
      try {
        const response = await mutateAsync(true)
        if (response.isSuccess) {
          setUser({ userData: response.user })
          return
        }

        setUser(INITIAL_USER_DATA)
        navigate('/', { replace: true })
      }
      catch (error) {
        console.error(error)
      }
    }
    retrieveUserSession()
  }, [location.pathname])

  return (
    <UserContext.Provider value={{ setUser, user, firstLoad, setFirstLoad }}>
      {children}
    </UserContext.Provider>
  )
}
