import type { Group, News } from '../../../packages/api-web'
import FlatList from 'flatlist-react'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import defaultTheme from 'tailwindcss/defaultTheme' // replaced from require('tailwindcss/defaultTheme')
import { useNewsQuery } from '../../../packages/api-web'
import { CustomText, Spinner } from '../../atoms'
import { NewItem } from '../../organisms'

interface NewListProps {
  selectedItem?: Group
  selectedNewItem?: News
  setSelectedNewItem: React.Dispatch<React.SetStateAction<News | undefined>>
}

export function NewList({ selectedItem, selectedNewItem, setSelectedNewItem }: NewListProps) {
  const navigate = useNavigate()
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const { data, hasNextPage, fetchNextPage, isLoading } = useNewsQuery({
    selectedNewsGroupHandle: selectedItem?.handle,
  })

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const newList = useMemo(() => {
    const result = data?.pages?.flatMap(i => i.items)
    return result as News[]
  }, [data])

  useEffect(() => {
    if (selectedNewItem == undefined && newList?.length > 0) {
      setSelectedNewItem(newList[0])
    }
  }, [newList])

  const renderItem = (item: News, idx: string) => {
    return (
      <li
        key={idx}
        className="pb-2 cursor-pointer"
        onClick={() => {
          if (screenWidth < Number.parseInt(defaultTheme.screens.lg.replace('px', ''), 10)) {
            navigate('/newdetails', {
              state: { newsHandle: item?.handle, typeHandle: selectedItem?.handle },
            })
          }
          else {
            setSelectedNewItem(item)
          }
        }}
      >
        <NewItem item={item} />
      </li>
    )
  }

  const blank = () =>
    isLoading
      ? (
          <Spinner />
        )
      : (
          <div className="absolute top-56 bottom-4 right-4 left-4 z-10 flex flex-col flex-1 justify-center items-center">
            <CustomText>Data is empty</CustomText>
          </div>
        )

  return (
    <ul>
      <FlatList
        list={newList}
        renderItem={renderItem}
        hasMoreItems={hasNextPage}
        loadMoreItems={fetchNextPage}
        renderWhenEmpty={blank}
      />
    </ul>
  )
}
