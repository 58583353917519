import type { ItemResponse } from '../../../packages/api-web'
import { APIConst } from '@inphiz/api'
import FlatList from 'flatlist-react/lib'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseIcon } from '../../../icons'
import { usePostReadingUserList } from '../../../packages/api-web'
import { CustomImg, CustomText, Divider, Spinner } from '../../atoms'

interface ReadUnreadListProps {
  isVisible: boolean
  onClose: () => void
  handle: string
}

export function ReadUnreadListModal({ isVisible, handle, onClose }: ReadUnreadListProps) {
  const { t } = useTranslation('common')
  const [showModal, setShowModal] = useState(isVisible)
  const [isApproved, setIsApproved] = useState(true)
  const { data, hasNextPage, fetchNextPage, isFetching, refetch } = usePostReadingUserList({
    isReading: isApproved,
    postHandle: handle,
  })

  useEffect(() => {
    refetch()
  }, [isApproved])

  const readingUserList = useMemo(() => {
    const result = data?.pages?.flatMap(i => i.items)
    return result as ItemResponse[]
  }, [data])

  useEffect(() => {
    if (isVisible) {
      setShowModal(true)
    }
    else {
      setTimeout(() => setShowModal(false), 300)
    }
  }, [isVisible])

  const renderItem = (item: ItemResponse, idx: string) => {
    return (
      <li key={idx}>
        <div key={item.handle} className="flex items-center pl-4 mb-4">
          <CustomImg
            name={item?.user.displayName}
            imgSrc={
              item?.user?.profileImageHandle
                ? `${APIConst.baseURL}/${APIConst.mediaUpload}/${item?.user?.profileImageHandle}`
                : ''
            }
            altText="user_image"
            className="h-[50px] w-[50px] rounded-full"
            imgClassName="rounded-full"
          />
          <CustomText variant="smPoppinsRegular" className="ml-2">
            {item?.user.displayName ?? ''}
          </CustomText>
        </div>
      </li>
    )
  }

  if (!showModal)
    return null

  return (
    <div
      className={`fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center bg-stroke-60 transition-opacity ${isVisible ? 'opacity-100' : 'opacity-0'} duration-300`}
      style={{ zIndex: 999 }}
    >
      <div
        className={`bg-white border-stroke border z-50 rounded-md transform transition-transform ${isVisible ? 'scale-100' : 'scale-90 duration-300'} duration-300 w-[400px]`}
      >
        <div className="flex items-center p-4 justify-between">
          <div></div>
          <button
            onClick={(e) => {
              e.stopPropagation()
              onClose()
            }}
          >
            <CloseIcon />
          </button>
        </div>
        <Divider />
        <div className="flex flex-1 flex-col h-[400px] overflow-hidden">
          <div className="flex justify-between">
            <button
              onClick={() => setIsApproved(true)}
              className={`w-1/2 ${isApproved ? 'border-b-2 border-b-secondary' : 'border-b border-b-stroke'} text-center py-2`}
            >
              <CustomText>{t('approved')}</CustomText>
            </button>
            <button
              onClick={() => setIsApproved(false)}
              className={`w-1/2 ${!isApproved ? 'border-b-2 border-b-secondary' : 'border-b border-b-stroke'} text-center py-2`}
            >
              <CustomText>{t('notApproved')}</CustomText>
            </button>
          </div>
          <div className="overflow-y-auto scrollbar pt-3">
            {isFetching ? (
              <Spinner />
            ) : (
              <ul>
                <FlatList
                  list={readingUserList}
                  renderItem={renderItem}
                  hasMoreItems={hasNextPage}
                  loadMoreItems={fetchNextPage}
                />
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
