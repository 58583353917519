import { APIConst } from '@inphiz/api'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import { QueryBuilder } from '@inphiz/core-utilities'
import { useQuery } from '@tanstack/react-query'

interface Response {
  permitFileUrl?: string
  isSuccess?: boolean
}

interface Params {
  permitHandle?: string
}

export function useGetPermitFile({ permitHandle }: Params) {
  return useQuery<Response>({
    queryKey: [QueryKey.getPermitFile, permitHandle],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.permits.getPermitsFile).add('PermitHandle', permitHandle)
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
    enabled: false,
  })
}
