import type { KeyboardEvent } from 'react'
import type { UploadResponse } from '../../packages/api-web'
import type { JobRequirementItem, JobTitleItem } from '../../packages/api-web/RoleManagement'
import type { filterItem } from '../RoleSpecializationsList'
import { QueryKey } from '@inphiz/api'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { queryClient } from '../../App'
import {
  BorderButton,
  CustomButton,
  CustomDropDown,
  CustomMenu,
  CustomNumberInput,
  CustomPagination,
  CustomTable,
  CustomText,
  CustomTextArea,
  DeleteAlert,
  ImagePicker,
  JobCompanyAlert,
  JobTitleAddAlert,
  Spinner,
  TextWithIcon,
} from '../../component'
import { CloseIcon, RightIcon, SearchIcon } from '../../icons'
import { useJobTitleList, useUploadMedia } from '../../packages/api-web'
import {
  useDeleteJobRequirement,
  useJobRoleDetails,
  useJobRoleRequirements,
  useJobType,
  usePostJobRequirement,
  usePostJobTitleRelationsInfo,
  usePutJobTitle,
} from '../../packages/api-web/RoleManagement'

function RoleSpecializationEdit() {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { id } = useParams()
  const [searchTxt, setSearchTxt] = useState('')
  const [itemPerPage, setItemPage] = useState(20)
  const [skipItem, setSkipItem] = useState(0)
  const [description, setDescription] = useState<string>('')
  const [icon, setIcon] = useState<string>('')
  const [coverImage, setCoverImage] = useState<string>('')
  const [isShowAlert, setIsShowAlert] = useState<boolean>(false)
  const [isShowProceed, setIsShowProceed] = useState<boolean>(false)
  const [isClearImage, setIsClearImage] = useState<boolean>(false)
  const [isClearCoverImage, setIsClearCoverImage] = useState<boolean>(false)
  const [isJobTitleAddAlert, setIsJobTitleAddAlert] = useState<boolean>(false)
  const [isShowRequirementsProceed, setIsShowRequirementsProceed] = useState<boolean>(false)
  const [updateJobTitleObj, setUpdateJobTitleObj] = useState<JobTitleItem>({})
  const [updateJobRequirementObj, setUpdateJobRequirementObj] = useState<JobRequirementItem[]>([])
  const [assignList, setAssignList] = useState<{ title: string, id: number }[]>([
    {
      title: t('noAutomaticOffer'),
      id: 0,
    },
    {
      title: t('managerOffers'),
      id: 1,
    },
    {
      title: t('automaticallyOffer'),
      id: 2,
    },
    {
      title: t('assignAutomatically'),
      id: 3,
    },
  ])
  const [selectedOption, setSelectedOption] = useState<{ title: string, id: number }>({
    title: t('noAutomaticOffer'),
    id: 0,
  })
  const [experienceTypeList, setExperienceTypeList] = useState<{ title: string, id: number }[]>([
    {
      title: t('daily'),
      id: 1,
    },
    {
      title: t('weekly'),
      id: 2,
    },
    {
      title: t('monthly'),
      id: 3,
    },
  ])
  const [selectedExperienceType, setSelectedExperienceType] = useState<{
    title: string
    id: number
  }>({
    title: t('daily'),
    id: 1,
  })
  const [experienceValue, setExperienceValue] = useState<number>(1)
  const [selectedJobTitles, setSelectedJobTitles] = useState<JobTitleItem[]>([])
  const { isLoading, data: jobRoleDetails } = useJobRoleDetails({
    handle: id ?? '',
  })
  const { isLoading: isJobTypeLoad, data: jobRequirement } = useJobType()
  const { data: jobTitleData, isLoading: isJobTitleLoad } = useJobTitleList()
  const { mutate, isPending } = usePostJobTitleRelationsInfo({ isJobTitle: false })
  const { mutate: updateMutate, isPending: updateIsPending } = usePutJobTitle({ isJobTitle: false })
  const { mutate: updateJobRequirementMutate, isPending: updateJobRequirementIsPending }
    = usePostJobRequirement({
      handle: id ?? '',
      isJobTitle: false,
    })
  const { mutate: deleteJobRequirementMutate, isPending: deleteJobRequirementIsPending }
    = useDeleteJobRequirement({
      handle: id ?? '',
      isJobTitle: false,
    })
  const { mutate: uploadMediaMute } = useUploadMedia()

  const [filterData, setFilterData] = useState<filterItem[]>([
    {
      id: 1,
      header: 'type',
      data: [],
      selectedData: null,
    },
    {
      id: 2,
      header: 'category',
      data: [],
      selectedData: null,
    },
  ])
  const {
    isLoading: isRequirementLoad,
    data: requirementsData,
    refetch,
  } = useJobRoleRequirements({
    filter: searchTxt,
    filterData,
    jobTitleHandle: id,
  })
  const tableHeaderList = [
    { title: t('tableTitle'), id: 1 },
    { title: t('type'), id: 2 },
    { title: t('category'), id: 3 },
    { title: t('compliance'), id: 4 },
  ]

  useEffect(() => {
    setDescription(jobRoleDetails?.item?.description ?? '')
    setSelectedJobTitles(jobRoleDetails?.item?.jobTitles ?? [])
    setSelectedOption(assignList[jobRoleDetails?.item?.userJobRoleAssignmentFlow ?? 0])
    const type = jobRoleDetails?.item?.minimalExperience?.split('.')
    if (type && type?.length > 0) {
      setExperienceValue(Number(type[0]))
      if (Number(type[0]) < 30) {
        setSelectedExperienceType(experienceTypeList[0])
      }
      else if (Number(type[0]) < 365) {
        setSelectedExperienceType(experienceTypeList[1])
      }
      else {
        setSelectedExperienceType(experienceTypeList[2])
      }
    }
  }, [jobRoleDetails])

  const jobTypeData = useMemo(() => {
    const result = jobRequirement?.items?.map((i) => {
      const data
        = i?.subTypes
          && i?.subTypes?.length > 0
          && i?.subTypes?.map((j) => {
            return {
              title: j,
              handle: j,
            }
          })
      return { ...i, handle: i?.title, subTypes: data }
    })
    return result
  }, [jobRequirement])

  useMemo(() => {
    const newFilter: any = [...filterData]
    newFilter[0].data = jobTypeData
    setFilterData(newFilter)
  }, [jobTypeData])

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter')
      refetch()
  }

  const saveJobDetails = () => {
    let experience = 0
    if (selectedExperienceType.id == 1) {
      experience = experienceValue
    }
    else if (selectedExperienceType.id == 2) {
      experience = experienceValue * 30
    }
    else if (selectedExperienceType.id == 2) {
      experience = experienceValue * 365
    }
    const paramsObj = {
      title: jobRoleDetails?.item?.title,
      description,
      handle: id,
      jobTitles: selectedJobTitles,
      jobTitleHandles: selectedJobTitles?.map(i => i.handle)?.filter(Boolean) as string[],
      userJobRoleAssignmentFlow: selectedOption?.id,
      minimalExperience: `${experience}.00:00:00`,
      ...(!isClearCoverImage
        && jobRoleDetails?.item?.coverMedia
        && jobRoleDetails?.item?.coverMedia?.length > 0 && {
        coverMediaHandles: jobRoleDetails?.item?.coverMedia
          ?.map(i => i.handle)
          ?.filter(Boolean) as string[],
      }),
      ...(!isClearImage
        && jobRoleDetails?.item?.media
        && jobRoleDetails?.item?.media?.length > 0 && {
        mediaItemHandles: jobRoleDetails?.item?.media
          ?.map(i => i.handle)
          ?.filter(Boolean) as string[],
      }),
    }
    const requestParams: FormData = new FormData()
    if (coverImage != '' || icon != '') {
      ;[coverImage, icon]?.forEach((item: any) => {
        requestParams.append('files', item)
      })
      uploadMediaMute(requestParams, {
        onSuccess: (response: UploadResponse) => {
          if (response.isSuccess) {
            const newObject = {
              ...paramsObj,
              ...(coverImage != ''
                && icon != '' && {
                coverMediaHandles: response.items && ([response.items[0]?.handle] as string[]),
                mediaItemHandles: response.items && ([response.items[1]?.handle] as string[]),
              }),
              ...(coverImage != ''
                && icon == '' && {
                coverMediaHandles: response.items && ([response.items[0]?.handle] as string[]),
              }),
              ...(coverImage == ''
                && icon != '' && {
                mediaItemHandles: response.items && ([response.items[0]?.handle] as string[]),
              }),
            }
            mutate(newObject, {
              onSuccess: () => {
                setUpdateJobTitleObj(newObject)
                setIsShowRequirementsProceed(false)
                setIsShowProceed(true)
              },
            })
          }
        },
      })
    }
    else {
      mutate(paramsObj, {
        onSuccess: () => {
          setUpdateJobTitleObj(paramsObj)
          setIsShowRequirementsProceed(false)
          setIsShowProceed(true)
        },
      })
    }
  }

  if (isLoading || isJobTypeLoad || isRequirementLoad || isJobTitleLoad)
    return <Spinner />

  return (
    <div className="p-5 h-full flex flex-col">
      {(isPending
        || updateIsPending
        || updateJobRequirementIsPending
        || deleteJobRequirementIsPending) && <Spinner />}
      {isShowProceed && (
        <DeleteAlert
          title={t('youWantToProceed')}
          subTitle={
            isShowRequirementsProceed ? t('noUsersWillBeAffected') : t('nothingWillBeRemoved')
          }
          leftButtonText={t('cancel')}
          rightButtonText={t('save')}
          onLeftButton={() => {
            setIsShowProceed(!isShowProceed)
          }}
          onRightButton={() => {
            setIsShowProceed(!isShowProceed)
            if (isShowRequirementsProceed) {
              const result = requirementsData?.items
                ?.filter(
                  item1 => !updateJobRequirementObj.some(item2 => item2.handle === item1.handle),
                )
                .map(i => i.handle) as string[]
              if (result && result?.length > 0) {
                deleteJobRequirementMutate(result, {
                  onSuccess: () => {
                    updateJobRequirementMutate(updateJobRequirementObj)
                  },
                })
              }
              else {
                updateJobRequirementMutate(updateJobRequirementObj)
              }
            }
            else {
              updateMutate(updateJobTitleObj, {
                onSuccess: () => {
                  queryClient.invalidateQueries({ queryKey: [QueryKey.jobRoleDetail] })
                },
              })
            }
          }}
        />
      )}
      {isShowAlert && (
        <JobCompanyAlert
          data={jobTitleData?.items}
          selectedData={selectedJobTitles}
          onCloseAlert={() => {
            setIsShowAlert(false)
          }}
          onSelectedData={(values) => {
            setSelectedJobTitles(values)
          }}
        />
      )}
      {isJobTitleAddAlert && (
        <JobTitleAddAlert
          isJobTitle={false}
          selectedData={requirementsData?.items}
          jobTitleHandle={id}
          filterDataItems={filterData}
          onCloseAlert={() => {
            setIsJobTitleAddAlert(false)
          }}
          onSuccessInfo={(data) => {
            setUpdateJobRequirementObj(data)
            setIsShowRequirementsProceed(true)
            setIsShowProceed(true)
          }}
        />
      )}
      <CustomText variant="xlPoppinsBold">{`${t('roleManagement')}  / ${t('roleList')}  / ${t('edit')}`}</CustomText>
      <div className="overflow-hidden overflow-y-auto scrollbar">
        <div className="mt-6 py-3 px-5 bg-white rounded-lg border border-stroke">
          <CustomText variant="mdPoppinsSemiBold">{t('totalUsersWithTheJobRole')}</CustomText>
          <CustomText variant="mdPoppinsRegular" className="!text-[#2F5BFD]">
            {`${jobRoleDetails?.item?.totalJobRoleUsersCount}`}
          </CustomText>
        </div>
        <div className="mt-6 py-6 px-5 bg-white rounded-lg border border-stroke">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
            <CustomTextArea
              title={`${t('description')} ${t('maxCharacters250')}`}
              placeholder={`${t('writeDescription')}`}
              aria-multiline
              value={description}
              onChange={(e) => {
                setDescription(e.target.value)
              }}
              maxLength={250}
            />
            <div className="flex lg:justify-center">
              <ImagePicker
                defaultImage={
                  jobRoleDetails?.item?.coverMedia && jobRoleDetails?.item?.coverMedia?.length > 0
                    ? jobRoleDetails?.item?.coverMedia[0].url
                    : undefined
                }
                title={`${t('uploadImage')} ${t('maxPixels200')}`}
                buttonTitle={`${t('choosePicture')}`}
                selectedImage={(item) => {
                  setIsClearCoverImage(false)
                  setCoverImage(item)
                }}
                onClearImage={() => {
                  setIsClearCoverImage(true)
                }}
              />
            </div>
            <div className="flex lg:justify-center">
              <ImagePicker
                defaultImage={
                  jobRoleDetails?.item?.media && jobRoleDetails?.item?.media?.length > 0
                    ? jobRoleDetails?.item?.media[0].url
                    : undefined
                }
                title={`${t('uploadIcon')} ${t('maxPixels200')}`}
                buttonTitle={`${t('choosePicture')}`}
                selectedImage={(item) => {
                  setIsClearImage(false)
                  setIcon(item)
                }}
                onClearImage={() => {
                  setIsClearImage(true)
                }}
              />
            </div>
          </div>
          <div className="mt-6 grid grid-cols-1 lg:grid-cols-3 gap-5">
            <div>
              <CustomText variant="mdPoppinsSemiBold">{t('addJobTitlesToRole')}</CustomText>
              <div className="flex mt-3 mb-3">
                <CustomText variant="mdPoppinsRegular">{`${t('chooseFromTheList')} `}</CustomText>
                <CustomText variant="mdPoppinsRegular" className="underline">
                  {t('youHaveSelected', {
                    count: selectedJobTitles?.length ?? 0,
                  })}
                </CustomText>
              </div>
              <CustomButton
                children={t('edit')}
                backgroundColor="bg-primary"
                textColor="text-textWhite"
                onClick={() => {
                  setIsShowAlert(true)
                }}
              />
            </div>
            <div className="w-[97%]">
              <CustomDropDown
                value={selectedOption?.id}
                title={t('automatisationForJobRoleAssignment')}
                data={assignList ?? []}
                labelField="title"
                valueField="id"
                className="!mb-2"
                onSelect={(_, item) => {
                  setSelectedOption(item as { title: string, id: number })
                }}
              />
            </div>
            {selectedOption.id != 0 && (
              <div className="w-[97%]">
                <CustomDropDown
                  value={selectedExperienceType?.id}
                  title={t('minimumExperience')}
                  data={experienceTypeList ?? []}
                  labelField="title"
                  valueField="id"
                  className="!mb-2"
                  onSelect={(_, item) => {
                    setSelectedExperienceType(item as { title: string, id: number })
                  }}
                />
                <CustomNumberInput
                  value={experienceValue}
                  onChange={(value) => {
                    if (value) {
                      setExperienceValue(Number(value))
                    }
                  }}
                />
              </div>
            )}
          </div>
          <div className="mt-3 flex gap-4 justify-end">
            <CustomButton
              children={t('save')}
              backgroundColor="bg-secondary"
              textColor="text-textWhite"
              className="!px-6"
              onClick={saveJobDetails}
            />
            <CustomButton
              children={t('cancel')}
              backgroundColor="bg-bthDisable"
              className="!px-6"
              textColor="text-textPrimary"
              onClick={() => {
                navigate(-1)
              }}
            />
          </div>
        </div>
        <div className="mt-6 px-5 py-3 bg-white rounded-lg flex items-center justify-between border border-stroke">
          <div className="flex items-center gap-3 flex-wrap">
            {filterData.map((item, index) => {
              return (
                <div className="flex items-center gap-3" key={index}>
                  <CustomMenu
                    menuList={item?.data ?? []}
                    selectedValue={item?.selectedData ?? null}
                    onClick={(value) => {
                      const updateItem = { ...item, selectedData: value?.handle }
                      const newFilter: any = [...filterData]
                      newFilter[index] = updateItem
                      if (index == 0) {
                        const updateItem = { ...newFilter[1], data: value?.subTypes }
                        newFilter[1] = updateItem
                      }
                      setFilterData(newFilter)
                    }}
                    buttonComponent={(
                      <div className="flex">
                        <TextWithIcon
                          text={t(item?.header)}
                          rightIcon={<RightIcon className="h-4 w-4 rotate-90 ml-4" />}
                          textVariant="smPoppinsRegular"
                        />
                        {item?.selectedData && (
                          <>
                            <div className="absolute -top-4 right-1 bg-primary h-4 w-4 rounded-full justify-center items-center flex">
                              <CustomText variant="xsmPoppinsRegular" textColor="text-textWhite">
                                1
                              </CustomText>
                            </div>
                            <button
                              onClick={() => {
                                const updateItem = { ...item, selectedData: null }
                                const newFilter: any = [...filterData]
                                newFilter[index] = updateItem
                                if (index == 0) {
                                  const updateItem = {
                                    ...newFilter[1],
                                    data: [],
                                    selectedData: null,
                                  }
                                  newFilter[1] = updateItem
                                }
                                setFilterData(newFilter)
                              }}
                            >
                              <CloseIcon className="h-5" />
                            </button>
                          </>
                        )}
                      </div>
                    )}
                  />
                  <div className="h-8 bg-black/10 w-[1px]" />
                </div>
              )
            })}
          </div>
          <BorderButton
            children={t('filter')}
            className="px-7 self-end"
            onClick={() => {
              refetch()
            }}
          />
        </div>
        <div className="mt-6 py-6 px-5 bg-white rounded-lg border border-stroke flex flex-col flex-1 overflow-hidden">
          <div className="flex flex-col sm:flex-row mb-4 gap-4">
            <CustomText className="mr-auto self-center" wrapText={false} variant="xlPoppinsBold">
              {`${t('total')}: ${requirementsData?.totalCount}`}
            </CustomText>
            <div className="flex flex-1 items-center justify-between">
              <div className="flex items-center w-auto gap-4">
                <SearchIcon />
                <input
                  type="text"
                  value={searchTxt}
                  placeholder={t('searchPlaceholder')}
                  className="outline-0 flex-1 min-w-[100px]"
                  onChange={e => setSearchTxt(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                {searchTxt && (
                  <button
                    onClick={() => {
                      setSearchTxt('')
                      setTimeout(() => {
                        refetch()
                      }, 1000)
                    }}
                  >
                    <CloseIcon className="h-5" />
                  </button>
                )}
              </div>
              <CustomButton
                children={t('add')}
                textVariant="smPoppinsMedium"
                className="px-4 sm:px-8 bg-secondary"
                onClick={() => {
                  setIsJobTitleAddAlert(true)
                }}
              />
            </div>
          </div>
          <div className="flex flex-col overflow-hidden rounded-lg border border-stroke">
            <div className="flex-1 overflow-x-auto overflow-y-auto scrollbar bg-white">
              <CustomTable
                tableHeaderList={tableHeaderList}
                tableDataList={requirementsData?.items?.slice(skipItem, skipItem + itemPerPage)}
                displayKeys={['title', 'type', 'subtype', 'isCompliant']}
              />
            </div>
            {requirementsData && requirementsData?.items && requirementsData?.items.length > 0 && (
              <CustomPagination
                totalItem={requirementsData?.totalCount}
                itemPerPage={itemPerPage}
                onChange={setItemPage}
                onPagePress={index => setSkipItem((index - 1) * itemPerPage)}
                skip={skipItem}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RoleSpecializationEdit
