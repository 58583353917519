import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { CustomButton, CustomPagination, CustomTable, CustomText, Spinner } from '../../component'
import { CloseIcon, SearchIcon } from '../../icons'
import { useFeedbackList } from '../../packages/api-web'

function Campaign() {
  const [itemPerPage, setItemPage] = useState(20)
  const [skipItem, setSkipItem] = useState(0)
  const [searchTxt, setSearchTxt] = useState('')
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { isLoading, data, refetch } = useFeedbackList({ itemPerPage, skip: skipItem, searchTxt })

  const tableHeaderList = useMemo(
    () => [
      {
        title: t('tableCampaign'),
        key: 'title',
        id: 1,
      },
      {
        title: t('startDate'),
        key: 'startAt',
        id: 2,
      },
      {
        title: t('endDate'),
        key: 'endAt',
        id: 3,
      },
    ],
    [t],
  )

  useEffect(() => {
    refetch()
  }, [skipItem, itemPerPage])

  const handleInputKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      refetch()
    }
  }

  if (isLoading)
    return <Spinner />

  return (
    <div className="p-5 h-full flex flex-col">
      <CustomText variant="xlPoppinsBold">{t('campaigns')}</CustomText>
      <div className="mt-6 py-6 px-5 bg-white rounded-lg border border-stroke flex flex-col overflow-hidden">
        <div className="flex flex-col sm:flex-row mb-4 gap-4">
          <CustomText className="mr-auto self-center" wrapText={false} variant="xlPoppinsBold">
            {`${t('total')}: ${data?.totalCount}`}
          </CustomText>
          <div className="flex flex-1 items-center justify-between">
            <div className="flex items-center w-auto gap-4">
              <SearchIcon />
              <input
                type="text"
                value={searchTxt}
                placeholder={t('searchPlaceholder')}
                className="outline-0 flex-1 min-w-[100px]"
                onChange={e => setSearchTxt(e.target.value)}
                onKeyDown={handleInputKeyDown}
              />
              {searchTxt && (
                <button
                  onClick={() => {
                    setSearchTxt('')
                    setTimeout(() => {
                      refetch()
                    }, 1000)
                  }}
                >
                  <CloseIcon className="h-5" />
                </button>
              )}
            </div>
            <CustomButton
              children={t('create')}
              textVariant="smPoppinsMedium"
              className="px-4 sm:px-8 bg-secondary"
              onClick={() => navigate('/feedback/campaign/create')}
            />
          </div>
        </div>
        <div className="flex flex-col overflow-hidden rounded-lg border border-stroke">
          <div className="flex-1 overflow-x-auto overflow-y-auto scrollbar bg-white">
            <CustomTable
              tableHeaderList={tableHeaderList}
              tableDataList={data?.items}
              displayKeys={['title', 'startAt', 'endAt']}
              onClick={(item) => {
                navigate(`/feedback/campaign/${item.handle}`)
              }}
            />
          </div>
          <CustomPagination
            totalItem={data?.totalCount}
            itemPerPage={itemPerPage}
            onChange={(value) => {
              setItemPage(value)
            }}
            onPagePress={(index) => {
              const skipItem = (index - 1) * itemPerPage
              setSkipItem(skipItem)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Campaign
