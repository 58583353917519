import type { Social } from './useSocialList'
import { UserDto } from '@inphiz-shared/shared-models/src/Dto/user-dto'
import { APIConst, QueryKey } from '@inphiz/api'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { useInfiniteQuery } from '@tanstack/react-query'

const UserItemType = new UserDto()
export type UserItem = typeof UserItemType

export interface ItemResponse extends Social {
  user: UserItem
}

interface ReadingListProps {
  isReading: boolean
  postHandle: string
}

const currentTakeItem = 30

export function usePostReadingUserList({ isReading, postHandle }: ReadingListProps) {
  const result = useInfiniteQuery({
    queryKey: [QueryKey.readingUserList, postHandle, isReading],
    queryFn: async ({ pageParam }) => {
      let url = `?UseContinuationToken=False&Cursor=${pageParam}&Take=${currentTakeItem}&ChildOf=${postHandle}&IncludeChildCount=0&IsOrderAscending=False&OnlyCount=False`
      if (isReading)
        url = `${url}&ParametersWithValues[IsReadConfirmed]=True`
      else url = `${url}&ParametersWithValues[IsReadConfirmed]=False`

      const results = await APIFunctions.GET(APIConst.socials.readingUserList + url)
      const nextCursorValue = pageParam + currentTakeItem
      const nextCursor = nextCursorValue >= results?.totalCount ? undefined : nextCursorValue
      return { ...results, nextCursor }
    },
    initialPageParam: 0,
    getNextPageParam: lastPage => lastPage.nextCursor,
    staleTime: Number.POSITIVE_INFINITY,
  })
  return result
}
