import { useTranslation } from 'react-i18next'
import { MenuIcon } from '../../../icons'
import { CustomText, GroupBarChart } from '../../atoms'

interface tabArrayParams {
  color?: string
  title?: string
  info?: string
  subTitle?: string
  rounded?: boolean | undefined
}

export function ControlCenterVehicleCost() {
  const { t } = useTranslation('common')
  const barLabel = [
    {
      color: 'bg-chartBlue',
      title: t('maintenanceCost'),
    },
    {
      color: 'bg-chartGreen',
      title: t('costDamages'),
    },
  ] as unknown as tabArrayParams[]

  return (
    <div>
      <div className="mt-5 py-5 px-5 bg-white rounded-lg border border-stroke flex flex-col overflow-hidden">
        <div className="flex items-center">
          <CustomText variant="mdPoppinsSemiBold">{t('vehicleCost')}</CustomText>
          <button>
            <MenuIcon className="stroke-black ml-2" />
          </button>
        </div>
        <div className="mt-5 grid grid-cols-1 lg:grid-cols-3 gap-5">
          <div className="col-span-2">
            <GroupBarChart
              minYAxis={0}
              maxYAxis={1000}
              labels={['TOTAL', 'Region Mitt', 'Region Nord', 'Region Syd']}
              datasets={[
                {
                  label: 'Dataset 1',
                  data: [800, 700, 950, 780],
                  backgroundColor: 'rgb(37, 80, 233)',
                  barPercentage: 0.5,
                  stack: '1',
                },
                {
                  label: 'Dataset 1',
                  data: [100, 130, 120, 80],
                  backgroundColor: 'rgb(68, 153, 87)',
                  barPercentage: 0.5,
                  stack: '2',
                },
              ]}
            />
          </div>
          <div className="flex flex-col gap-3 justify-center">
            {barLabel?.map((item) => {
              return (
                <div className="flex items-center">
                  <div
                    className={`h-5 w-5 mr-2 ${item?.color} ${item?.rounded ? 'rounded-full' : ''}`}
                  />
                  <CustomText variant="mdPoppinsRegular">{item?.title ?? ''}</CustomText>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
